import React, { useEffect, useState } from 'react';
import './drawerSidebarMultiple.scss';
import {
  Drawer,
  Typography,
  Divider,
  Button,
  IconButton,
  Backdrop,
  Autocomplete,
  Checkbox,
  TextField,
  Box,
  Popper,
  Grid,
  ButtonGroup,
} from '@mui/material';
import { Close, CheckBox, CheckBoxOutlineBlank, Search } from '@mui/icons-material';

// Define a generic data structure
interface DataItem {
  label: string;
  value: string;
}

interface DrawerSidebarMultipleProps {
  dataList: DataItem[];
  isDrawerOpen: boolean;
  isBackdropOpen: boolean;
  selectedValue?: string[];
  handleCloseDrawer: () => void;
  handleSelectionClick: (value: string[]) => void;
  titleLabel?: string;
}

export default function DrawerSidebarMultiple({
  dataList,
  isDrawerOpen,
  isBackdropOpen,
  selectedValue,
  handleCloseDrawer,
  handleSelectionClick,
  titleLabel,
}: DrawerSidebarMultipleProps) {
  const initialValue = (selectedValue || []).map((value) => ({ label: value, value }));
  const [activeValue, setActiveValue] = React.useState(initialValue);
  const [temporarySelection, setTemporarySelection] = React.useState(initialValue);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null); // Initialize with null
  const handleApply = () => {
    const selectedValues = temporarySelection.map((item) => item.value);
    setActiveValue(temporarySelection);
    handleSelectionClick(selectedValues);
    handleCloseDrawer();
  };

  useEffect(() => {
    if (isDrawerOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [isDrawerOpen]);

  const toggleItemSelection = (value: DataItem) => {
    const updatedSelection = temporarySelection.some((item) => item.value === value.value)
      ? temporarySelection.filter((item) => item.value !== value.value)
      : [...temporarySelection, value];

    setTemporarySelection(updatedSelection);
  };
  const handleSelectAll = () => {
    setTemporarySelection(dataList);
  };

  const handleClearAll = () => {
    setTemporarySelection([]);
  };

  return (
    <>
      {isDrawerOpen && (
        <Backdrop
          id="backdrop"
          open={isBackdropOpen}
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1, overflow: 'hidden' }}
        >
          <Drawer anchor="right" open={isDrawerOpen} variant="persistent" onClose={handleCloseDrawer}>
            <div id="close-icon">
              <IconButton
                onClick={() => {
                  handleCloseDrawer();
                  setTemporarySelection(activeValue);
                }}
              >
                <Close />
              </IconButton>
            </div>
            <div className="sidebarHeader">
              <Typography variant="h6" id="sidebar-title">
                Select {titleLabel}
              </Typography>
              <li>Can select multiple options</li>
              <Divider orientation="horizontal" flexItem />
              <div className="sidebarChoiceContainer">
                <Autocomplete
                  open
                  multiple
                  options={dataList}
                  popupIcon={<Search />}
                  getOptionLabel={(option) => option.label}
                  value={temporarySelection}
                  clearIcon={false}
                  renderOption={(props: any, option, state, ownerState) => {
                    return (
                      <li
                        {...props}
                        onClick={(event) => {
                          toggleItemSelection(option);
                          setAnchorEl(event.currentTarget);
                        }}
                        key={state.index}
                      >
                        <Checkbox
                          icon={<CheckBoxOutlineBlank />}
                          checkedIcon={<CheckBox />}
                          style={{ marginRight: 8 }}
                          checked={temporarySelection.some((item) => item.value === option.value)}
                          key={state.index}
                        />
                        {option.label}
                      </li>
                    );
                  }}
                  // PopperComponent={(props: any) => {
                  //   return (
                  //     <Popper {...props} open oanchorEl={anchorEl} placement="bottom-end">
                  //       {props.children}
                  //       <Grid container className="bottomSide">
                  //         <div className="hrTop">
                  //           <hr className="hrTopBtn"></hr>
                  //         </div>
                  //         <div id="select-clear-buttons">
                  //           <Button variant="text" className="btnSelectAll" onClick={handleSelectAll}>
                  //             Select All
                  //           </Button>
                  //           <Button variant="text" className="btnClearAll" onClick={handleClearAll}>
                  //             Clear
                  //           </Button>
                  //         </div>
                  //       </Grid>
                  //     </Popper>
                  //   );
                  // }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      // placeholder={temporarySelection.length !== 0 ? '' : 'Search'}
                      placeholder={'Search'}
                      InputProps={{
                        ...params.InputProps,
                        // type: 'search',
                      }}
                      onClick={(event) => setAnchorEl(event.currentTarget)}
                      // value={temporarySelection.map((item) => item.label).join(', ')}
                    />
                  )}
                  onChange={(_, newValue) => {
                    const selectedItems = Array.isArray(newValue) ? newValue : [];
                    setTemporarySelection(selectedItems);
                  }}
                />
              </div>
            </div>
            <div className="sidebarFooter">
              <div id="select-clear-buttons">
                <Button variant="text" className="btnSelectAll" onClick={handleSelectAll}>
                  Select All
                </Button>
                <Button variant="text" className="btnClearAll" onClick={handleClearAll}>
                  Clear All
                </Button>
              </div>
              {/* <button onClick={(event) => setAnchorEl(event.currentTarget)}>Open Popper</button> */}
              <Box textAlign="center" className="blockApply">
                {/* <button onClick={(event) => setAnchorEl(event.currentTarget)}>Open Popper</button> */}

                <Button
                  id="apply-button"
                  variant="contained"
                  // disabled={temporarySelection?.length === 0}
                  onClick={handleApply}
                >
                  APPLY
                </Button>
              </Box>
            </div>
          </Drawer>
        </Backdrop>
      )}
    </>
  );
}

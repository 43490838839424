import secureLocalStorage from 'react-secure-storage';
import API from '../api';
import { filterPayload } from '../type';

const yesApp = secureLocalStorage.getItem('salesToken') || secureLocalStorage.getItem('salesToken') ? '' : '-detail';
class ExpectedDeliveryServiceClass {
  getExpectedDelivery = (params: filterPayload) =>
    API.post({
      uri: process.env.REACT_APP_ENV === 'SIT' ? 'edb19c91-40a3-47fd-bc2c-8dc05dc38289' : 'expected-delivery' + yesApp,
      params,
    });
  // API.post({uri: '', params});
  // Success

  getExpecteDealerSumary = (params: filterPayload) =>
    API.post({
      uri: process.env.REACT_APP_ENV === 'SIT' ? 'edb19c91-40a3-47fd-bc2c-8dc05dc38289' : 'expected-delivery-summary',
      params,
    });

  getExpecteDealerdetail = (params: filterPayload) =>
    API.post({
      uri: process.env.REACT_APP_ENV === 'SIT' ? 'edb19c91-40a3-47fd-bc2c-8dc05dc38289' : 'expected-delivery-detail',
      params,
    });
}

export const ExpectedDeliveryService = new ExpectedDeliveryServiceClass();

import React from 'react';
import { CustomLineChart } from './CustomLineChart';

export const ExampleLineChart: React.FC = () => {
  const data: { [key: string]: number } = {
    'Page A': 2000,
    'Page B': 3000,
    'Page C': 2000,
    'Page D': 2780,
    'Page E': 1890,
    'Page F': 2390,
    'Page G': 3490,
    // Add more data points as needed
    'Page H': 1500,
    'Page I': 2700,
    'Page J': 3200,
  };

  // return <CustomLineChart data={data} />;
  return <></>;
};

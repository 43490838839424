// authStore.ts
import create from 'zustand';

export type DealerCodeType = {
  dealerCode: string;
  dealerNameEn: string;
  dealerNameTh: string;
};

export type ColorType = {
  colorCode: string;
  colorNameTh: string;
  colorNameEn: string;
};
export type ProvinceType = {
  provinceCode: number | string | any;
  provinceNameTh: string;
  provinceNameEn: string;
};

export type AreaType = {
  areaCode: string;
  areaName: string;
};

export type RegionType = {
  regionCode: number | string | any;
  regionName: string;
};
export type SubRegionType = {
  subregionCode: string;
  subregionName: string;
};

export type ModelType = string[];

interface FilterSaleStore {
  filterDealer: DealerCodeType[];
  filterProductCodes: ModelType[];
  filterModelName: ModelType[];
  filterModelCode: ModelType[];
  filterColor: ColorType[];
  filterOwnerCodes: ModelType[];
  filterProvince: ProvinceType[];
  filterArea: AreaType[];
  filterSubRegion: SubRegionType[];
  filterRegion: RegionType[];
  setDealers: (dealers: DealerCodeType[]) => void; // Correct the parameter type here
  setModelNames: (modelName: ModelType[]) => void; // Correct the parameter type here
  setModelCodes: (modelCode: ModelType[]) => void; // Correct the parameter type here
  setColors: (modelColor: ColorType[]) => void; // Correct the parameter type here
  setProductCodes: (productCode: ModelType[]) => void;
  setOwnerCodes: (owenrCode: ModelType[]) => void;
  setProvinces: (province: ProvinceType[]) => void;
  setAreas: (province: AreaType[]) => void;
  setSubRegions: (subRegion: SubRegionType[]) => void;
  setRegions: (subRegion: RegionType[]) => void;
}
export const useFilterSaleStore = create<FilterSaleStore>((set, get) => ({
  filterDealer: [],
  filterModelName: [],
  filterColor: [],
  filterModelCode: [],
  filterProductCodes: [],
  filterOwnerCodes: [],
  filterProvince: [],
  filterArea: [],
  filterSubRegion: [],
  filterRegion: [],
  setRegions: (region) => {
    set({ filterRegion: region });
  },
  setSubRegions: (subRegion) => {
    set({ filterSubRegion: subRegion });
  },
  setOwnerCodes: (owenrCode) => {
    set({ filterOwnerCodes: owenrCode });
  },
  setProductCodes: (productCodes) => {
    set({ filterProductCodes: productCodes });
  },
  setDealers: (dealers) => {
    set({ filterDealer: dealers });
  },
  setModelNames: (models) => {
    set({ filterModelName: models });
  },
  setModelCodes: (modelCodes) => {
    set({ filterModelCode: modelCodes });
  },
  setColors: (colors) => {
    set({ filterColor: colors });
  },
  setProvinces: (provinces) => {
    set({ filterProvince: provinces });
  },
  setAreas: (areas) => {
    set({ filterArea: areas });
  },
}));

import React, { useEffect, useMemo, useState } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { AppBar, List, ListItem, ListItemText, Toolbar, Typography } from '@mui/material';
import './HeaderComponent.scss';
import { IListScreenProps, IList } from './types';
import { PROFILE_ROLE } from '../../Screen/DeliveryStatusScreen/constant';

export const HeaderComponent = (props: IListScreenProps) => {
  const { listScreen, permissions, profileData, dealer } = props;
  const { role } = profileData;
  const location = useLocation();
  const [activeScreen, setActiveScreen] = useState<IList | null>(null);

  const renderSubtitleExpected = () => {
    if (role === PROFILE_ROLE.DM || role === PROFILE_ROLE.SP) {
      return 'NATIONWIDE';
    } else if (role === PROFILE_ROLE.GM || role === PROFILE_ROLE.HD) {
      return 'REGIONAL';
    } else {
      return 'AREA';
    }
  };

  const renderHeaderWithPermission = useMemo(() => {
    return listScreen.map((link: IList, index: number) => {
      const permission = permissions && permissions.findIndex((value) => value.includes(link?.key)) >= 0;
      const havePermission = link.key === 'yes' ? true : permission;
      if (havePermission || profileData) {
        return (
          <ListItem
            component={RouterLink}
            to={link.to}
            key={link.to}
            button
            selected={location.pathname === link.to}
            className={location.pathname === link.to ? 'active' : 'inActive'}
          >
            {role ? (
              <ListItemText
                primary={link.btnName}
                className="btnScreen"
                sx={{ marginLeft: index + 1 === listScreen.length ? '16px' : 0 }}
              />
            ) : (
              <ListItemText primary={link.btnName} className="btnScreenSale" />
            )}
          </ListItem>
        );
      }
    });
  }, [listScreen, permissions, profileData]);

  useEffect(() => {
    // Find the active screen based on location.pathname
    const foundScreen = listScreen.find((screen) => location.pathname === screen.to);
    if (foundScreen) {
      // console.warn(foundScreen);
      setActiveScreen(foundScreen);
    }
  }, [listScreen, location.pathname]);

  return (
    <AppBar className="appBar" position="sticky" style={{ position: 'sticky', zIndex: '1!important' }}>
      <Toolbar className="tollBar">
        <List component="div" className="listMenu">
          {renderHeaderWithPermission}
        </List>
        <Typography variant="h5" className="placeHolderTitle">
          {activeScreen?.page}
        </Typography>
        <Typography variant="subtitle1" className="placeHolderTitle">
          {activeScreen?.page === 'EXPECTED DELIVERY' && !dealer ? renderSubtitleExpected() : activeScreen?.subTitle}
        </Typography>
      </Toolbar>
    </AppBar>
  );
};

import React, { useEffect, useState } from 'react';
import { Stack } from '@mui/material';
import './ExpectedDeliveryScreen.scss';
import { FilterExpectedDelivery } from './filter';
import { TableCustom } from '../../components/Table/TableCustom';
import { mockColumn } from './mock';
import { ExpectedDeliveryService } from '../../services/expectedDeliveryService';
import BackdropWithLoading from '../../components/loading';
import { useNavigate } from 'react-router-dom';
import { ProtectedRoute } from '../protectedRoute';
import { useAuthStore } from '../../state/authen-store';
import { filterPayload } from '../../services/type';
import { LastUpdatedService } from '../../services/lastUpdatedService';
import { formatDate } from '../../helper/utils-helper';

export type ExpectedDeliverySale = {
  ownerCode: string;
  dealerName: string;
  productCode: string;
  productName: string;
  color: string;
  qty: string;
  expectedDeliveryDate: string;
  totalAmount: string;
};
export const ExpectedDeliveryScreen = () => {
  const [expectedArrData, setExpectedArrData] = useState<ExpectedDeliverySale[]>([]);
  const [pageIndex, setPageIndex] = useState<number>(1);
  const [totalPage, setTotalPage] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadFilter, setLoadFilter] = useState<boolean>(true);
  const navigate = useNavigate();
  const { profileData } = useAuthStore();
  const [params, setParams] = useState<filterPayload>({});
  const [checkNotFound, setCheckNotFound] = useState<boolean>(false);
  const [lastUpdatedData, setLastUpdatedData] = useState<string>('');

  useEffect(() => {
    if (profileData && !loadFilter && !loading) {
      console.warn('getExpectedDeliveryData');
      getExpectedDeliveryData();
      getLastUpdated();
    }
  }, [profileData, params, loadFilter]);

  const loadFilterEnd = (loading: boolean) => {
    setLoadFilter(loading);
  };
  const getExpectedDeliveryData = () => {
    setLoading(true);
    ExpectedDeliveryService.getExpectedDelivery(params)
      .then((resp) => {
        setLoading(false);
        const data = resp.data.data;
        if (data) {
          setTotalPage(data[0].totalPages);
          setPageIndex(data[0].page);
          setExpectedArrData(data[0].contents);
          setCheckNotFound(false);
        } else {
          setCheckNotFound(true);
        }
      })
      .catch((error) => {
        setLoading(false);
        handleApiError(error);
        console.error('Error fetching getByAreaData:', error);
      });
  };

  const getLastUpdated = () => {
    // setLoading(true);
    LastUpdatedService.getSalesExpectedDeliveryLastUpdated(params)
      .then((resp) => {
        // setLoading(false);
        const data = resp?.data?.data?.lastUpdated;
        if (data) {
          const formatedDate = formatDate(data);
          setLastUpdatedData(formatedDate);
        } else {
          setLastUpdatedData('');
        }
      })
      .catch((error) => {
        // setLoading(false);
        setLastUpdatedData('');
        handleApiError(error);
      });
  };

  const handleApiError = (err: any) => {
    if (err.code === 'ERR_INVALID_JWT') {
      return navigate('/error', {
        state: {
          errorMessage: err.message && err.code ? `${err.message}/${err.code}` : err.message || '',
          originalPath: '/expected-delivery',
          errtokenExpire: true,
        },
        replace: true,
      });
    }
    return navigate('/error', {
      state: {
        errorMessage: err.message && err.code ? `${err.message}/${err.code}` : err.message || '',
        originalPath: '/expected-delivery',
        errtokenExpire: false,
      },
      replace: true,
    });
  };
  const handlePagination = (event: any, value: any) => {
    const updatedParamsFilter = {
      ...params,
      pageIndex: value,
    };
    // console.warn('filter Changelast----->>>>>', updatedParamsFilter);
    setParams(updatedParamsFilter);
    setPageIndex(value);
  };

  const checkDataIsNotFound = () => {
    if (checkNotFound) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <ProtectedRoute>
      <Stack sx={{ padding: '36px 32px' }}>
        <FilterExpectedDelivery
          activeSubmenu={''}
          callBackFilterParams={setParams}
          loadFilter={loadFilterEnd}
          pageIndex={pageIndex}
        />
        {!checkDataIsNotFound() && (
          <Stack style={{ justifyContent: 'center', paddingTop: '26px' }}>
            {expectedArrData?.length > 0 && (
              <TableCustom
                columns={mockColumn}
                data={expectedArrData}
                widthTable={1120}
                pagination
                heightTable={540}
                lastUpdateText={'Last Updated : ' + lastUpdatedData}
                page={pageIndex}
                totalPage={totalPage}
                onChangePage={handlePagination}
              />
            )}
          </Stack>
        )}
      </Stack>
      <BackdropWithLoading show={loading || loadFilter} />
    </ProtectedRoute>
  );
};

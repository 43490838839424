import React, { useEffect, useState } from 'react';
import { Grid, Stack, Typography } from '@mui/material';
import StackedBarChart from '../../../components/Chart/StackedBarChart/StackedBarChart';
import { TableCustom, Column } from '../../../components/Table/TableCustom';
import './YesSummaryScreen.scss';
import { FilteYesDealer } from '../filter';
import { mockColumn } from './mock';
import { ProtectedRoute } from '../../protectedRoute';
import { useNavigate } from 'react-router-dom';
import { useAuthStore } from '../../../state/authen-store';
import { ExpectedDeliveryService } from '../../../services/expectedDeliveryService';
import { barColorsDeliveryStatus } from '../../DeliveryStatusScreen/mock';
import { dataBarStackType } from '../../DeliveryStatusScreen';
import BackdropWithLoading from '../../../components/loading';
import { filterPayload } from '../../../services/type';
import { LastUpdatedService } from '../../../services/lastUpdatedService';
import { formatDate } from '../../../helper/utils-helper';

export type ExpectedDeliveryDealer = {
  productName: string;
  color: string;
  qty: string;
  expectedDeliveryDate: string;
  totalAmount: string;
};

export const YesSummaryScreen = () => {
  const [expectedArrData, setExpectedArrData] = useState<ExpectedDeliveryDealer[]>([]);
  const [barArrData, setBarArrData] = useState<dataBarStackType[]>([]);
  const [columnChart, setColumnChart] = useState<Column[]>([]);
  const [rowChart, setRowChart] = useState<any[]>([]);
  const [pageIndex, setPageIndex] = useState<number>(1);
  const [sizePage, setSizePage] = useState<number>(20);
  const [totalPage, setTotalPage] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadFilter, setLoadFilter] = useState<boolean>(true);
  const navigate = useNavigate();
  const barStackDataKeys = ['mcDelivered', 'mcInTransit', 'mcInProduction', 'mcRejected'];
  const { profileData } = useAuthStore();
  // const navigate = useNavigate();
  const [params, setParams] = useState<filterPayload>({});
  const [checkNotFound, setCheckNotFound] = useState<boolean>(false);
  const [lastUpdatedData, setLastUpdatedData] = useState<string>('');

  const transformData = (data: any[]) => {
    const allProduct = data.reduce((acc, item) => {
      const { products, ...rest } = item;
      const transformedProducts = products.map((product: any, index: any) => ({
        ...product,
        ...rest,
        header: index === 0, // add to header key arry index 0
      }));
      return [...acc, ...transformedProducts];
    }, []);
    return allProduct;
  };

  useEffect(() => {
    if (profileData && !loadFilter && !loading) {
      getDeliveryStatusData();
      getExpectedDeliveryData();
      getLastUpdated();
    }
  }, [profileData, params, loadFilter]);
  const loadFilterEnd = (loading: boolean) => {
    setLoadFilter(loading);
  };

  const getDeliveryStatusData = () => {
    setLoading(true);

    ExpectedDeliveryService.getExpecteDealerSumary(params)
      .then((resp) => {
        setLoading(false);
        const data = resp?.data?.data;
        if (data) {
          const byAreaDataFormat = {
            name: '',
            data: {
              mcDelivered: data['mcDelivered'],
              mcInTransit: data['mcInTransit'],
              mcWaitForShipping: data['mcWaitForShipping'],
              mcInProduction: data['mcInProduction'],
              mcRejected: data['mcRejected'],
            },
          };
          setBarArrData([byAreaDataFormat]);
          setColumnChart([
            {
              id: 'statusChart',
              label: 'Total',
              styleColumn: {
                width: '150px',
                textAlign: 'center',
                padding: 0,
                fontSize: '14px',
              },
              customRow: (value: any) => <div style={{ textAlign: 'left', padding: 0, fontSize: '12px' }}>{value}</div>,
            },
            {
              id: 'value',
              label: String(
                [
                  byAreaDataFormat?.data.mcDelivered,
                  byAreaDataFormat?.data.mcInTransit,
                  byAreaDataFormat?.data.mcInProduction,
                  byAreaDataFormat?.data.mcRejected,
                ].reduce((total, value) => total + (value ?? 0), 0),
              ),
              styleColumn: {
                width: '260px',
                textAlign: 'center',
                padding: 0,
                fontSize: '14px',
              },
              customRow: (value: any) => (
                <div style={{ textAlign: 'center', padding: 0, fontSize: '12px' }}>{value}</div>
              ),
            },
          ]);
          setRowChart([
            {
              id: '1',
              statusChart: (
                <Grid container spacing={1}>
                  <Grid item xs="auto" style={{ margin: 4 }}>
                    <div className="colorSquare delivery" />
                  </Grid>
                  <Grid item xs={8} style={{ margin: 4 }}>
                    Deliveryed
                  </Grid>
                </Grid>
              ),
              value: byAreaDataFormat?.data.mcDelivered ?? 0,
            },
            {
              id: '2',
              statusChart: (
                <Grid container spacing={1}>
                  <Grid item xs="auto" style={{ margin: 4 }}>
                    <div className="colorSquare inTransit" />
                  </Grid>
                  <Grid item xs={8} style={{ margin: 4 }}>
                    In Transit
                  </Grid>
                </Grid>
              ),
              value: byAreaDataFormat?.data.mcInTransit ?? 0,
            },
            {
              id: '3',
              statusChart: (
                <Grid container spacing={1}>
                  <Grid item xs="auto" style={{ margin: 4 }}>
                    <div className="colorSquare inProcess" />
                  </Grid>
                  <Grid item xs={8} style={{ margin: 4 }}>
                    In Production
                  </Grid>
                </Grid>
              ),
              value: byAreaDataFormat?.data.mcInProduction ?? 0,
            },
            {
              id: '4',
              statusChart: (
                <Grid container spacing={1}>
                  <Grid item xs="auto" style={{ margin: 4 }}>
                    <div className="colorSquare reJected" />
                  </Grid>
                  <Grid item xs={8} style={{ margin: 4 }}>
                    Rejected
                  </Grid>
                </Grid>
              ),
              value: byAreaDataFormat?.data.mcRejected ?? 0,
            },
          ]);
          setCheckNotFound(false);
          // setCorePieChartData(sumData[0]);g
        } else {
          setLoading(false);
          setCheckNotFound(true);
          // handleApiError(resp.data);
        }
      })
      .catch((error) => {
        setLoading(false);
        handleApiError(error);
        console.error('Error fetching getByRegionData:', error);
      });
  };

  const getExpectedDeliveryData = () => {
    setLoading(true);
    ExpectedDeliveryService.getExpectedDelivery(params)
      .then((resp) => {
        setLoading(false);
        const data = resp.data.data;
        if (data) {
          setLoading(false);
          setSizePage(data[0].size);
          setTotalPage(data[0].totalPages);
          setPageIndex(data[0].page);
          const transform = transformData(data[0].contents);
          setExpectedArrData(transform);
          setCheckNotFound(false);
        } else {
          setLoading(false);
          setCheckNotFound(true);
          // handleApiError(resp.data);
        }
      })
      .catch((error) => {
        setLoading(false);
        handleApiError(error);
        console.error('Error fetching getByAreaData:', error);
      });
  };

  const getLastUpdated = () => {
    // setLoading(true);
    LastUpdatedService.getDealerExpectedDeliveryLastUpdated(params)
      .then((resp) => {
        // setLoading(false);
        const data = resp?.data?.data?.lastUpdated;
        if (data) {
          const formatedDate = formatDate(data);
          setLastUpdatedData(formatedDate);
        } else {
          setLastUpdatedData('');
        }
      })
      .catch((error) => {
        // setLoading(false);
        setLastUpdatedData('');
        handleApiError(error);
      });
  };

  const handleApiError = (err: any) => {
    if (err.code === 'ERR_INVALID_JWT') {
      return navigate('/error', {
        state: {
          errorMessage: err.message && err.code ? `${err.message}/${err.code}` : err.message || '',
          originalPath: '/yes-summary',
          errtokenExpire: true,
        },
        replace: true,
      });
    }
    return navigate('/error', {
      state: {
        errorMessage: err.message && err.code ? `${err.message}/${err.code}` : err.message || '',
        originalPath: '/yes-summary',
        errtokenExpire: false,
      },
      replace: true,
    });
  };
  const handlePagination = (event: any, value: any) => {
    const updatedParamsFilter = {
      ...params,
      pageIndex: value,
    };
    setParams(updatedParamsFilter);
    setPageIndex(value);
  };

  //
  const setIsParams = (value: any) => {
    setParams(value);
  };

  const checkDataIsNotFound = () => {
    if (checkNotFound) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <ProtectedRoute>
      <Stack sx={{ padding: '36px 32px' }}>
        {profileData !== null && (
          <FilteYesDealer
            activeSubmenu={''}
            callBackFilterParams={setIsParams}
            loadFilter={loadFilterEnd}
            pageIndex={pageIndex}
          />
        )}

        {!checkDataIsNotFound() &&
          columnChart.length > 0 &&
          rowChart.length > 0 &&
          barArrData.length > 0 &&
          expectedArrData.length > 0 && (
            <Stack
              sx={{ justifyContent: 'space-between', flexDirection: 'row', paddingTop: '26px' }}
              className="noMarginTop"
            >
              <Stack>
                <div className="textLastUpdate">Last Updated : {lastUpdatedData}</div>
                <Typography variant="h5" className="titleChart">
                  DELIVERY STATUS
                </Typography>
                <StackedBarChart
                  data={barArrData}
                  dataKeys={barStackDataKeys}
                  heightChart={370}
                  widthChart={460}
                  styleBars={{ marginTop: -40 }}
                  colors={barColorsDeliveryStatus}
                  ScreenDealer={true}
                  notShowLegend={true}
                />
                <TableCustom
                  columns={columnChart}
                  data={rowChart}
                  widthTable={460}
                  cellNopadding
                  classNames={['tableChart']}
                  totalPage={0}
                />
              </Stack>
              <TableCustom
                columns={mockColumn}
                data={expectedArrData}
                widthTable={640}
                pagination
                lastUpdateText={'Last Updated : ' + lastUpdatedData}
                page={pageIndex}
                totalPage={totalPage}
                onChangePage={handlePagination}
              />
            </Stack>
          )}
      </Stack>
      <BackdropWithLoading show={loading || loadFilter} />
    </ProtectedRoute>
  );
};

import React from 'react';
import './App.scss';
import { BrowserRouter, Routes, Navigate, Route } from 'react-router-dom';
import { DeliveryDeliveryScreen } from './Screen/DeliveryStatusScreen';
import { LateDeliveryScreen } from './Screen/LateDeliveryScreen';
import { ExpectedDeliveryScreen } from './Screen/ExpectedDeliveryScreen';
import { DemoScreen } from './Screen/DemoScreen';
import { ErrorScreen } from './Screen/ErrorScreen';
import { YesSummaryScreen } from './Screen/YesOnline/YesSummaryScreen';
import { YesDetailScreen } from './Screen/YesOnline/YesDetailScreen';
import secureLocalStorage from 'react-secure-storage';

function App() {
  const queryParameters = new URLSearchParams(window.location.search);
  const salesToken = queryParameters.get('salesToken') || secureLocalStorage.getItem('salesToken');
  const yesToken = queryParameters.get('yesToken') || secureLocalStorage.getItem('yesToken');

  const reRouter = () => {
    if (salesToken) {
      return '/delivery-status';
    } else if (yesToken) {
      return '/yes-summary';
    } else {
      return '/error';
    }
  };

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Navigate to={reRouter()} replace={true} />} />
        <Route path="/delivery-status" element={<DeliveryDeliveryScreen />} />
        <Route path="/late-delivery" element={<LateDeliveryScreen />} />
        <Route path="/expected-delivery" element={<ExpectedDeliveryScreen />} />
        <Route path="/yes-summary" element={<YesSummaryScreen />} />
        <Route path="/yes-details" element={<YesDetailScreen />} />
        <Route path="/demo" element={<DemoScreen />} />
        <Route path="/error" element={<ErrorScreen />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;

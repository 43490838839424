import { Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import './Submenu.scss'; // Import your CSS file for styling
import { UserRole } from '../../services/type';
import { mockProfile } from '../../services/mock';
import { useAuthStore } from '../../state/authen-store';

interface SubmenuProps {
  screen?: string;
  onActiveMenu?: (submenuName: string) => void;
  currentActiveSubMenu?: string;
}

const listSubmenu = [
  {
    screenName: 'deliveryStatus',
    submenuItems: [
      { index: 1, submenuName: 'BY REGION', role: UserRole.DELIVERY_REGION },
      { index: 2, submenuName: 'BY AREA', role: UserRole.DELIVERY_AREA },
      { index: 3, submenuName: 'BY DEALER', role: UserRole.DELIVERY_DEALER },
    ],
  },
  {
    screenName: 'lateDeliveryStatus',
    submenuItems: [
      { index: 1, submenuName: 'DELIVERY', role: UserRole.LATE_DELIVERY },
      { index: 2, submenuName: 'PRODUCTION', role: UserRole.LATE_PRODUCTION },
    ],
  },
];

export const Submenu: React.FC<SubmenuProps> = ({ screen, onActiveMenu, currentActiveSubMenu }) => {
  const [activeSubmenu, setActiveSubmenu] = useState<number>(1);
  const { profileData } = useAuthStore();
  const submenuGroup = listSubmenu.find((item) => item.screenName === screen);

  if (!submenuGroup) {
    return null; // Return null if the provided screen prop doesn't match any submenu
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    if (currentActiveSubMenu) {
      const currentSubmenuData = submenuGroup?.submenuItems.find((item) => item.submenuName === currentActiveSubMenu);
      setActiveSubmenu(currentSubmenuData?.index || 1);
      onActiveMenu && onActiveMenu(currentSubmenuData?.submenuName || submenuGroup.submenuItems[1].submenuName);
    }
  }, [currentActiveSubMenu]);

  return (
    <Stack className="directionContainer" flexDirection={'row'} marginTop={'0px'}>
      {submenuGroup.submenuItems.map((submenuItem) => {
        const permission =
          !!profileData && profileData.permissions.findIndex((value) => value.includes(submenuItem.role)) >= 0;
        if (permission)
          return (
            <Stack
              key={submenuItem.index}
              onClick={() => {
                setActiveSubmenu(submenuItem.index);
                !!onActiveMenu && onActiveMenu(submenuItem.submenuName);
              }}
              className={activeSubmenu === submenuItem.index ? 'selectedDelivery labelSubmenu ' : 'labelSubmenu'}
            >
              {submenuItem.submenuName}
            </Stack>
          );

        return null;
      })}
    </Stack>
  );
};

import React from 'react';
import { Button, TextField, Typography, Grid, Stack, Divider } from '@mui/material';
import DeliveryStatusPieChart from '../deliveryStatusPieChart/DeliveryStatusPieChart';
import GroupedLabel from '../groupedLabel/groupedLabel';

const mockList1 = [
  { product_code: 'B6FK00010A' },
  { product_code: 'B8B800011A' },
  { product_code: 'B6FK00012A' },
  { product_code: 'B8B800011E' },
  { product_code: 'BKF100010B' },
  { product_code: 'BJK200010B' },
  { product_code: 'BNC100010B' },
  { product_code: 'B8B800010E' },
  { product_code: 'B6FK00013A' },
  { product_code: 'B8B800010A' },
];

const mockList2 = [
  { region: 'BANGKOK' },
  { region: 'CENTRAL' },
  { region: 'NORTH' },
  { region: 'NORTH-EAST' },
  { region: 'SOUTH' },
];

const mockList3 = [{ startMonth: 2, startYear: 2022, endMonth: 8, endYear: 2023 }];

const mockList4 = [{ month: 4, year: 2022 }];

const data1 = [
  { name: 'Delivered', value: 5728 },
  { name: 'In Transit', value: 4413 },
  { name: 'Wait for Shipping', value: 1419 },
  { name: 'In Production', value: 3348 },
  { name: 'Rejected', value: 20 },
];

const data2 = [
  { name: 'Delivered', value: 5728 },
  { name: 'In Transit', value: 4413 },
  { name: 'Wait for Shipping', value: 1419 },
  { name: 'In Production', value: 3348 },
  { name: 'Rejected', value: 200 },
];

// const getPeriod = (start: boolean, period: any) => {
//   if(start){
//     set()
//   }else{
//     setStar('dddd');
//   }
// };

// const startDate='';
// const endDate='';
const Component = () => {
  return (
    <div className={'card-container'}>
      <Typography variant="h3">Component Library</Typography>

      <Grid container spacing={2} style={{ padding: '0 0px' }}>
        <DeliveryStatusPieChart titleLabel="NATIONWIDE" dataList={data1} />
        <DeliveryStatusPieChart titleLabel="NORTH" dataList={data2} />
        <DeliveryStatusPieChart titleLabel="SOUTH" dataList={data1} />
        <Grid item>
          <DeliveryStatusPieChart titleLabel="NORTH-EAST" dataList={data2} />
        </Grid>
        <Grid item>
          <DeliveryStatusPieChart titleLabel="CENTRAL" dataList={data1} />
        </Grid>
        <Grid item>
          <DeliveryStatusPieChart titleLabel="NORTH-EAST" dataList={data2} />
        </Grid>
      </Grid>

      <Button variant="text">Text</Button>
      <Button variant="contained">Contained</Button>
      <Button variant="outlined">Outlined</Button>
      <TextField id="outlined-basic" label="Outlined" variant="outlined" />
      <TextField id="filled-basic" label="Filled" variant="filled" />
      <TextField id="standard-basic" label="Standard" variant="standard" />

      <Typography variant="h3">Demo Dropdown w/ search</Typography>
      <Stack
        style={{ justifyContent: 'center' }}
        direction="row"
        divider={<Divider orientation="vertical" flexItem />}
        spacing={2}
      >
        <>
          <GroupedLabel titleLabel="Region" dataList={mockList2} />
          <GroupedLabel titleLabel="Model Code" dataList={mockList2} disabled />
          <GroupedLabel titleLabel="Product Code" dataList={mockList1} typeOfData="multiple" />
        </>
        <GroupedLabel titleLabel="Period" dataList={mockList3} typeOfData="datePeriod" />
      </Stack>
      <Stack style={{ justifyContent: 'center' }} direction="row" spacing={2}>
        <GroupedLabel titleLabel="Month-Year" dataList={mockList4} typeOfData="date" isOnlyMonthYear />
        <GroupedLabel
          titleLabel="Start Date"
          dataList={mockList3}
          typeOfData="datePeriod"
          isStartMonthYear
          // onChangeDate={(isPeriod: any) => getPeriod(true, isPeriod)}
          // label={startDate}
        />

        <GroupedLabel
          titleLabel="End Date"
          dataList={mockList3}
          typeOfData="datePeriod"
          isEndMonthYear
          // onChangeDate={(isPeriod: any) => getPeriod(false, isPeriod)}
          // labelEnd={!!endDate ? endDate :''}
        />
      </Stack>
    </div>
  );
};

export default Component;

import React, { useEffect, useState } from 'react';
import { Divider, Stack } from '@mui/material';
import './LateDeliveryScreen.scss';
import { PROFILE_ROLE } from './mock';
import { useFilterSaleStore } from '../../state/filter-sale-store';
import {
  formatArrToDropDown,
  formatArrObjToDropdown,
  checkRoleManageMent,
  changeValuesToLabel,
} from '../../helper/utils-helper';
import { RegionService } from '../../services/regionServices';
import { ModelProductService } from '../../services/modelProductService';
import { OwnerSaleService } from '../../services/ownerSaleService';
import { useNavigate } from 'react-router-dom';
import { mockProfile } from '../../services/mock';
import GroupedLabel from '../../components/groupedLabel/groupedLabel';
import { GroupedLabelPeriod } from '../../components/groupedLabelPeriod/groupedLabelPeriod';
import { GroupedLabelMultiple } from '../../components/groupedLabel/groupedLabelMutiple';
import DrawerSidebarDateNew from '../../components/drawerSidebarDate/drawerSidebarDateNew';
import { ProfileDataType, useAuthStore } from '../../state/authen-store';
import BackdropWithLoading from '../../components/loading';
import { filterPayload } from '../../services/type';
import GroupedLabelSingle from '../../components/groupedLabel/groupedLabelSingle';
import moment from 'moment';

export interface FilterLateDeliveryProps {
  showDisplay: string;
  callBackFilterParams: (value: any) => void;
  activeSubmenu: string;
  loadFilter: (loading: boolean) => void;
}

export const FilterLateDelivery = (props: FilterLateDeliveryProps) => {
  const currMonth = Number(moment().format('MM'));
  const currYear = Number(moment().year());

  const defaultOnlyMonth = currMonth;
  const defaultOnlyYear = currYear;
  const [openStartPeriod, seOpenStartPeriod] = useState<boolean>(false);
  const [openEndPeriod, setOpenEndPeriod] = useState<boolean>(false);

  const [selectedStartMonth, setSelectedStartMonth] = React.useState(defaultOnlyMonth);
  const [selectedStartYear, setSelectedStartYear] = React.useState(defaultOnlyYear - 1);
  const [selectedEndMonth, setSelectedEndMonth] = React.useState(defaultOnlyMonth);
  const [selectedEndYear, setSelectedEndYear] = React.useState(defaultOnlyYear);
  const mockDate = [{ month: currMonth, year: currYear }];
  const { showDisplay, callBackFilterParams, loadFilter, activeSubmenu } = props;
  const { profileData } = useAuthStore();
  const { role, regionCode, subregionCode, areaCode } = profileData || ({} as ProfileDataType);
  const { filterModelName, setModelNames } = useFilterSaleStore();
  const { filterDealer, setDealers } = useFilterSaleStore();
  const { filterColor, setColors } = useFilterSaleStore();
  const { filterModelCode, setModelCodes } = useFilterSaleStore();
  const { filterProductCodes, setProductCodes } = useFilterSaleStore();
  const { filterOwnerCodes, setOwnerCodes } = useFilterSaleStore();
  const { filterProvince, setProvinces } = useFilterSaleStore();
  const { filterArea, setAreas } = useFilterSaleStore();
  const { filterSubRegion, setSubRegions } = useFilterSaleStore();
  const { filterRegion, setRegions } = useFilterSaleStore();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);

  const checkRoleFilter = () => {
    if (role === PROFILE_ROLE.DM || role === PROFILE_ROLE.SP || role === PROFILE_ROLE.GM || role === PROFILE_ROLE.HD) {
      return true;
    }
    return false;
  };
  const intitialParams = {
    subregionCodes: !checkRoleFilter() ? subregionCode : null,
    regionCode: !checkRoleFilter() ? regionCode : null,
    areaCodes: !checkRoleFilter() ? areaCode : null,
    month: (currMonth - 1).toString().padStart(2, '0'),
    year: currYear.toString(),
    startMonth: selectedStartMonth.toString().padStart(2, '0'),
    startYear: selectedStartYear.toString(),
    endMonth: selectedEndMonth.toString().padStart(2, '0'),
    endYear: selectedEndYear.toString(),
    provinceCodes: null,
    ownerCodes: null,
    dealerCodes: null,
    modelNames: null,
    modelCodes: null,
    productCodes: null,
    colorCodes: null,
  };
  const [isParamsFilter, setIsParamsFilter] = useState<filterPayload>(intitialParams);

  const getRegion = () => {
    setLoading(true);
    RegionService.getRegions(isParamsFilter)
      .then((resp) => {
        const data = resp.data.data;
        setLoading(false);
        if (data) {
          setRegions(data);
        } else {
          handleApiError(resp.data);
        }
      })
      .catch((error) => {
        // Handle errors here
        handleApiError(error.message);
        console.error('Error fetching getRegion:', error);
      });
  };

  const getSubRegion = (regionCode: number | string | any) => {
    setLoading(true);
    const isParams = {
      regionCode: regionCode ? regionCode : null,
    };
    RegionService.getSubRegions(isParams)
      .then((resp) => {
        setLoading(false);
        const data = resp.data.data;
        if (data) {
          setSubRegions(data);
        } else {
          handleApiError(resp.data);
        }
      })
      .catch((error) => {
        // Handle errors here
        handleApiError(error.message);
        console.error('Error fetching getSubRegion:', error);
      });
  };

  const getArea = (regionCode: number | string | any, subregionCode: any) => {
    setLoading(true);
    const isParams = {
      regionCode: regionCode || null,
      subregionCodes: subregionCode || null,
    };
    RegionService.getAreas(isParams)
      .then((resp) => {
        setLoading(false);
        const data = resp.data.data;
        if (data) {
          setAreas(data);
        } else {
          handleApiError(resp.data);
        }
      })
      .catch((error) => {
        handleApiError(error.message);
        console.error('Error fetching getArea:', error);
      });
  };

  const getProvince = (regionCode: number | string | any, subregionCode: any, areaCode: any) => {
    setLoading(true);
    const isParams = {
      regionCode: regionCode || null,
      subregionCodes: [subregionCode] || null,
      areaCodes: [areaCode] || null,
    };
    RegionService.getProvinces(isParams)
      .then((resp) => {
        setLoading(false);
        const data = resp.data.data;
        if (data) {
          setProvinces(data);
        } else {
          handleApiError(resp.data);
        }
      })
      .catch((error) => {
        handleApiError(error.message);
        console.error('Error fetching getProvince:', error);
      });
  };

  const getOwnerCode = () => {
    setLoading(true);
    OwnerSaleService.getOwnerCodes(isParamsFilter)
      .then((resp) => {
        setLoading(false);
        const data = resp.data.data;
        if (data) {
          setOwnerCodes(data);
        } else {
          handleApiError(resp.data);
        }
      })
      .catch((error) => {
        handleApiError(error.message);
        console.error('Error fetching getOwnerCode:', error);
      });
  };

  const getColor = () => {
    setLoading(true);
    ModelProductService.getColors(isParamsFilter)
      .then((resp) => {
        setLoading(false);
        const data = resp.data.data;
        if (data) {
          setColors(data);
        } else {
          handleApiError(resp.data);
        }
      })
      .catch((error) => {
        handleApiError(error.message);
        console.error('Error fetching getColor:', error);
      });
  };

  const getProductCode = () => {
    setLoading(true);
    ModelProductService.getProductCodes(isParamsFilter)
      .then((resp) => {
        setLoading(false);
        const data = resp.data.data;
        if (data) {
          setProductCodes(data);
        } else {
          handleApiError(resp.data);
        }
      })
      .catch((error) => {
        handleApiError(error.message);
        console.error('Error fetching getProductCode:', error);
      });
  };

  const getModelCode = () => {
    setLoading(true);
    ModelProductService.getModelCodes(isParamsFilter)
      .then((resp) => {
        setLoading(false);
        const data = resp.data.data;
        if (data) {
          setModelCodes(data);
        } else {
          handleApiError(resp.data);
        }
      })
      .catch((error) => {
        handleApiError(error.message);
        console.error('Error fetching getModelCode:', error);
      });
  };

  const getDealer = () => {
    setLoading(true);
    OwnerSaleService.getDealers(isParamsFilter)
      .then((resp) => {
        setLoading(false);
        const data = resp.data.data;
        if (data) {
          setDealers(data);
        } else {
          handleApiError(resp.data);
        }
      })
      .catch((error) => {
        handleApiError(error.message);
        console.error('Error fetching getDealer:', error.code);
      });
  };

  const getModelName = () => {
    setLoading(true);
    ModelProductService.getModelNames(isParamsFilter)
      .then((resp) => {
        setLoading(false);
        const data = resp.data.data;
        if (data) {
          setModelNames(data);
        } else {
          handleApiError(resp.data);
        }
      })
      .catch((error) => {
        handleApiError(error.message);
        console.error('Error fetching getModelName:', error);
      });
  };

  const handleApiError = (err: any) => {
    if (err.code === 'ERR_INVALID_JWT') {
      return navigate('/error', {
        state: {
          errorMessage: err.message || err.code || '',
          originalPath: '/late-delivery',
          errtokenExpire: true,
        },
        replace: true,
      });
    }
    return navigate('/error', {
      state: {
        errorMessage: err.message || err.code || '',
        originalPath: '/late-delivery',
        errtokenExpire: false,
      },
      replace: true,
    });
  };
  useEffect(() => {
    callBackFilterParams(isParamsFilter);
  }, [isParamsFilter]);

  useEffect(() => {
    // console.warn('activeSubmenu', activeSubmenu);

    setIsParamsFilter(intitialParams);

    // console.warn('  checkRoleRegionFilter()', checkRoleRegionFilter());
  }, [activeSubmenu]);

  const checkRoleRegionFilter = () => {
    if (role === PROFILE_ROLE.DM || role === PROFILE_ROLE.SP) {
      return true;
    }
    return false;
  };

  // console.warn('checkRoleFilter', checkRoleFilter());
  const updateFilterValue = (key: string, value: any) => {
    // Create a new object with the updated month field
    const updatedParamsFilter = {
      ...isParamsFilter, // Copy the existing state
      [key]: value, // Update the month field
    };
    // Update the state with the new object
    setIsParamsFilter(updatedParamsFilter);
  };

  const handleSelectionPeriodMonthClick = (start: any, end: any) => {
    setSelectedStartMonth(start);
    setSelectedEndMonth(end);
  };
  const handleSelectionPeriodYearClick = (start: any, end: any) => {
    setSelectedStartYear(start);
    setSelectedEndYear(end);
  };

  useEffect(() => {
    // Create a new object with the updated month field
    const updatedParamsFilter = {
      ...isParamsFilter, // Copy the existing state
      startMonth: selectedStartMonth.toString().padStart(2, '0'),
      startYear: selectedStartYear.toString(),
      endMonth: selectedEndMonth.toString().padStart(2, '0'),
      endYear: selectedEndYear.toString().padStart(2, '0'),
    };
    // Update the state with the new object
    setIsParamsFilter(updatedParamsFilter);
  }, [selectedStartMonth, selectedStartYear, selectedEndMonth, selectedEndYear]);

  useEffect(() => {
    const fetchData = async () => {
      // console.warn('fetchDatafetchDatafetchData');
      loadFilter(true);
      if (profileData) {
        try {
          setLoading(true);
          const promises = [];
          promises.push(getRegion());
          promises.push(getSubRegion(profileData.regionCode));
          promises.push(getArea(profileData.regionCode, profileData.subregionCode));
          promises.push(getProvince(profileData.regionCode, profileData.subregionCode, profileData.areaCode));
          promises.push(getDealer());
          promises.push(getModelName());
          promises.push(getColor());
          promises.push(getModelCode());
          promises.push(getProductCode());
          promises.push(getOwnerCode());

          await Promise.all(promises);
          setTimeout(() => {
            loadFilter(false);
            setLoading(false);
          }, 450);
        } catch (error: any) {
          setLoading(false);
          loadFilter(false);
          handleApiError(error);
        }
      }
    };
    fetchData();
  }, []);
  // console.warn('checkRoleFilter', checkRoleFilter());
  return (
    <>
      <Divider orientation="horizontal" flexItem style={{ marginBottom: '5px' }} />
      <div id="group-container">
        <Stack
          style={{ justifyContent: 'center' }}
          direction="row"
          divider={<Divider orientation="vertical" flexItem />}
          spacing={2}
        >
          <>
            <GroupedLabelSingle
              titleLabel="Region"
              dataList={formatArrObjToDropdown(filterRegion, 'regionName', 'regionCode')}
              selectedTemp={checkRoleRegionFilter() ? null : regionCode}
              disabled={!checkRoleRegionFilter()}
              textLabelTemp={
                checkRoleRegionFilter()
                  ? null
                  : changeValuesToLabel(regionCode, formatArrObjToDropdown(filterRegion, 'regionName', 'regionCode'))
              }
              isSelectFilter={(key: string, value: any) => {
                updateFilterValue(key, value);
              }}
            />

            <GroupedLabelMultiple
              titleLabel="Sub-Region"
              dataList={formatArrObjToDropdown(filterSubRegion, 'subregionName', 'subregionCode')}
              disabled={!checkRoleFilter()}
              isSelectFilter={(key: string, value: any) => {
                updateFilterValue(key, value);
              }}
              selectedTemp={!checkRoleFilter() ? subregionCode : null}
              textLabelTemp={
                !checkRoleFilter()
                  ? changeValuesToLabel(
                      subregionCode,
                      formatArrObjToDropdown(filterSubRegion, 'subregionName', 'subregionCode'),
                    )
                  : null
              }
            />
          </>
          <GroupedLabelMultiple
            titleLabel="Owner Code"
            dataList={formatArrToDropDown(filterOwnerCodes)}
            isSelectFilter={(key: string, value: any) => {
              updateFilterValue(key, value);
            }}
            disabled={false}
          />
          <>
            <GroupedLabelMultiple
              titleLabel="Model Name"
              dataList={formatArrToDropDown(filterModelName) || []}
              isSelectFilter={(key: string, value: any) => {
                updateFilterValue(key, value);
              }}
              disabled={false}
            />
            <GroupedLabelMultiple
              titleLabel="Model Code"
              dataList={formatArrToDropDown(filterModelCode) || []}
              isSelectFilter={(key: string, value: any) => {
                updateFilterValue(key, value);
              }}
              disabled={false}
            />
          </>
          {props.showDisplay === 'DELIVERY_BY' || props.showDisplay === 'PRODUCTION_BY' ? (
            <GroupedLabel
              titleLabel="Month-Year"
              dataList={mockDate}
              typeOfData="date"
              isOnlyMonthYear
              isSelectFilter={(key: string, value: any) => {
                const updatedParamsFilter = {
                  ...isParamsFilter, // Copy the existing state
                  month: value.month.toString().padStart(2, '0'), // Update the  field & value
                  year: value.year.toString(),
                };
                // console.warn('updatedParamsFilterupdatedParamsFilter', updatedParamsFilter);
                setIsParamsFilter(updatedParamsFilter);
              }}
              disabled={false}
              isLockMonthCurrent={true}
            />
          ) : (
            <GroupedLabelPeriod
              titleLabel="Start Date"
              isEndMonthYear={false}
              selectedStartMonth={selectedStartMonth}
              selectedStartYear={selectedStartYear}
              selectedEndMonth={selectedEndMonth}
              selectedEndYear={selectedEndYear}
              onClick={() => seOpenStartPeriod(!openStartPeriod)}
            />
          )}
        </Stack>
      </div>
      <div id="group-container">
        <Stack
          style={{ justifyContent: 'center' }}
          direction="row"
          divider={<Divider orientation="vertical" flexItem />}
          spacing={2}
        >
          <>
            <GroupedLabelMultiple
              titleLabel="Area"
              dataList={formatArrObjToDropdown(filterArea, 'areaName', 'areaCode')}
              disabled={!checkRoleFilter()}
              isSelectFilter={(key: string, value: any) => {
                updateFilterValue(key, value);
              }}
              selectedTemp={!checkRoleFilter() ? areaCode : null}
              textLabelTemp={
                !checkRoleFilter()
                  ? changeValuesToLabel(areaCode, formatArrObjToDropdown(filterArea, 'areaName', 'areaCode'))
                  : null
              }
            />

            <GroupedLabelMultiple
              titleLabel="Province"
              dataList={formatArrObjToDropdown(filterProvince, 'provinceNameTh', 'provinceCode')}
              isSelectFilter={(key: string, value: any) => {
                updateFilterValue(key, value);
              }}
              disabled={false}
            />
          </>
          <GroupedLabelMultiple
            titleLabel="Dealer"
            dataList={formatArrObjToDropdown(filterDealer, 'dealerNameTh', 'dealerCode' || [])}
            isSelectFilter={(key: string, value: any) => {
              updateFilterValue(key, value);
            }}
            disabled={false}
          />
          <>
            <GroupedLabelMultiple
              titleLabel="Product Code"
              dataList={formatArrToDropDown(filterProductCodes)}
              isSelectFilter={(key: string, value: any) => {
                updateFilterValue(key, value);
              }}
              disabled={false}
            />
            <GroupedLabelMultiple
              titleLabel="Color"
              dataList={formatArrObjToDropdown(filterColor, 'colorNameTh', 'colorCode')}
              isSelectFilter={(key: string, value: any) => {
                updateFilterValue(key, value);
              }}
              disabled={false}
            />
          </>
          {props.showDisplay === 'DELIVERY_BY' || props.showDisplay === 'PRODUCTION_BY' ? (
            <div className="empty-box"></div>
          ) : (
            <GroupedLabelPeriod
              titleLabel="End Date"
              isEndMonthYear={true}
              selectedStartMonth={selectedStartMonth}
              selectedStartYear={selectedStartYear}
              selectedEndMonth={selectedEndMonth}
              selectedEndYear={selectedEndYear}
              onClick={() => setOpenEndPeriod(!openEndPeriod)}
            />
          )}
        </Stack>
      </div>
      <Divider orientation="horizontal" flexItem style={{ marginTop: '5px', marginBottom: '5px' }} />
      <DrawerSidebarDateNew
        titleLabel={'Start'}
        typeOfData={'datePeriod'}
        isDrawerOpen={openStartPeriod}
        handleCloseDrawer={() => seOpenStartPeriod(false)}
        isBackdropOpen={openStartPeriod}
        selectedStartMonth={selectedStartMonth}
        selectedStartYear={selectedStartYear}
        selectedEndMonth={selectedEndMonth}
        selectedEndYear={selectedEndYear}
        handleSelectionMonthClick={handleSelectionPeriodMonthClick}
        handleSelectionYearClick={handleSelectionPeriodYearClick}
        isEndMonthYear={false}
        isStartMonthYear={true}
        handleApply={() => {
          seOpenStartPeriod(false);
        }}
      />

      <DrawerSidebarDateNew
        titleLabel={'End'}
        typeOfData={'datePeriod'}
        isDrawerOpen={openEndPeriod}
        handleCloseDrawer={() => setOpenEndPeriod(false)}
        isBackdropOpen={openEndPeriod}
        selectedStartMonth={selectedStartMonth}
        selectedStartYear={selectedStartYear}
        selectedEndMonth={selectedEndMonth}
        selectedEndYear={selectedEndYear}
        handleSelectionMonthClick={handleSelectionPeriodMonthClick}
        handleSelectionYearClick={handleSelectionPeriodYearClick}
        isEndMonthYear={true}
        isStartMonthYear={false}
        handleApply={() => {
          setOpenEndPeriod(false);
        }}
      />
      <BackdropWithLoading show={loading} />
    </>
  );
};

import React, { useEffect, useState, createRef } from 'react';
import { Divider, Grid, Stack, Typography } from '@mui/material';
import './DeliveryStatusScreen.scss';
import DeliveryStatusPieChart from '../../components/deliveryStatusPieChart/DeliveryStatusPieChart';
import { Submenu } from '../../components/subMenu';
import { DeliveryStatusService } from '../../services/deliveryStatusService';
import { LastUpdatedService } from '../../services/lastUpdatedService';
import { DELIVERY_STATUS_SUBMENU, DeliveryData, PROFILE_ROLE, rootPageDelivery } from './constant';
import StackedBarChart from '../../components/Chart/StackedBarChart/StackedBarChart';
import { TableStack } from '../../components/Table/TableStack';
import { ArrowForwardIos } from '@mui/icons-material';
import { FilterDeliveryDelivery } from './filter';
import BackdropWithLoading from '../../components/loading';
import { useNavigate } from 'react-router-dom';
import { barColorsDeliveryStatus, columnDeliveryStatus } from './mock';
import { ProfileDataType, useAuthStore } from '../../state/authen-store';
import { ProtectedRoute } from '../protectedRoute';
import { filterPayload } from '../../services/type';
import { changeValuesToLabel, formatArrObjToDropdown, formatDate } from '../../helper/utils-helper';
import { useFilterSaleStore } from '../../state/filter-sale-store';

type DataHorizontalRow = {
  total?: number | 0;
  name: string;
  mcDelivered: number;
  mcInTransit: number;
  mcWaitForShipping: number;
  mcInProduction: number;
  mcRejected: number;
};

export type DelStatusByRegionType = {
  regionCode: string;
  regionName: string;
  mcDelivered: number;
  mcInTransit: number;
  mcWaitForShipping: number;
  mcRejected: number;
  baseMcOrdered: number;
  canceledMcOrdered: number;
  mcInProduction: number;
  pieChartData: { name: string; value: number }[];
};

export type dataBarStackType = {
  code?: number;
  name: string;
  data: { [key: string]: number };
};

interface DelStatusByAreaType {
  areaCode: string;
  areaName: string;
  mcDelivered: number;
  mcInTransit: number;
  mcWaitForShipping: number;
  mcRejected: number;
  baseMcOrdered: number;
  canceledMcOrdered: number;
  mcInProduction: number;
}

interface DelStatusByDealerType {
  dealerCode: string;
  dealerNameTh: string;
  dealerNameEn: string;
  mcDelivered: number;
  mcInTransit: number;
  mcWaitForShipping: number;
  mcRejected: number;
  baseMcOrdered: number;
  canceledMcOrdered: number;
  mcInProduction: number;
  pieChartData: { name: string; value: number }[];
}

export const DeliveryDeliveryScreen = () => {
  const [activeSubmenu, setActiveSubmenu] = useState<string>();
  const [deliveryData, setDeliveryData] = useState<DeliveryData[]>([]);
  const [byRegionArrData, setByRegionArrData] = useState<DelStatusByRegionType[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadFilter, setLoadFilter] = useState<boolean>(true);
  const [byDealerArrData, setByDealerArrData] = useState<DelStatusByDealerType[]>([]);
  const [corePieChartData, setCorePieChartData] = useState<DelStatusByRegionType>();
  const [areaPieChart, setAreaPieChart] = useState<{ name: string; value: any }[]>();
  const [lastUpdatedData, setLastUpdatedData] = useState<string>('');
  const navigate = useNavigate();
  const barStackDataKeys = ['mcDelivered', 'mcInTransit', 'mcWaitForShipping', 'mcInProduction', 'mcRejected'];
  const [barAreaArrData, setBarAreaArrData] = useState<dataBarStackType[]>([]);
  const [byAreaArrData, setByAreaArrData] = useState<DelStatusByAreaType[]>([]);
  const { profileData } = useAuthStore();
  const [byAreaArrDataTable, setByAreaArrDataTable] = useState<DataHorizontalRow[]>([]);
  const [barDealerArrData, setBarDealerArrData] = useState<dataBarStackType[]>([]);
  const { permissions, role } = profileData || ({} as ProfileDataType);
  const [checkNotFound, setCheckNotFound] = useState<boolean>(false);
  const [byDealerArrDataTable, setByDealerArrDataTable] = useState<DataHorizontalRow[]>([]);
  const [clickRegion, setClickRegion] = useState<string>('');
  const [headerTitle, setHeaderTitle] = useState<string>('OVERALL DELIVERY STATUS');
  const scrollableElementRef = createRef<HTMLDivElement>();
  const [params, setParams] = useState<filterPayload>({});
  const [rolePermission, setRolePermission] = useState<any>();
  const { filterRegion } = useFilterSaleStore();

  const renderActiveScreen = () => {
    if (role === PROFILE_ROLE.DM || role === PROFILE_ROLE.SP) {
      return DELIVERY_STATUS_SUBMENU.BY_REGION;
    } else if (role === PROFILE_ROLE.GM || role === PROFILE_ROLE.HD) {
      return DELIVERY_STATUS_SUBMENU.BY_AREA;
    } else {
      return DELIVERY_STATUS_SUBMENU.BY_DEALER;
    }
  };

  const handleSetShowDisplay = (value: any) => {
    setActiveSubmenu(value);
  };

  const getByRegionData = () => {
    setLoading(true);
    DeliveryStatusService.getOverallStatus(params)
      .then((resp) => {
        setLoading(false);
        const data = resp?.data?.data;

        // console.warn(data);
        if (data) {
          const sumData = data.reduce(
            (accumulator: any, currentValue: any) => {
              accumulator.mcDelivered += currentValue.mcDelivered;
              accumulator.mcInTransit += currentValue.mcInTransit;
              accumulator.mcWaitForShipping += currentValue.mcWaitForShipping;
              accumulator.mcRejected += currentValue.mcRejected;
              accumulator.mcInProduction += currentValue.mcInProduction;
              accumulator.totalAll +=
                currentValue.mcDelivered +
                currentValue.mcInTransit +
                currentValue.mcWaitForShipping +
                currentValue.mcRejected +
                currentValue.mcInProduction;
              return accumulator;
            },
            {
              mcDelivered: 0,
              mcInTransit: 0,
              mcWaitForShipping: 0,
              mcRejected: 0,
              mcInProduction: 0,
              totalAll: 0,
              regionName: 'NATIONWIDE',
            },
          );
          const addNation = [sumData].concat(data);
          setDeliveryData(addNation);
          const byRegionDataFormat = addNation.map((item: any) => {
            return {
              ...item,
              pieChartData: [
                { name: 'Delivered', value: item.mcDelivered },
                { name: 'In Transit', value: item.mcInTransit },
                { name: 'Wait for Shipping', value: item.mcWaitForShipping },
                { name: 'In Production', value: item.mcInProduction },
                { name: 'Rejected', value: item.mcRejected },
              ],
            };
          });
          setByRegionArrData(byRegionDataFormat);
          setCorePieChartData(byRegionDataFormat[0]);
          setCheckNotFound(false);
        } else {
          setByRegionArrData([]);
          setCorePieChartData(undefined);
          setCheckNotFound(true);
        }
      })
      .catch((error) => {
        setByRegionArrData([]);
        setCorePieChartData(undefined);
        setLoading(false);
        handleApiError(error);
      });
  };
  const loadFilterEnd = (loading: boolean) => {
    setLoadFilter(loading);
  };

  const getByAreaData = () => {
    setLoading(true);
    DeliveryStatusService.getDsmByArea(params)
      .then((resp) => {
        setLoading(false);
        const data = resp?.data?.data;
        if (data) {
          const byAreaDataFormat = formatDataForBarStack(data, 'areaName');
          setByAreaArrData(data);
          setBarAreaArrData(byAreaDataFormat);
          setByAreaArrDataTable(formatDataForTableStack(byAreaDataFormat));

          const sumAreaData = data.reduce(
            (accumulator: any, currentValue: any) => {
              accumulator.mcDelivered += currentValue.mcDelivered;
              accumulator.mcInTransit += currentValue.mcInTransit;
              accumulator.mcWaitForShipping += currentValue.mcWaitForShipping;
              accumulator.mcRejected += currentValue.mcRejected;
              accumulator.mcInProduction += currentValue.mcInProduction;
              return accumulator;
            },
            {
              mcDelivered: 0,
              mcInTransit: 0,
              mcWaitForShipping: 0,
              mcRejected: 0,
              mcInProduction: 0,
              regionName: 'SUM OF AREA',
            },
          );
          const byAreaPieChartFormat = [
            { name: 'Delivered', value: sumAreaData.mcDelivered },
            { name: 'In Transit', value: sumAreaData.mcInTransit },
            { name: 'Wait for Shipping', value: sumAreaData.mcWaitForShipping },
            { name: 'In Production', value: sumAreaData.mcInProduction },
            { name: 'Rejected', value: sumAreaData.mcRejected },
          ];
          setAreaPieChart(byAreaPieChartFormat);
          setCheckNotFound(false);
          setLoading(false);
        } else {
          setByAreaArrData([]);
          setBarAreaArrData([]);
          setByAreaArrDataTable([]);
          setCheckNotFound(true);
        }
      })
      .catch((error) => {
        setByAreaArrData([]);
        setBarAreaArrData([]);
        setByAreaArrDataTable([]);
        setLoading(false);
        handleApiError(error);
      });
  };

  const getByDealerData = () => {
    setLoading(true);
    DeliveryStatusService.getDsmByDealer(params)
      .then((resp) => {
        setLoading(false);
        const data = resp?.data?.data;
        if (data) {
          const byDealerDataFormat = formatDataForBarStack(data, 'dealerNameTh');
          setByDealerArrData(data);
          setBarDealerArrData(byDealerDataFormat);
          setByDealerArrDataTable(formatDataForTableStack(byDealerDataFormat));
          setCheckNotFound(false);
        } else {
          setByDealerArrData([]);
          setBarDealerArrData([]);
          setByDealerArrDataTable(formatDataForTableStack([]));
          setCheckNotFound(true);
        }
      })
      .catch((error) => {
        setLoading(false);
        setByDealerArrData([]);
        setBarDealerArrData([]);
        setByDealerArrDataTable(formatDataForTableStack([]));
        handleApiError(error);
      });
  };

  const formatDataForBarStack = (arrData: any[], keyName: string) => {
    return arrData.map((item: any) => ({
      name: item[keyName],
      data: {
        mcDelivered: item['mcDelivered'],
        mcInTransit: item['mcInTransit'],
        mcWaitForShipping: item['mcWaitForShipping'],
        mcInProduction: item['mcInProduction'],
        mcRejected: item['mcRejected'],
      },
    }));
  };

  const formatDataForTableStack = (arrData: any[]) => {
    return arrData.map((item) => {
      const total = Object.values(item.data).reduce((a: any, b: any) => a + b, 0);
      return { total, name: item.name, ...item.data };
    });
  };

  const checkRootDsmPage = rootPageDelivery.map((page: string) => {
    const permissionRootPage = !!permissions && permissions.findIndex((value) => value.includes(page)) >= 0;
    return permissionRootPage;
  });

  useEffect(() => {
    setActiveSubmenu(renderActiveScreen());
    setRolePermission(checkRootDsmPage);
  }, [role]);

  const getLastUpdated = () => {
    // setLoading(true);
    LastUpdatedService.getDeliveryStatusLastUpdated(params)
      .then((resp) => {
        // setLoading(false);
        const data = resp?.data?.data?.lastUpdated;
        if (data) {
          const formatedDate = formatDate(data);
          setLastUpdatedData(formatedDate);
        } else {
          setLastUpdatedData('');
        }
      })
      .catch((error) => {
        // setLoading(false);
        setLastUpdatedData('');
        handleApiError(error);
      });
  };

  useEffect(() => {
    // console.warn('checkShowDisplay', checkShowDisplay());
    if (profileData && !loadFilter && !loading && activeSubmenu !== '' && rolePermission.length > 0)
      switch (activeSubmenu) {
        case DELIVERY_STATUS_SUBMENU.BY_REGION:
          if (!checkRootDsmPage[0]) {
            return setActiveSubmenu(DELIVERY_STATUS_SUBMENU.BY_AREA);
          }
          // console.warn('switchswitchswitchBY_REGION');
          getByRegionData();
          handleResetScroll();
          setHeaderTitle('OVERALL DELIVERY STATUS');
          getLastUpdated();
          break;

        case DELIVERY_STATUS_SUBMENU.BY_AREA:
          if (!checkRootDsmPage[1]) return setActiveSubmenu(DELIVERY_STATUS_SUBMENU.BY_DEALER);
          // console.warn('switchswitchswitchBY_AREA');
          getByAreaData();
          handleResetScroll();
          setHeaderTitle('DELIVERY STATUS BY AREA');
          getLastUpdated();
          break;

        case DELIVERY_STATUS_SUBMENU.BY_DEALER:
          // console.warn('switchswitchswitchBY_DEALER');
          getByDealerData();
          handleResetScroll();
          setHeaderTitle('DELIVERY STATUS BY DEALER');
          getLastUpdated();
          break;
      }
  }, [profileData, activeSubmenu, params, loadFilter, rolePermission]);

  useEffect(() => {
    // console.warn('warnwarnwarn', checkNotFound);
  }, [checkNotFound]);

  const onClickPieChartBox = (selectedData: DelStatusByRegionType) => {
    setClickRegion(selectedData.regionCode);
    setActiveSubmenu(DELIVERY_STATUS_SUBMENU.BY_AREA);
    const updatedParamsFilter = {
      ...params, // Copy the existing state
      regionCode: selectedData.regionCode, // Update the  field & value
    };
    setParams(updatedParamsFilter);
  };

  const handleApiError = (err?: any) => {
    if (err.code === 'ERR_INVALID_JWT') {
      return navigate('/error', {
        state: {
          errorMessage: err.message && err.code ? `${err.message}/${err.code}` : err.message || '',
          originalPath: '/delivery-status',
          errtokenExpire: true,
        },
        replace: true, // If you want to replace the current entry in the history stack
      });
    }
    return navigate('/error', {
      state: {
        errorMessage: err.message && err.code ? `${err.message}/${err.code}` : err.message || '',
        originalPath: '/delivery-status',
        errtokenExpire: false,
      },
      replace: true, // If you want to replace the current entry in the history stack
    });
  };

  const renderPieChartBoxByRegion = () => {
    let renderResult = [];
    for (let i = 1; i < byRegionArrData.length; i++) {
      renderResult.push(
        <div onClick={() => onClickPieChartBox(byRegionArrData[i])}>
          <Grid className={i === 1 ? 'nextChart regionChart' : 'regionChart'}>
            <DeliveryStatusPieChart
              titleLabel={byRegionArrData[i].regionName}
              dataList={byRegionArrData[i].pieChartData || []}
            />
            <Divider orientation="horizontal" flexItem style={{ margin: '10px 0' }} />
            <div className="view-detail">
              <Typography variant="subtitle2">VIEW DETAIL</Typography>
              <ArrowForwardIos style={{ height: '16px' }} />
            </div>
          </Grid>
        </div>,
      );
    }
    return renderResult;
  };

  const setIsParams = (value: any) => {
    setParams(value);
  };
  const handleResetScroll = () => {
    if (scrollableElementRef.current) {
      scrollableElementRef.current.scrollTo({
        top: 0,
        left: 0,
        behavior: 'instant', // You can use 'auto' for instant scroll
      });
    }
  };
  const checkShowDisplay = () => {
    switch (activeSubmenu) {
      case DELIVERY_STATUS_SUBMENU.BY_REGION:
        setByRegionArrData([]);
        setCorePieChartData(undefined);
        return 'DELIVERY_STATUS_SUBMENU.BY_REGION';

      case DELIVERY_STATUS_SUBMENU.BY_AREA:
        return 'DELIVERY_STATUS_SUBMENU.BY_AREA';

      case DELIVERY_STATUS_SUBMENU.BY_DEALER:
        return 'DELIVERY_STATUS_SUBMENU.BY_DEALER';
    }
  };

  const checkDataIsNotFound = () => {
    if (checkNotFound) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <ProtectedRoute>
      <Stack sx={{ padding: '15px 50px' }} spacing={2}>
        <Stack id="direction-container">
          <Submenu
            screen="deliveryStatus"
            onActiveMenu={(submenu: string) => {
              // console.warn('handleSetShowDisplay(submenu)handleSetShowDisplay(submenu', submenu);

              handleSetShowDisplay(submenu);
            }}
            currentActiveSubMenu={activeSubmenu}
          />
        </Stack>
        {activeSubmenu === DELIVERY_STATUS_SUBMENU.BY_REGION && (
          <FilterDeliveryDelivery
            activeSubmenu={activeSubmenu}
            callBackFilterParams={setIsParams}
            loadFilter={loadFilterEnd}
          />
        )}

        {activeSubmenu === DELIVERY_STATUS_SUBMENU.BY_AREA && (
          <FilterDeliveryDelivery
            activeSubmenu={activeSubmenu}
            callBackFilterParams={setIsParams}
            loadFilter={loadFilterEnd}
            regionCodeByRegion={clickRegion}
          />
        )}
        {activeSubmenu === DELIVERY_STATUS_SUBMENU.BY_DEALER && (
          <FilterDeliveryDelivery
            activeSubmenu={activeSubmenu}
            callBackFilterParams={setIsParams}
            loadFilter={loadFilterEnd}
          />
        )}

        {!checkDataIsNotFound() && (
          <Stack className="boxMain">
            <Typography variant="h6" className="titleChart">
              {headerTitle}
            </Typography>
            <div className="lastUpdate">Last Updated : {lastUpdatedData}</div>
            <Stack
              className="containerChart"
              flexDirection={'column'}
              sx={activeSubmenu !== DELIVERY_STATUS_SUBMENU.BY_REGION ? { minHeight: 700 } : { minHeight: 410 }}
              style={{ overflow: activeSubmenu !== DELIVERY_STATUS_SUBMENU.BY_REGION ? 'hidden' : 'scroll' }}
              ref={scrollableElementRef}
            >
              <Grid container gap={4} style={{ padding: '0 0px', width: '2014px', height: '100%' }}>
                {/* CorePieChart (By Region, By Area) */}
                {activeSubmenu === DELIVERY_STATUS_SUBMENU.BY_REGION && (
                  <Grid className="nationPieChart" style={{ height: '450px' }}>
                    <DeliveryStatusPieChart
                      titleLabel={corePieChartData?.regionName || ''}
                      dataList={corePieChartData?.pieChartData || []}
                    />
                  </Grid>
                )}
                {activeSubmenu === DELIVERY_STATUS_SUBMENU.BY_AREA && (
                  <Grid className="nationPieChart" style={{ height: '100%' }}>
                    <DeliveryStatusPieChart
                      titleLabel={changeValuesToLabel(
                        params.regionCode,
                        formatArrObjToDropdown(filterRegion, 'regionName', 'regionCode'),
                      )}
                      dataList={areaPieChart || []}
                    />
                  </Grid>
                )}

                {/* DeliveryStatus By Region */}
                {activeSubmenu === DELIVERY_STATUS_SUBMENU.BY_REGION && renderPieChartBoxByRegion()}

                {/* DeliveryStatus By Area */}
                {activeSubmenu === DELIVERY_STATUS_SUBMENU.BY_AREA && (
                  <Stack className="containerByAreaBarChart" flexDirection={'column'} style={{ zIndex: 10 }}>
                    <Grid item xs={11}>
                      {barAreaArrData.length > 0 && (
                        <StackedBarChart
                          data={barAreaArrData}
                          dataKeys={barStackDataKeys}
                          textAngel="Quantity"
                          heightChart={400}
                          colors={barColorsDeliveryStatus}
                        />
                      )}
                    </Grid>
                    <Stack className="boxTable">
                      <TableStack
                        verticalColumnsData={columnDeliveryStatus}
                        horizontalRowData={byAreaArrDataTable}
                        hKeyHeader={'name'}
                        showTotal={true}
                        widthData={500}
                        tableMaxWidth={1220}
                      />
                    </Stack>
                  </Stack>
                )}

                {/* DeliveryStatus By Dealer */}
                {activeSubmenu === DELIVERY_STATUS_SUBMENU.BY_DEALER && (
                  <Stack sx={{ marginLeft: '20px' }}>
                    <StackedBarChart
                      data={barDealerArrData}
                      dataKeys={barStackDataKeys}
                      textAngel="Quantity"
                      colors={barColorsDeliveryStatus}
                      widthChart={1018}
                      heightChart={400}
                    />
                    <Stack className="boxTable">
                      <TableStack
                        verticalColumnsData={columnDeliveryStatus}
                        horizontalRowData={byDealerArrDataTable}
                        hKeyHeader={'name'}
                        showTotal={true}
                        tableMaxWidth={1220}
                        minWidthHRow={785}
                        widthData={600}
                      />
                    </Stack>
                  </Stack>
                )}
              </Grid>
            </Stack>
          </Stack>
        )}
      </Stack>
      <BackdropWithLoading show={loading || loadFilter} />
    </ProtectedRoute>
  );
};

import React, { CSSProperties } from 'react';
import { Grid, Stack, Typography } from '@mui/material';
import VerticalColumns from '../tableVertical.tsx/VerticalColumns';
import { HorizontalRow } from '../tableVertical.tsx/HorizontalRow';

interface TableStackProps {
  verticalColumnsData: string[] | React.ReactNode[];
  vWidth?: string | number;
  height?: string | number;
  vStyleColumn?: CSSProperties;
  horizontalRowData: Record<string, string | number>[];
  hWidth?: string | number;
  hStyleRow?: CSSProperties;
  hKeyHeader: string;
  showTotal: boolean;
  tableMaxWidth?: number;
  minWidthHRow?: number;
  widthData?: number;
}

export const TableStack: React.FC<TableStackProps> = ({
  verticalColumnsData,
  vWidth = 220,
  height = 32,
  vStyleColumn,
  horizontalRowData,
  hWidth = 170,
  hStyleRow,
  hKeyHeader,
  showTotal,
  tableMaxWidth,
  minWidthHRow,
  widthData,
}) => {
  // const horizontalWidth = tableMaxWidth ? tableMaxWidth - 15 - (typeof vWidth === 'number' ? vWidth : 220) : '80%';

  return (
    <Stack spacing={1}>
      <Grid item sx={{ display: 'flex', flexDirection: 'row', minWidth: tableMaxWidth }}>
        <Stack className="stickyFirstCol">
          <VerticalColumns columns={verticalColumnsData} width={vWidth} height={height} styleColumn={vStyleColumn} />
        </Stack>

        <Stack sx={{ width: widthData, minWidth: minWidthHRow, overflow: 'hidden', overflowX: 'scroll' }}>
          <HorizontalRow
            data={horizontalRowData}
            keyHeader={hKeyHeader}
            width={hWidth}
            height={height}
            showTotal={showTotal}
            styleRow={hStyleRow}
          />
        </Stack>
      </Grid>
    </Stack>
  );
};

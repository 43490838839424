import React, { useEffect, useState } from 'react';
import { Stack } from '@mui/material';
import { TableCustom } from '../../../components/Table/TableCustom';
import './YesDetailScreen.scss';
import { ProtectedRoute } from '../../protectedRoute';
import { ExpectedDeatails, mockColumnDetails } from '../YesSummaryScreen/mock';
import { useNavigate } from 'react-router-dom';
import { ProfileDataType, useAuthStore } from '../../../state/authen-store';
import { FilteYesDealer } from '../filter';
import { ExpectedDeliveryService } from '../../../services/expectedDeliveryService';
import BackdropWithLoading from '../../../components/loading';
import { filterPayload } from '../../../services/type';
import { LastUpdatedService } from '../../../services/lastUpdatedService';
import { formatDate } from '../../../helper/utils-helper';

export const YesDetailScreen = () => {
  const [expectedArrData, setExpectedArrData] = useState<ExpectedDeatails[]>([]);
  const [pageIndex, setPageIndex] = useState<number>(1);
  const [totalPage, setTotalPage] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(false);
  const { profileData } = useAuthStore();
  const { dealerName, ownerCode } = profileData || ({} as ProfileDataType);
  const [loadFilter, setLoadFilter] = useState<boolean>(true);
  const navigate = useNavigate();
  const [params, setParams] = useState<filterPayload>({});
  const [checkNotFound, setCheckNotFound] = useState<boolean>(false);
  const [lastUpdatedData, setLastUpdatedData] = useState<string>('');

  const remakeData = (data: ExpectedDeatails[], ownerCode: string, dealerName: string) => {
    return data.map((item) => ({
      ownerCode,
      dealerName,
      ...item,
    }));
  };

  useEffect(() => {
    if (profileData && !loadFilter && !loading) {
      getExpectedDetails();
      getLastUpdated();
    }
  }, [profileData, params, loadFilter]);

  const setIsParams = (value: any) => {
    setParams(value);
  };

  const loadFilterEnd = (loading: boolean) => {
    // console.warn('loadingloadingloading', loading);
    setLoadFilter(loading);
  };

  const handlePagination = (event: any, value: any) => {
    const updatedParamsFilter = {
      ...params,
      pageIndex: value,
    };
    // console.warn('filter Changelast----->>>>>', updatedParamsFilter);
    setParams(updatedParamsFilter);
    setPageIndex(value);
  };

  const getExpectedDetails = () => {
    setLoading(true);
    ExpectedDeliveryService.getExpecteDealerdetail(params)
      .then((resp) => {
        setLoading(false);
        const data = resp?.data?.data;
        if (data) {
          setLoading(false);
          setTotalPage(data[0].totalPages);
          setPageIndex(data[0].page);
          setExpectedArrData(remakeData(data[0].contents, ownerCode || '', dealerName || ''));
          setCheckNotFound(false);
        } else {
          setLoading(false);
          setCheckNotFound(true);
          // handleApiError(resp.data);
        }
      })
      .catch((error) => {
        setLoading(false);
        setLoading(false);
        handleApiError(error);
        console.error('Error fetching getByRegionData:', error);
      });
  };

  const getLastUpdated = () => {
    // setLoading(true);
    LastUpdatedService.getDealerExpectedDeliveryLastUpdated(params)
      .then((resp) => {
        // setLoading(false);
        const data = resp?.data?.data?.lastUpdated;
        if (data) {
          const formatedDate = formatDate(data);
          setLastUpdatedData(formatedDate);
        } else {
          setLastUpdatedData('');
        }
      })
      .catch((error) => {
        // setLoading(false);
        setLastUpdatedData('');
        handleApiError(error);
      });
  };

  const handleApiError = (err: any) => {
    if (err.code === 'ERR_INVALID_JWT') {
      return navigate('/error', {
        state: {
          errorMessage: err.message && err.code ? `${err.message}/${err.code}` : err.message || '',
          originalPath: '/yes-details',
          errtokenExpire: true,
        },
        replace: true,
      });
    }
    return navigate('/error', {
      state: {
        errorMessage: err.message && err.code ? `${err.message}/${err.code}` : err.message || '',
        originalPath: '/yes-details',
        errtokenExpire: false,
      },
      replace: true,
    });
  };

  const checkDataIsNotFound = () => {
    if (checkNotFound) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <ProtectedRoute>
      <Stack sx={{ padding: '36px 32px' }}>
        {profileData !== null && (
          <FilteYesDealer
            activeSubmenu={''}
            callBackFilterParams={setIsParams}
            loadFilter={loadFilterEnd}
            pageIndex={pageIndex}
          />
        )}
        {!checkDataIsNotFound() && (
          <TableCustom
            columns={mockColumnDetails}
            data={expectedArrData}
            widthTable={1114}
            pagination
            heightTable={440}
            lastUpdateText={'Last Updated : ' + lastUpdatedData}
            page={pageIndex}
            totalPage={totalPage}
            onChangePage={handlePagination}
          />
        )}
      </Stack>
      <BackdropWithLoading show={loading || loadFilter} />
    </ProtectedRoute>
  );
};

import moment from 'moment';
import { PROFILE_ROLE } from '../Screen/LateDeliveryScreen/mock';

export const randomText = (length: number) => {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = ' ';
  const charactersLength = length;
  for (let i = 0; i < charactersLength; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const randomNumber = (min: number, max: number) => {
  return Math.random() * (max - min) + min;
};

export const formatArrToDropDown = (data: any[]) => {
  if (!Array.isArray(data)) {
    return []; // Return an empty array or handle the error appropriately
  }
  return data.map((item) => ({ label: item, value: item }));
};

export const formatArrObjToDropdown = (data: any[], labelKey: string, valueKey: string) => {
  if (!Array.isArray(data)) {
    return []; // Return an empty array or handle the error appropriately
  }
  return data.map((item) => {
    return { label: item[labelKey], value: item[valueKey] };
  });
};
export const changeValuesToLabel = (selected: any[] | any, list: { label: any; value: any }[]) => {
  // Ensure selected is an array
  const selectedArray = Array.isArray(selected) ? selected : [selected];

  const labels = selectedArray.map((select) => {
    const valueObj = list.find((el) => el.value === select);
    return valueObj ? valueObj.label : '';
  });

  const summary = labels.join(', '); // Join the selected labels with a comma

  const maxLength = 10; // Maximum length for displayed text
  if (summary.length > maxLength) {
    // Cut off the text and add '...' if it exceeds the maximum length
    return summary.substring(0, maxLength) + '...';
  }
  return summary;
};

export const checkRoleManageMent = (role: string) => {
  return role === PROFILE_ROLE.DM || role === PROFILE_ROLE.SP;
};
export const numberWithCommas = (x: number) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const formatDataForBarStack = (arrData: any[], keyName: string) => {
  return arrData.map((item: any) => ({
    name: item[keyName],
    data: {
      mcDelivered: item['mcDelivered'],
      mcInTransit: item['mcInTransit'],
      mcWaitForShipping: item['mcWaitForShipping'],
      mcInProduction: item['mcInProduction'],
      mcRejected: item['mcRejected'],
    },
  }));
};

export const formatDate = (dateString: string) => {
  const parsedDate = moment(dateString, 'YYYY-MM-DD');
  const formattedDate = parsedDate.format('D MMMM YYYY');
  return formattedDate;
};

import React, { useMemo } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, LabelList } from 'recharts';
import CustomXAxisTick from './CustomXAxisTick';
import './StackedBarChart.scss';
import { StackedBarChartProps } from './type';

const StackedBarChart: React.FC<StackedBarChartProps> = ({
  data,
  dataKeys,
  textAngel,
  styleBars,
  heightChart = '600px',
  widthChart,
  unitYaxis,
  unitInBar,
  colors,
  notShowLegend,
  ScreenDealer,
  ScreenLate,
}) => {
  const numberOfDataPoints = data.length;
  const minWidth = 100;
  const maxWidth = 10000;

  const findMaxValue = () => {
    return data.reduce((max, item) => {
      return Math.max(max, ...Object.values(item.data));
    }, 0);
  };

  const maxValue = useMemo(() => findMaxValue(), [data]);

  const dynamicChartWidth = Math.min(Math.max(minWidth, numberOfDataPoints * 40), maxWidth);
  const legendMappingsDs: Record<string, string> = {
    'data.mcDelivered': 'Delivered',
    'data.mcInTransit': 'In Transit',
    'data.mcWaitForShipping': 'Wait for Shipping',
    'data.mcInProduction': 'In Production',
    'data.mcRejected': 'Rejected',
  };

  const legendMappingsDealer: Record<string, string> = {
    'data.mcDelivered': 'Delivered',
    'data.mcInTransit': 'In Transit',
    'data.mcInProduction': 'In Production',
    'data.mcRejected': 'Rejected',
  };

  const legendMappingsLd: Record<string, string> = {
    'data.delayedDeparturePercentage': 'Delayed Departure',
    'data.delayTransitTimePercentage': 'Delayed Transit Time',
  };

  const checkLegendDisplay = (dataKey: any) => {
    if (ScreenDealer) {
      return legendMappingsDealer[dataKey];
    } else if (ScreenLate) {
      return legendMappingsLd[dataKey];
    } else {
      return legendMappingsDs[dataKey];
    }
  };

  return (
    // <div className={'containerStackBar'} style={{ width: widthChart, height: heightChart }}>
    <div className={'containerStackBar'} style={{ width: widthChart, height: heightChart }}>
      <ResponsiveContainer width={dynamicChartWidth * 4} height={heightChart}>
        <BarChart data={data} margin={{ top: 60, bottom: 20, left: 60 }} style={styleBars}>
          <CartesianGrid strokeDasharray="3 3" />
          {!notShowLegend ? (
            <Legend
              formatter={(value, entry) =>
                ScreenLate ? legendMappingsLd[value] || value : legendMappingsDs[value] || value
              }
            />
          ) : null}
          <XAxis
            dataKey="name"
            className="nameBusiness"
            tickMargin={6}
            tickFormatter={(value: any) => (value.length > 10 ? `${value.substring(0, 10)}...` : value)}
            tick={(props: any) => <CustomXAxisTick {...props} />}
          />
          <YAxis
            tickMargin={0}
            tickCount={10}
            tickSize={20}
            axisLine={true}
            tick={{ dx: -25 }}
            className="labelAngle"
            label={{
              value: textAngel ? `${textAngel}` : null,
              style: { textAnchor: 'middle' },
              angle: -90,
              position: 'left',
              offset: 40,
            }}
            unit={unitYaxis}
          />
          <Tooltip
            content={({ payload, label, active }) => {
              if (active && payload) {
                return (
                  <div
                    className="custom-tooltip"
                    style={{
                      backgroundColor: 'white',
                      borderRadius: '6px',
                      fontSize: '12px',
                      padding: '12px',
                      boxShadow:
                        '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
                    }}
                  >
                    <p className="tooltip-label" style={{ fontSize: '16px', fontWeight: 'bold' }}>
                      {label}
                    </p>
                    {payload.map((entry, index) => {
                      const dataKey = entry.dataKey;
                      if (dataKey !== undefined) {
                        const legendLabel = checkLegendDisplay(dataKey);
                        if (legendLabel !== undefined) {
                          return (
                            <p key={index} style={{ color: entry.color }}>
                              <b>{legendLabel}: </b>
                              {unitInBar ? `${entry.value}${unitInBar}` : `${entry.value}`}
                            </p>
                          );
                        }
                      }
                      return (
                        <p key={index} style={{ color: entry.color }}>
                          {entry.name}:{entry.value}
                        </p>
                      );
                    })}
                  </div>
                );
              }
            }}
          />

          {dataKeys.map((key, index) => (
            <Bar
              dataKey={`data.${key}`}
              stackId="stack"
              fill={colors[index % colors.length]}
              barSize={70}
              key={`data.${key}`}
              legendType="circle"
              isAnimationActive={false}
            >
              {index === dataKeys.length - 1 && (
                <LabelList
                  valueAccessor={(props: any) => {
                    const totalValue = dataKeys.reduce((total, k) => total + props.data[k], 0);
                    const unitOfvalue = unitInBar || '';
                    const textResult = `${totalValue}${unitOfvalue}`;
                    return textResult;
                  }}
                  position={'top'}
                  fill="black"
                  width={150}
                  style={{ pointerEvents: 'none' }}
                />
              )}
              <LabelList
                fill="white"
                valueAccessor={(props: any) => {
                  const isValue = props.data[key];
                  const unitOfvalue = unitInBar || '';
                  return isValue > maxValue * 0.07 ? isValue + unitOfvalue : '';
                }}
                style={{ pointerEvents: 'none' }}
              />
            </Bar>
          ))}
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default StackedBarChart;

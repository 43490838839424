import React, { useEffect } from 'react';
import './groupedLabel.scss';
import DrawerSidebarMultiple from '../drawerSidebarMultiple/drawerSidebarMultiple';
import { KeyboardArrowDown } from '@mui/icons-material';
import { changeValuesToLabel } from '../../helper/utils-helper';

interface DataItem {
  label: string;
  value: string;
}

type AllowedTitles =
  | 'Region'
  | 'Sub-Region'
  | 'Area'
  | 'Province'
  | 'Owner Code'
  | 'Dealer'
  | 'Model Name'
  | 'Model Code'
  | 'Product Code'
  | 'Color'
  | 'Month Year';

interface GroupedLabelProps {
  titleLabel: any;
  dataList: DataItem[];
  disabled?: boolean;
  isSelectFilter: (key: string, value: any) => void;
  selectedTemp?: any;
  textLabelTemp?: any;
}

export const GroupedLabelMultiple: React.FC<GroupedLabelProps> = ({
  titleLabel,
  dataList,
  disabled = false,
  isSelectFilter,
  selectedTemp,
  textLabelTemp,
}: GroupedLabelProps) => {
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const defaultWord = 'All';
  const [textLabel, setTextLabel] = React.useState(textLabelTemp);
  const handleOpenDrawer = () => {
    setOpenDrawer(true);
  };
  const handleCloseDrawer = () => {
    setOpenDrawer(false);
  };
  // useEffect(() => {
  //   if (selectedValue !== textLabel) {
  //     setTextLabel(selectedTemp);
  //     handleSelectionClick([selectedValue], titleLabel);
  //   }
  // }, []);

  useEffect(() => {
    // Set the initial value of textLabel from textLabelTemp when the component is mounted
    if (textLabelTemp && selectedTemp) {
      setTextLabel(textLabelTemp);
      // console.warn('selectedTemp', selectedTemp);
      // console.warn('titleLabel', titleLabel);
      handleSelectionClick(selectedTemp, titleLabel);
    } else if (textLabelTemp === 'N/A') {
      setTextLabel('N/A');
    } else {
      setTextLabel(textLabelTemp);
    }
  }, [selectedTemp, textLabelTemp]);

  const handleSelectionClick = (value: any, titleLabel: AllowedTitles) => {
    const multivalue = changeValuesToLabel(value, dataList);
    setTextLabel(multivalue);

    const actionMap: Record<AllowedTitles, () => void> = {
      Region: () => isSelectFilter('regionCode', value),
      'Sub-Region': () => {
        isSelectFilter('subregionCodes', value);
      },
      Area: () => isSelectFilter('areaCodes', value),
      Province: () => isSelectFilter('provinceCodes', value),
      'Owner Code': () => isSelectFilter('ownerCodes', value),
      Dealer: () => isSelectFilter('dealerCodes', value),
      'Model Name': () => isSelectFilter('modelNames', value),
      'Model Code': () => isSelectFilter('modelCodes', value),
      'Product Code': () => isSelectFilter('productCodes', value),
      Color: () => isSelectFilter('colorCodes', value),
      'Month Year': () => isSelectFilter('monthYear', value),
    };

    const action = actionMap[titleLabel];
    if (action) {
      action();
    }
  };

  return (
    <>
      <fieldset
        id={disabled ? 'dropdown-disabled' : 'dropdown'}
        onClick={() => {
          // handleOpenDrawer();
          if (!disabled) {
            handleOpenDrawer();
          }
        }}
      >
        <>
          <legend id={disabled ? 'dropdown-label-disabled' : 'dropdown-label'}>{titleLabel}</legend>
          <>{textLabel || 'All'}</>
        </>
        <KeyboardArrowDown id="dropdown-icon" style={{ color: disabled ? '#c7c7c7' : '#fa230d' }} />
      </fieldset>
      <DrawerSidebarMultiple
        titleLabel={titleLabel}
        dataList={textLabel === 'N/A' ? [] : dataList}
        isDrawerOpen={textLabel === 'N/A' ? false : openDrawer}
        handleCloseDrawer={handleCloseDrawer}
        isBackdropOpen={openDrawer}
        handleSelectionClick={(value) => handleSelectionClick(value, titleLabel)}
      />
    </>
  );
};

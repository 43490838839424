import React, { useEffect, useState } from 'react';
import './LateDeliveryScreen.scss';
import { FilterLateDelivery } from './filterDelivery';
import { FilterProductTion } from './filterProduction';
import {
  LateTrendData,
  LateTrendTableData,
  LateTrendChartData,
  LateModelCodeData,
  LateProductCodeData,
  LateDeliveryByChartData,
  LateTransitTimeData,
  LineTypeData,
  LateTrendProdData,
  LateModelCodeProdData,
  LateProductCodeProdData,
  LateProductionByChartData,
  LateProductionTimeData,
} from './service';
import { columnsVertical, monthList } from './mock';
import { Stack, Typography, Grid } from '@mui/material';
import StackedBarChart from '../../components/Chart/StackedBarChart/StackedBarChart';
import { CustomLineChart, DataPoint } from '../../components/Chart/LineChart/CustomLineChart';
import VerticalColumns from '../../components/Table/tableVertical.tsx/VerticalColumns';
import { HorizontalRow } from '../../components/Table/tableVertical.tsx/HorizontalRow';
import { DoubleArrow } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { Submenu } from '../../components/subMenu';
import BackdropWithLoading from '../../components/loading';
import { LateDeliveryService } from '../../services/lateDeliveryService';
import { LateProductionService } from '../../services/lateProductService';
import { ProtectedRoute } from '../protectedRoute';
import { useAuthStore } from '../../state/authen-store';
import { filterPayload } from '../../services/type';
import { LastUpdatedService } from '../../services/lastUpdatedService';
import { formatDate } from '../../helper/utils-helper';
// import { Navbar } from './components/component/Header/Navbar';

export const LateDeliveryScreen = () => {
  const [activeSubmenu, setActiveSubmenu] = React.useState('DELIVERY');
  const [checkNotFound, setCheckNotFound] = useState<boolean>(false);
  const { profileData } = useAuthStore();
  const [params, setParams] = useState<filterPayload>({});
  const [loadFilter, setLoadFilter] = useState<boolean>(true);
  const [showDisplay, setShowDisplay] = React.useState('DELIVERY_TREND');
  const [lateTrendProdData, setLateTrendProdData] = useState<LateTrendProdData[]>([]);
  const [lateTrendProdChart, setLateTrendProdChart] = useState<DataPoint[]>([]);

  const handleSetShowDisplay = (value: any) => {
    if (value === 'DELIVERY') {
      setShowDisplay('DELIVERY_TREND');
      setActiveSubmenu(value);
    } else if (value === 'PRODUCTION') {
      setShowDisplay('PRODUCTION_TREND');
      setActiveSubmenu(value);
    } else {
      setShowDisplay(value);
    }
  };

  useEffect(() => {
    console.warn('activeSubmenu', activeSubmenu);
  }, [activeSubmenu]);

  useEffect(() => {
    if (profileData && !loadFilter && !loading) {
      setParams({});
      switch (showDisplay) {
        case 'DELIVERY_TREND':
          getLateTrend();
          getLastUpdated();
          break;

        case 'DELIVERY_BY':
          getLateModelCode();
          getLateProductCode();
          getLastUpdated();
          break;

        case 'DELIVERY_TIME':
          getLateTransitTime();
          getLastUpdated();
          break;

        case 'PRODUCTION_TREND':
          getLateTrendProd();
          getLastUpdatedProd();
          break;

        case 'PRODUCTION_BY':
          getLateModelProdCode();
          getLateProductProdCode();
          getLastUpdatedProd();
          break;

        case 'PRODUCTION_TIME':
          getLateProductionTime();
          getLastUpdatedProd();
          break;
      }
    }
  }, [profileData, params, loadFilter, showDisplay]);

  const barStackDataKeys = ['delayedDeparturePercentage', 'delayTransitTimePercentage'];
  const redColor = ['#FA230D'];
  const yellowColor = ['#FEBF00'];
  const navyColor = ['#072978'];
  const grayColor = ['#C7C7C7'];
  const twoColors = ['#FEBF00', '#C7C7C7'];

  const setIsParams = (value: any) => {
    setParams(value);
  };

  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const handleApiError = (err?: any) => {
    if (err.code === 'ERR_INVALID_JWT') {
      return navigate('/error', {
        state: {
          errorMessage: err.message && err.code ? `${err.message}/${err.code}` : err.message || '',
          originalPath: '/late-delivery',
          errtokenExpire: true,
        },
        replace: true, // If you want to replace the current entry in the history stack
      });
    }
    return navigate('/error', {
      state: {
        errorMessage: err.message && err.code ? `${err.message}/${err.code}` : err.message || '',
        originalPath: '/late-delivery',
        errtokenExpire: false,
      },
      replace: true, // If you want to replace the current entry in the history stack
    });
  };

  const checkDataIsNotFound = () => {
    if (checkNotFound) {
      return true;
    } else {
      return false;
    }
  };

  const [lateTrendData, setLateTrendData] = useState<LateTrendData[]>([]);
  const [sortedTableData, setSortedTableData] = useState<LateTrendTableData[]>([]);
  const [sortedChartData, setSortedChartData] = useState<LateTrendChartData[]>([]);
  const getLateTrend = () => {
    // console.warn('getLateTrendgetLateTrendgetLateTrendgetLateTrend');
    setLoading(true);
    LateDeliveryService.getLateTrend(params)
      .then((resp) => {
        setLoading(false);
        const data = resp.data.data;
        if (data) {
          setLateTrendData(data);
          // console.warn(data);
          formatDataForTable(data);
          formatDataForChart(data);
          setCheckNotFound(false);
        } else {
          setCheckNotFound(true);
          // handleApiError(resp.data);
        }
        setLateTrendData(data);
      })
      .catch((error) => {
        setLoading(false);
        handleApiError(error.message);
        console.error('Error fetching getLateTrend:', error);
      });
  };

  const formatDataForTable = (arrData: any[]) => {
    const sortedData = arrData.map(
      ({ month, deliveryLateQty, delayTransitTimeQty, delayedDepartureQty, totalOrder }) => {
        const monthObj = monthList.find((item) => item.id === parseInt(month, 10));

        return {
          month: monthObj ? monthObj.monthName : '',
          deliveryLateQty,
          delayTransitTimeQty,
          delayedDepartureQty,
          totalOrder,
        };
      },
    );
    setSortedTableData(sortedData);
  };

  const formatDataForChart = (arrData: any[]) => {
    const sortedData = arrData.map((item: any) => {
      const monthObj = monthList.find((month) => month.id === parseInt(item['month'], 10));
      return {
        name: monthObj ? monthObj.monthName : '',
        data: {
          delayTransitTimePercentage: item['delayTransitTimePercentage'],
          delayedDeparturePercentage: item['delayedDeparturePercentage'],
        },
      };
    });
    setSortedChartData(sortedData);
  };

  const [lateModelCodeData, setLateModelCodeData] = useState<LateModelCodeData[]>([]);
  const [lateModelCodeChart, setLateModelCodeChart] = useState<LateDeliveryByChartData[]>([]);
  const getLateModelCode = () => {
    setLoading(true);
    LateDeliveryService.getLateDepartureModel(params)
      .then((resp) => {
        setLoading(false);
        const data = resp.data.data;
        if (data) {
          setLateModelCodeData(data);
          // console.warn(data);
          const sortedData = lateModelCodeData.map((item) => ({
            name: item.modelName,
            data: {
              deliveryLateQty: item.deliveryLateQty,
            },
          }));
          setLateModelCodeChart(sortedData);
          setCheckNotFound(false);
        } else {
          setCheckNotFound(true);
          // handleApiError(resp.data);
        }
      })
      .catch((error) => {
        setLoading(false);
        handleApiError(error.message);
        console.error('Error fetching getLateModelCode:', error);
      });
  };

  const [lateProductCodeData, setLateProductCodeData] = useState<LateProductCodeData[]>([]);
  const [lateProductCodeChart, setLateProductCodeChart] = useState<LateDeliveryByChartData[]>([]);
  const getLateProductCode = () => {
    setLoading(true);
    LateDeliveryService.getLateDepartureProduct(params)
      .then((resp) => {
        setLoading(false);
        const data = resp.data.data;
        if (data) {
          setLateProductCodeData(data);
          const sortedData = lateProductCodeData.map((item) => ({
            name: item.productCode,
            data: {
              deliveryLateQty: item.deliveryLateQty,
            },
          }));
          setLateProductCodeChart(sortedData);
          setCheckNotFound(false);
        } else {
          setCheckNotFound(true);
          // handleApiError(resp.data);
        }
      })
      .catch((error) => {
        setLoading(false);
        handleApiError(error.message);
        console.error('Error fetching getLateProductCode:', error);
      });
  };

  const [lateTransitTimeChart, setLateTransitTimeChart] = useState<DataPoint[]>([]);
  const getLateTransitTime = () => {
    setLoading(true);
    LateDeliveryService.getAvgDelayedTransit(params)
      .then((resp) => {
        setLoading(false);
        const data = resp.data.data;
        if (data) {
          const sortedData: LineTypeData = {};
          data.forEach((item: any) => {
            const month = monthList.find((monthItem) => monthItem.id === parseInt(item.month));
            const monthYear = `${month?.monthName} ${item.year}`;
            sortedData[monthYear] = item.avgDelayedTransitTime;
          });

          const result: DataPoint[] = convertLineTypeDataToDataPointArray(sortedData);
          setLateTransitTimeChart(result);
          setCheckNotFound(false);
        } else {
          setCheckNotFound(true);
          // handleApiError(resp.data);
        }
      })
      .catch((error) => {
        setLoading(false);
        handleApiError(error.message);
        console.error('Error fetching getLateTransitTime:', error);
      });
  };

  const getLateTrendProd = () => {
    setLoading(true);
    LateProductionService.getLateProductionTrend(params)
      .then((resp) => {
        setLoading(false);
        const data = resp.data.data;
        if (data) {
          setLateTrendProdData(data);
          const sortedData: LineTypeData = {};
          data.forEach((item: any) => {
            const month = monthList.find((monthItem) => monthItem.id === parseInt(item.month));
            const monthYear = `${month?.monthName} ${item.year}`;
            sortedData[monthYear] = item.lateProductionQty;
          });
          const result: DataPoint[] = convertLineTypeDataToDataPointArray(sortedData);
          setLateProductionTimeChart(result);
          setCheckNotFound(false);
        } else {
          setCheckNotFound(true);
          // handleApiError(resp.data);
        }
      })
      .catch((error) => {
        setLoading(false);
        handleApiError(error.message);
        console.error('Error fetching getLateTrendProd:', error);
      });
  };

  const [lateModelCodeProdData, setLateModelCodeProdData] = useState<LateModelCodeProdData[]>([]);
  const [lateModelCodeProdChart, setLateModelCodeProdChart] = useState<LateProductionByChartData[]>([]);
  const getLateModelProdCode = () => {
    setLoading(true);
    LateProductionService.getLateProductionModel(params)
      .then((resp) => {
        setLoading(false);
        const data = resp.data.data;
        if (data) {
          setLateModelCodeProdData(data);
          // console.warn(data);
          const sortedData = lateModelCodeProdData.map((item) => ({
            name: item.modelName,
            data: {
              lateProductionQty: item.lateProductionQty,
            },
          }));
          setLateModelCodeProdChart(sortedData);
          setCheckNotFound(false);
        } else {
          setCheckNotFound(true);
          // handleApiError(resp.data);
        }
      })
      .catch((error) => {
        setLoading(false);
        handleApiError(error.message);
        console.error('Error fetching getLateModelProdCode:', error);
      });
  };

  const [lateProductCodeProdData, setLateProductCodeProdData] = useState<LateProductCodeProdData[]>([]);
  const [lateProductCodeProdChart, setLateProductCodeProdChart] = useState<LateProductionByChartData[]>([]);
  const getLateProductProdCode = () => {
    setLoading(true);
    LateProductionService.getLateProductionProduct(params)
      .then((resp) => {
        setLoading(false);
        const data = resp.data.data;
        if (data) {
          setLateProductCodeProdData(data);
          const sortedData = lateProductCodeProdData.map((item) => ({
            name: item.productCode,
            data: {
              lateProductionQty: item.lateProductionQty,
            },
          }));
          setLateProductCodeProdChart(sortedData);
          setCheckNotFound(false);
        } else {
          setCheckNotFound(true);
          // handleApiError(resp.data);
        }
      })
      .catch((error) => {
        setLoading(false);
        handleApiError(error.message);
        console.error('Error fetching getLateProductProdCode:', error);
      });
  };

  const loadFilterEnd = (loading: boolean) => {
    // console.warn('loadingloadingloading', loading);
    setLoadFilter(loading);
  };

  const convertLineTypeDataToDataPointArray = (lineTypeData: LineTypeData): DataPoint[] => {
    return Object.keys(lineTypeData).map((name) => ({
      name,
      value: lineTypeData[name],
    }));
  };

  const [lateProductionTimeData, setLateProductionTimeData] = useState<LateProductionTimeData[]>([]);
  const [lateProductionTimeChart, setLateProductionTimeChart] = useState<DataPoint[]>([]);

  const getLateProductionTime = () => {
    setLoading(true);
    LateProductionService.getAvgDelayedProduction(params)
      .then((resp) => {
        setLoading(false);
        const data = resp.data.data;
        if (data) {
          setLateProductionTimeData(data);

          const sortedData: LineTypeData = {};
          data.forEach((item: any) => {
            const month = monthList.find((monthItem) => monthItem.id === parseInt(item.month));
            const monthYear = `${month?.monthName} ${item.year}`;
            sortedData[monthYear] = item.avgDelayedTransitTime;
          });

          const result: DataPoint[] = convertLineTypeDataToDataPointArray(sortedData);
          setLateTrendProdChart(result);
          setCheckNotFound(false);
        } else {
          setCheckNotFound(true);
          // handleApiError(resp.data);
        }
      })
      .catch((error) => {
        setLoading(false);
        handleApiError(error.message);
        console.error('Error fetching getLateProductionTime:', error);
      });
  };

  const [lastUpdatedData, setLastUpdatedData] = useState<string>('');
  const getLastUpdated = () => {
    // setLoading(true);
    LastUpdatedService.getLateDeliveryLastUpdated(params)
      .then((resp) => {
        // setLoading(false);
        const data = resp?.data?.data?.lastUpdated;
        if (data) {
          const formatedDate = formatDate(data);
          setLastUpdatedData(formatedDate);
        } else {
          setLastUpdatedData('');
        }
      })
      .catch((error) => {
        // setLoading(false);
        setLastUpdatedData('');
        handleApiError(error);
      });
  };

  const [lastUpdatedProdData, setLastUpdatedProdData] = useState<string>('');
  const getLastUpdatedProd = () => {
    // setLoading(true);
    LastUpdatedService.getLateProductionLastUpdated(params)
      .then((resp) => {
        // setLoading(false);
        const data = resp?.data?.data?.lastUpdated;
        if (data) {
          const formatedDate = formatDate(data);
          setLastUpdatedProdData(formatedDate);
        } else {
          setLastUpdatedProdData('');
        }
      })
      .catch((error) => {
        // setLoading(false);
        setLastUpdatedProdData('');
        handleApiError(error);
      });
  };

  return (
    <ProtectedRoute>
      {profileData && (
        <Stack sx={{ display: 'flex', padding: '15px 50px', flexDirection: 'column' }}>
          <Stack id="direction-container">
            <Submenu screen="lateDeliveryStatus" onActiveMenu={(submenu: string) => handleSetShowDisplay(submenu)} />
          </Stack>

          {activeSubmenu === 'DELIVERY' && (
            <FilterLateDelivery
              showDisplay={showDisplay}
              callBackFilterParams={setIsParams}
              activeSubmenu={activeSubmenu}
              loadFilter={loadFilterEnd}
            />
          )}
          {activeSubmenu === 'PRODUCTION' && (
            <FilterProductTion
              showDisplay={showDisplay}
              callBackFilterParams={setIsParams}
              activeSubmenu={activeSubmenu}
              loadFilter={loadFilterEnd}
            />
          )}

          {activeSubmenu === 'DELIVERY' && (
            <>
              {showDisplay === 'DELIVERY_TREND' && (
                <>
                  {!checkDataIsNotFound() && (
                    <>
                      <Typography id="display-label" variant="h6">
                        LATE DELIVERY TREND
                      </Typography>

                      <div id="display-container">
                        <div id="updated-text">Last Updated : {lastUpdatedData}</div>
                        <Grid container spacing={1}>
                          <Grid item xs={12}>
                            {sortedChartData.length > 0 && (
                              <StackedBarChart
                                data={sortedChartData}
                                dataKeys={barStackDataKeys}
                                widthChart={1000}
                                heightChart={280}
                                textAngel="Late Delivery Percentage"
                                colors={twoColors}
                                unitInBar="%"
                                unitYaxis="%"
                                ScreenLate
                                notShowLegend
                              />
                            )}
                          </Grid>
                        </Grid>

                        <Grid container spacing={1}>
                          <Grid item xs={1} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <div style={{ transform: 'rotate(270deg)', whiteSpace: 'nowrap' }}>Quantity</div>
                          </Grid>
                          <Grid item xs={11} style={{ display: 'flex', flexDirection: 'row', overflow: 'scroll' }}>
                            <VerticalColumns columns={columnsVertical} width={'220px'} height={'20px'} />
                            {sortedTableData.length > 0 && (
                              <HorizontalRow
                                data={sortedTableData}
                                keyHeader={'month'}
                                width="90px"
                                height={'20px'}
                                showTotal={false}
                              />
                            )}
                          </Grid>
                        </Grid>
                      </div>
                    </>
                  )}
                  {checkDataIsNotFound() && <div style={{ height: '480px' }}></div>}
                  <div
                    id="section-button"
                    onClick={() => {
                      handleSetShowDisplay('DELIVERY_BY');
                    }}
                  >
                    <DoubleArrow id="double-arrow" />
                    <Typography variant="subtitle2">LATE DEPARTURE BY MODEL</Typography>
                  </div>
                </>
              )}
              {showDisplay === 'DELIVERY_TIME' && (
                <>
                  <div
                    id="section-button-up"
                    onClick={() => {
                      handleSetShowDisplay('DELIVERY_BY');
                    }}
                  >
                    <DoubleArrow id="double-arrow-up" />
                    <Typography variant="subtitle2">LATE DEPARTURE BY MODEL</Typography>
                  </div>
                  {!checkDataIsNotFound() && (
                    <>
                      <Typography id="display-label" variant="h6">
                        AVERAGE DELAYED TRANSIT TIME
                      </Typography>
                      <div id="display-container">
                        <div id="updated-text">Last Updated : {lastUpdatedData}</div>
                        <Grid container spacing={1}>
                          <Grid item xs={1} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <div style={{ transform: 'rotate(270deg)', whiteSpace: 'nowrap' }}>
                              Average Day Delayed - Delivery
                            </div>
                          </Grid>
                          <Grid item xs={11} style={{ overflow: 'auto' }}>
                            {<CustomLineChart data={lateTransitTimeChart} lateScreen />}
                          </Grid>
                        </Grid>
                      </div>
                    </>
                  )}
                  {checkDataIsNotFound() && <div style={{ height: '480px' }}></div>}
                </>
              )}
              {showDisplay === 'DELIVERY_BY' && (
                <>
                  <div
                    id="section-button-up"
                    onClick={() => {
                      handleSetShowDisplay('DELIVERY_TREND');
                    }}
                  >
                    <DoubleArrow id="double-arrow-up" />
                    <Typography variant="subtitle2">LATE DELIVERY TREND</Typography>
                  </div>
                  {!checkDataIsNotFound() && (
                    <>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <Typography id="display-label" variant="h6">
                            LATE DEPARTURE BY MODEL
                          </Typography>
                          <div id="display-container">
                            <div id="updated-text">Last Updated : {lastUpdatedData}</div>
                            {lateModelCodeChart.length > 0 && (
                              <StackedBarChart
                                data={lateModelCodeChart}
                                dataKeys={['deliveryLateQty']}
                                widthChart={480}
                                heightChart={360}
                                textAngel="Late Departure Qty"
                                colors={yellowColor}
                                notShowLegend
                              />
                            )}
                          </div>
                        </Grid>

                        <Grid item xs={6}>
                          <Typography id="display-label" variant="h6">
                            LATE DEPARTURE BY PRODUCT CODE
                          </Typography>
                          <div id="display-container">
                            <div id="updated-text">Last Updated : {lastUpdatedData}</div>
                            {lateProductCodeChart.length > 0 && (
                              <StackedBarChart
                                data={lateProductCodeChart}
                                dataKeys={['deliveryLateQty']}
                                widthChart={480}
                                heightChart={360}
                                textAngel="Late Departure Qty"
                                colors={redColor}
                                notShowLegend
                              />
                            )}
                          </div>
                        </Grid>
                      </Grid>
                    </>
                  )}
                  {checkDataIsNotFound() && <div style={{ height: '440px' }}></div>}
                  <div
                    id="section-button"
                    onClick={() => {
                      handleSetShowDisplay('DELIVERY_TIME');
                    }}
                  >
                    <DoubleArrow id="double-arrow" />
                    <Typography variant="subtitle2">AVERAGE DELAYED TRANSIT TIME</Typography>
                  </div>
                </>
              )}
            </>
          )}

          {activeSubmenu === 'PRODUCTION' && (
            <>
              {showDisplay === 'PRODUCTION_TREND' && (
                <>
                  {!checkDataIsNotFound() && (
                    <>
                      <Typography id="display-label" variant="h6">
                        LATE PRODUCTION TREND
                      </Typography>
                      <div id="display-container">
                        <div id="updated-text">Last Updated : {lastUpdatedProdData}</div>
                        <Grid container spacing={1}>
                          <Grid item xs={1} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <div style={{ transform: 'rotate(270deg)', whiteSpace: 'nowrap' }}>
                              Late Production Percentage
                            </div>
                          </Grid>
                          <Grid item xs={11} style={{ overflow: 'auto' }}>
                            {<CustomLineChart data={lateTrendProdChart} unit="%" lateScreen />}
                          </Grid>
                        </Grid>
                      </div>
                    </>
                  )}
                  {checkDataIsNotFound() && <div style={{ height: '480px' }}></div>}
                  <div
                    id="section-button"
                    onClick={() => {
                      handleSetShowDisplay('PRODUCTION_BY');
                    }}
                  >
                    <DoubleArrow id="double-arrow" />
                    <Typography variant="subtitle2">LATE PRODUCTION BY MODEL</Typography>
                  </div>
                </>
              )}
              {showDisplay === 'PRODUCTION_TIME' && (
                <>
                  <div
                    id="section-button-up"
                    onClick={() => {
                      handleSetShowDisplay('PRODUCTION_BY');
                    }}
                  >
                    <DoubleArrow id="double-arrow-up" />
                    <Typography variant="subtitle2">LATE PRODUCTION BY MODEL</Typography>
                  </div>
                  {!checkDataIsNotFound() && (
                    <>
                      <Typography id="display-label" variant="h6">
                        AVERAGE DELAYED PRODUCTION TIME
                      </Typography>
                      <div id="display-container">
                        <div id="updated-text">Last Updated : {lastUpdatedProdData}</div>
                        <Grid container spacing={1}>
                          <Grid item xs={1} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <div style={{ transform: 'rotate(270deg)', whiteSpace: 'nowrap' }}>
                              Average Day Delayed - Production
                            </div>
                          </Grid>
                          <Grid item xs={11} style={{ overflow: 'auto' }}>
                            {lateProductionTimeChart.length > 0 && (
                              <CustomLineChart data={lateProductionTimeChart} lateScreen />
                            )}
                          </Grid>
                        </Grid>
                      </div>
                    </>
                  )}
                  {checkDataIsNotFound() && <div style={{ height: '480px' }}></div>}
                </>
              )}
              {showDisplay === 'PRODUCTION_BY' && (
                <>
                  <div
                    id="section-button-up"
                    onClick={() => {
                      handleSetShowDisplay('PRODUCTION_TREND');
                    }}
                  >
                    <DoubleArrow id="double-arrow-up" />
                    <Typography variant="subtitle2">LATE PRODUCTION TREND</Typography>
                  </div>
                  {!checkDataIsNotFound() && (
                    <>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <Typography id="display-label" variant="h6">
                            LATE PRODUCTION BY MODEL
                          </Typography>
                          <div id="display-container">
                            <div id="updated-text">Last Updated : {lastUpdatedProdData}</div>
                            {lateModelCodeProdChart.length > 0 && (
                              <StackedBarChart
                                data={lateModelCodeProdChart}
                                dataKeys={['lateProductionQty']}
                                widthChart={480}
                                heightChart={360}
                                textAngel="Late Production Qty"
                                colors={navyColor}
                                notShowLegend
                              />
                            )}
                          </div>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography id="display-label" variant="h6">
                            LATE PRODUCTION BY PRODUCT CODE
                          </Typography>
                          <div id="display-container">
                            <div id="updated-text">Last Updated : {lastUpdatedProdData}</div>
                            {lateProductCodeProdChart.length > 0 && (
                              <StackedBarChart
                                data={lateProductCodeProdChart}
                                dataKeys={['lateProductionQty']}
                                widthChart={480}
                                heightChart={360}
                                textAngel="Late Production Qty"
                                colors={grayColor}
                                notShowLegend
                              />
                            )}
                          </div>
                        </Grid>
                      </Grid>
                    </>
                  )}
                  {checkDataIsNotFound() && <div style={{ height: '440px' }}></div>}
                  <div
                    id="section-button"
                    onClick={() => {
                      handleSetShowDisplay('PRODUCTION_TIME');
                    }}
                  >
                    <DoubleArrow id="double-arrow" />
                    <Typography variant="subtitle2">AVERAGE DELAYED PRODUCTION TIME</Typography>
                  </div>
                </>
              )}
            </>
          )}
        </Stack>
      )}
      <BackdropWithLoading show={loading || loadFilter} />
    </ProtectedRoute>
  );
};

import React, { useEffect } from 'react';
import './drawerSidebar.scss';
import { Drawer, Typography, Divider, Button, IconButton, Backdrop } from '@mui/material';
import { Close } from '@mui/icons-material';

type SingleNewData = {
  label: string;
  value: string;
};

interface drawerSidebarProps {
  dataList: SingleNewData[];
  isDrawerOpen: boolean;
  isBackdropOpen: boolean;
  selectedValue?: string;
  handleCloseDrawer: () => void;
  handleSelectionClick: (value: any, label?: any) => void;
  handleApply: () => void;
  titleLabel?: string;
}

export default function DrawerSidebar({
  dataList,
  isDrawerOpen,
  selectedValue,
  handleCloseDrawer,
  handleSelectionClick,
  titleLabel,
}: drawerSidebarProps) {
  const [selectedTempValue, setSelectedTempValue] = React.useState(selectedValue);

  const handleItemClick = (value: string) => {
    setSelectedTempValue(value);
  };
  const onApply = () => {
    handleSelectionClick(selectedTempValue); // Trigger selection click immediately when an item is clicked
    // console.warn(selectedTempValue);
    handleCloseDrawer();
  };

  useEffect(() => {
    if (isDrawerOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [isDrawerOpen]);

  return (
    <Backdrop id="backdrop" open={isDrawerOpen} sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}>
      <Drawer
        anchor="right"
        open={isDrawerOpen}
        variant="persistent"
        onClose={handleCloseDrawer}
        sx={{ color: '#fff', zIndex: '1000!imporatnt' }}
      >
        <div id="close-icon">
          <IconButton onClick={handleCloseDrawer}>
            <Close />
          </IconButton>
        </div>
        <div id="sidebar-header">
          <Typography variant="h6" id="sidebar-title">
            Select {titleLabel}
          </Typography>
          <li>Select only one option</li>
          <Divider orientation="horizontal" flexItem />
          <div id="sidebar-choice-container">
            {dataList.map((el, index) => {
              return (
                <div
                  key={index}
                  id={el.value === selectedTempValue ? 'selected-select-choice' : 'select-choice'}
                  onClick={() => handleItemClick(el.value)} // Handle item click
                >
                  {el.label}
                </div>
              );
            })}
          </div>
        </div>
        <div id="sidebar-footer">
          <Button
            id="apply-button"
            variant="contained"
            onClick={() => {
              onApply();
            }}
            // disabled={!selectedTempValue}
          >
            APPLY
          </Button>
        </div>
      </Drawer>
    </Backdrop>
  );
}

import API from '../api';
import { filterPayload } from '../type';

class ModelProductServiceClass {
  getModelNames = (params: filterPayload) =>
    // API.post({uri:'/sales/products/model-names'});
    API.post({
      uri: process.env.REACT_APP_ENV === 'SIT' ? 'daba4b28-079d-4df9-a96d-09e5139d4ad6' : 'products/model-names',
      params,
    });
  getModelCodes = (params: filterPayload) =>
    // API.post({uri:'/sales/products/model-codes', params});
    API.post({
      uri: process.env.REACT_APP_ENV === 'SIT' ? 'c4bdb9f9-dff1-4a5c-9af9-b57fbabefb84' : 'products/model-codes',
      params,
    });
  // Success         https://run.mocky.io/v3/c4bdb9f9-dff1-4a5c-9af9-b57fbabefb84

  getProductCodes = (params: filterPayload) =>
    // API.post({uri:'/sales/products/product-codes', params});
    API.post({
      uri: process.env.REACT_APP_ENV === 'SIT' ? '0b434278-0b4a-4dfe-b3be-79b1419cc860' : 'products/product-codes',
      params,
    });
  getColors = (params: filterPayload) =>
    // API.post({uri:'/sales/products/product-colors', params});
    API.post({
      uri: process.env.REACT_APP_ENV === 'SIT' ? 'f6d85b11-98d6-4ffe-8ebd-a6611f54576f' : 'products/product-colors',
      params,
    });
  // Success         https://run.mocky.io/v3/f6d85b11-98d6-4ffe-8ebd-a6611f54576f
}

export const ModelProductService = new ModelProductServiceClass();

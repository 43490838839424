import React, { useEffect, useState } from 'react';
import { Divider, Stack } from '@mui/material';
import './YesSummaryScreen//YesSummaryScreen.scss';
import GroupedLabel from '../../components/groupedLabel/groupedLabel';
import { ModelProductService } from '../../services/modelProductService';
import { useFilterSaleStore } from '../../state/filter-sale-store';
import { formatArrToDropDown, formatArrObjToDropdown } from '../../helper/utils-helper';
import { GroupedLabelMultiple } from '../../components/groupedLabel/groupedLabelMutiple';
import { useNavigate } from 'react-router-dom';
import { DELIVERY_STATUS_SUBMENU } from '../DeliveryStatusScreen/constant';

import { useAuthStore } from '../../state/authen-store';
import { filterPayload } from '../../services/type';
import BackdropWithLoading from '../../components/loading';
import moment from 'moment';

export interface FilteYesDealerProps {
  activeSubmenu: string;
  callBackFilterParams: (value: any) => void;
  loadFilter: (loading: boolean) => void;
  params?: filterPayload;
  pageIndex: number;
}

export const FilteYesDealer = (props: FilteYesDealerProps) => {
  const mockDate = [{ month: Number(moment().format('MM')), year: Number(moment().year()) }];
  const { activeSubmenu, callBackFilterParams, loadFilter, pageIndex } = props;

  const { filterModelName, setModelNames } = useFilterSaleStore();
  const { filterColor, setColors } = useFilterSaleStore();
  const { filterModelCode, setModelCodes } = useFilterSaleStore();
  const { filterProductCodes, setProductCodes } = useFilterSaleStore();
  const { profileData } = useAuthStore();
  const [loading, setLoading] = useState<boolean>(true);
  const navigate = useNavigate();
  const sizePage = 20;
  const intitialParams = {
    modelNames: null,
    modelCodes: null,
    productCodes: null,
    colorCodes: null,
    limit: sizePage,
    offSet: sizePage * (pageIndex - 1),
    month: Number(moment().format('MM')).toString().padStart(2, '0'),
    year: Number(moment().year()).toString(),
  };
  const [isParamsFilter, setIsParamsFilter] = useState<any>(intitialParams);

  useEffect(() => {
    const fetchData = async () => {
      loadFilter(true);
      setLoading(true);
      if (profileData && !loading) {
        try {
          setLoading(true);
          const promises = [];
          promises.push(getModelName());
          promises.push(getModelCode());
          promises.push(getProductCode());
          promises.push(getColor());
          await Promise.all(promises);
          setTimeout(() => {
            loadFilter(false);
            setLoading(false);
          }, 450);
        } catch (error: any) {
          setLoading(false);
          loadFilter(false);
          handleApiError(error);
        }
      }
    };
    fetchData();
    // console.warn('activeSubmenuactiveSubmenu', activeSubmenu);
  }, []);

  const updateFilterValue = (key: string, value: any) => {
    // Create a new object
    const updatedParamsFilter = {
      ...isParamsFilter, // Copy the existing state
      [key]: value,
    };
    setIsParamsFilter(updatedParamsFilter);
  };

  const getColor = () => {
    ModelProductService.getColors(isParamsFilter)
      .then((resp) => {
        const data = resp.data.data;
        setColors(data);
      })
      .catch((error) => {
        handleApiError(error);
        console.error('Error fetching getColors:', error);
      });
  };

  const getProductCode = () => {
    ModelProductService.getProductCodes(isParamsFilter)
      .then((resp) => {
        const data = resp.data.data;
        setProductCodes(data);
      })
      .catch((error) => {
        handleApiError(error);
        console.error('Error fetching getProductCodes:', error);
      });
  };

  const getModelCode = () => {
    ModelProductService.getModelCodes(isParamsFilter)
      .then((resp) => {
        const data = resp.data.data;
        setModelCodes(data);
      })
      .catch((error) => {
        handleApiError(error);
        console.error('Error fetching getModelCodes:', error);
      });
  };

  const getModelName = () => {
    ModelProductService.getModelNames(isParamsFilter)
      .then((resp) => {
        const data = resp.data.data;
        setModelNames(data);
      })
      .catch((error) => {
        handleApiError(error);
        console.error('Error fetching getModelNames', error.code);
      });
  };

  const handleApiError = (err?: any) => {
    if (err.code === 'ERR_INVALID_JWT') {
      return navigate('/error', {
        state: {
          errorMessage: err.message || err.code || '',
          errtokenExpire: true,
        },
        replace: true, // If you want to replace the current entry in the history stack
      });
    }
    return navigate('/error', {
      state: {
        errorMessage: err.message || err.code || '',
        errtokenExpire: false,
      },
      replace: true, // If you want to replace the current entry in the history stack
    });
  };

  useEffect(() => {
    callBackFilterParams(isParamsFilter);
  }, [isParamsFilter]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      loadFilter(true);
      if (profileData) {
        try {
          const promises = [];
          promises.push(getModelName());
          promises.push(getColor());
          promises.push(getModelCode());
          promises.push(getProductCode());
          await Promise.all(promises);
          setLoading(false);
          loadFilter(false);
        } catch (error: any) {
          setLoading(false);
          loadFilter(false);
          handleApiError(error);
        }
      }
    };
    fetchData();
  }, []);

  return (
    <>
      <Divider orientation="horizontal" flexItem style={{ marginBottom: '5px', marginTop: '0px' }} />
      <div id="group-container">
        {activeSubmenu !== DELIVERY_STATUS_SUBMENU.BY_REGION && (
          <Stack
            style={{ justifyContent: 'center' }}
            direction="row"
            divider={<Divider orientation="vertical" flexItem />}
            spacing={2}
          >
            <>
              <GroupedLabelMultiple
                titleLabel="Model Name"
                dataList={formatArrToDropDown(filterModelName) || []}
                isSelectFilter={(key: string, value: any) => {
                  updateFilterValue(key, value);
                }}
                disabled={false}
              />

              <GroupedLabelMultiple
                titleLabel="Model Code"
                dataList={formatArrToDropDown(filterModelCode) || []}
                isSelectFilter={(key: string, value: any) => {
                  updateFilterValue(key, value);
                }}
                disabled={false}
              />

              <GroupedLabelMultiple
                titleLabel="Product Code"
                dataList={formatArrToDropDown(filterProductCodes)}
                isSelectFilter={(key: string, value: any) => {
                  updateFilterValue(key, value);
                }}
                disabled={false}
              />
              <GroupedLabelMultiple
                titleLabel="Color"
                dataList={formatArrObjToDropdown(filterColor, 'colorNameTh', 'colorCode')}
                isSelectFilter={(key: string, value: any) => {
                  updateFilterValue(key, value);
                }}
                disabled={false}
              />
              <>
                <GroupedLabel
                  titleLabel="Month-Year"
                  dataList={mockDate}
                  typeOfData="date"
                  isOnlyMonthYear
                  isSelectFilter={(key: string, value: any) => {
                    updateFilterValue(key, value);
                  }}
                  disabled={false}
                />
              </>
            </>
          </Stack>
        )}
      </div>
      <BackdropWithLoading show={loading} />
      <Divider orientation="horizontal" flexItem style={{ marginTop: '12px', marginBottom: '5px' }} />
    </>
  );
};

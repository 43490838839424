import API from '../api';
import { token } from '../type';

class AuthServiceClass {
  getProfile = (params?: token) =>
    // API.post({uri:'/sales/profile'});
    API.post({ uri: process.env.REACT_APP_ENV === 'SIT' ? '03bfd16a-ce5c-4c5e-a870-a504453aee73' : 'profile', params });

  // Success         https://run.mocky.io/v3/03bfd16a-ce5c-4c5e-a870-a504453aee73
}

export const AuthService = new AuthServiceClass();

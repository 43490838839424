// authStore.ts
import create from 'zustand';

export type ProfileDataType = {
  employeeCode: string;
  employeeName: string;
  regionCode: number | string | any;
  regionName: any;
  subregionCode: any;
  subregionName: any;
  areaCode: any;
  areaName: any;
  role: string;
  permissions: string[];
  ownerCode?: string;
  dealerName?: string;
};

interface AuthStore {
  profileData: ProfileDataType | null;
  setProfile: (profile: ProfileDataType) => void;
}

export const useAuthStore = create<AuthStore>((set, get) => ({
  profileData: null,
  setProfile: (profileData) => {
    set({ profileData: profileData });
  },
}));

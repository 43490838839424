import API from '../api';
import { filterPayload } from '../type';

class LateDeliveryServiceClass {
  getLateTrend = (params: filterPayload) =>
    // API.post({uri:'/sales/late-delivery-trend', params});
    API.post({
      uri: process.env.REACT_APP_ENV === 'SIT' ? '94d5e061-10fe-4723-bc91-3aafc9c0fc72' : 'late-delivery-trend',
      params,
    });
  // Success         https://run.mocky.io/v3/94d5e061-10fe-4723-bc91-3aafc9c0fc72

  getLateDepartureModel = (params: filterPayload) =>
    // API.post({uri:'/sales/late-departure-by-model', params});
    API.post({
      uri: process.env.REACT_APP_ENV === 'SIT' ? '0a7eb256-c1ce-4e29-8122-1c6418348de5' : 'late-departure-by-model',
      params,
    });

  // Success         https://run.mocky.io/v3/0a7eb256-c1ce-4e29-8122-1c6418348de5
  getLateDepartureProduct = (params: filterPayload) =>
    // API.post({uri:'/sales/late-departure-by-product-code', params});
    API.post({
      uri:
        process.env.REACT_APP_ENV === 'SIT' ? 'ea0079dc-3684-4a96-a500-367facd9e201' : 'late-departure-by-product-code',
      params,
    });

  getAvgDelayedTransit = (params: filterPayload) =>
    // API.post({uri:'/sales/avg-delay-transit-time', params});
    API.post({
      uri: process.env.REACT_APP_ENV === 'SIT' ? 'a07a57cd-ab48-4350-854f-a4323bd32bf4' : 'avg-delay-transit-time',
      params,
    });
  // Success         https://run.mocky.io/v3/a07a57cd-ab48-4350-854f-a4323bd32bf4
}

export const LateDeliveryService = new LateDeliveryServiceClass();

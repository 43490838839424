import React, { useEffect, useState } from 'react';
import { Divider, Stack } from '@mui/material';
import './DeliveryStatusScreen.scss';
import GroupedLabel from '../../components/groupedLabel/groupedLabel';
import { ModelProductService } from '../../services/modelProductService';
import { DELIVERY_STATUS_SUBMENU, PROFILE_ROLE } from './constant';
import { OwnerSaleService } from '../../services/ownerSaleService';
import { useFilterSaleStore } from '../../state/filter-sale-store';
import {
  formatArrToDropDown,
  formatArrObjToDropdown,
  changeValuesToLabel,
  checkRoleManageMent,
} from '../../helper/utils-helper';
import { GroupedLabelMultiple } from '../../components/groupedLabel/groupedLabelMutiple';
import { RegionService } from '../../services/regionServices';
import { useNavigate } from 'react-router-dom';
import BackdropWithLoading from '../../components/loading';
import { ProfileDataType, useAuthStore } from '../../state/authen-store';
import { filterPayload } from '../../services/type';
import GroupedLabelSingle from '../../components/groupedLabel/groupedLabelSingle';
import moment from 'moment';

export interface FilterDeliveryDeliveryProps {
  activeSubmenu: string;
  callBackFilterParams: (value: any) => void;
  loadFilter: (end: boolean) => void;
  regionCodeByRegion?: number | any;
}

export const FilterDeliveryDelivery = (props: FilterDeliveryDeliveryProps) => {
  const mockDate = [{ month: Number(moment().format('MM')), year: Number(moment().year()) }];
  const { activeSubmenu, callBackFilterParams, loadFilter, regionCodeByRegion } = props;
  const { profileData } = useAuthStore();
  const { filterModelName, setModelNames } = useFilterSaleStore();
  const { filterDealer, setDealers } = useFilterSaleStore();
  const { filterColor, setColors } = useFilterSaleStore();
  const { filterModelCode, setModelCodes } = useFilterSaleStore();
  const { filterProductCodes, setProductCodes } = useFilterSaleStore();
  const { filterOwnerCodes, setOwnerCodes } = useFilterSaleStore();
  const { filterProvince, setProvinces } = useFilterSaleStore();
  const { filterArea, setAreas } = useFilterSaleStore();
  const { filterSubRegion, setSubRegions } = useFilterSaleStore();
  const { filterRegion, setRegions } = useFilterSaleStore();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const { role, regionCode, subregionCode, areaCode } = profileData || ({} as ProfileDataType);
  // console.warn('rolerole', role);
  const [isParamsFilter, setIsParamsFilter] = useState<filterPayload>({
    subregionCodes: [checkRoleManageMent(role) ? null : subregionCode],
    regionCode: checkRoleManageMent(role) ? (regionCodeByRegion ? regionCodeByRegion : 2) : regionCode,
    areaCodes: checkRoleManageMent(role) ? null : [areaCode],
    provinceCodes: null,
    ownerCodes: null,
    dealerCodes: null,
    modelNames: null,
    modelCodes: null,
    productCodes: null,
    colorCodes: null,
    limit: null,
    offSet: null,
    // month: (new Date().getMonth() - 6).toString().padStart(2, '0'),
    month: Number(moment().format('MM')).toString().padStart(2, '0'),
    year: Number(moment().year()).toString(),
  });

  const updateFilterValue = (key: string, value: any) => {
    // console.warn('updateFilterValue', key, value);
    const updatedParamsFilter = {
      ...isParamsFilter, // Copy the existing state
      [key]: value, // Update the  field & value
    };
    // Update the state with the new object
    setIsParamsFilter(updatedParamsFilter);
  };

  const getRegion = () => {
    setLoading(true);
    RegionService.getRegions({})
      .then((resp) => {
        const data = resp.data.data;
        setLoading(false);
        if (data) {
          setRegions(data);
          // console.warn('getRegionsgetRegionsgetRegions', data);
        } else {
          handleApiError(resp.data);
        }
      })
      .catch((error) => {
        handleApiError(error);
        console.error('Error fetching getRegions:', error);
      });
  };

  const getModelName = () => {
    setLoading(true);
    ModelProductService.getModelNames(isParamsFilter)
      .then((resp) => {
        setLoading(false);
        const data = resp.data.data;
        if (data) {
          setModelNames(data);
        } else {
          handleApiError(resp.data);
        }
      })
      .catch((error) => {
        handleApiError(error);
        console.error('Error fetching getModelNames', error.message);
      });
  };

  const checkRoleRegionFilter = () => {
    if (
      activeSubmenu === DELIVERY_STATUS_SUBMENU.BY_AREA ||
      activeSubmenu === DELIVERY_STATUS_SUBMENU.BY_DEALER ||
      activeSubmenu === DELIVERY_STATUS_SUBMENU.BY_REGION
    ) {
      if (role === PROFILE_ROLE.DM || role === PROFILE_ROLE.SP) {
        return false;
      }
    }
    return true;
  };
  // console.warn('checkRoleRegionFilter', checkRoleRegionFilter());

  const checkRoleAreaFilter = () => {
    if (
      activeSubmenu === DELIVERY_STATUS_SUBMENU.BY_AREA ||
      activeSubmenu === DELIVERY_STATUS_SUBMENU.BY_DEALER ||
      activeSubmenu === DELIVERY_STATUS_SUBMENU.BY_REGION
    ) {
      if (
        role === PROFILE_ROLE.DM ||
        role === PROFILE_ROLE.SP ||
        role === PROFILE_ROLE.GM ||
        role === PROFILE_ROLE.HD
      ) {
        return false;
      }
    }
    return true;
  };

  const getSubRegion = (regionCode: number | any) => {
    setLoading(true);
    const isParams = {
      regionCode: regionCode ? regionCode : null,
    };
    RegionService.getSubRegions(isParams)
      .then((resp) => {
        setLoading(false);
        const data = resp.data.data;
        if (data) {
          setSubRegions(data);
        } else {
          handleApiError(resp.data);
        }
      })
      .catch((error) => {
        handleApiError(error);
        console.error('Error fetching getSubRegion:', error);
      });
  };

  const getArea = (regionCode: number | any, subregionCode: any) => {
    setLoading(true);
    const isParams = {
      regionCode: regionCode,
      subregionCodes: [subregionCode] || null,
    };
    RegionService.getAreas(isParams)
      .then((resp) => {
        setLoading(false);
        const data = resp.data.data;
        if (data) {
          setAreas(data);
        } else {
          handleApiError(resp.data);
        }
      })
      .catch((error) => {
        handleApiError(error);
        console.error('Error fetching getArea:', error);
      });
  };

  const getProvince = (regionCode: number | any, subregionCode: any, areaCode: number | any) => {
    setLoading(true);
    const isParams = {
      regionCode: regionCode || null,
      subregionCodes: [subregionCode] || null,
      areaCodes: [areaCode] || null,
    };
    RegionService.getProvinces(isParams)
      .then((resp) => {
        setLoading(false);
        const data = resp.data.data;
        if (data) {
          setProvinces(data);
        } else {
          handleApiError(resp.data);
        }
      })
      .catch((error) => {
        handleApiError(error);
        console.error('Error fetching getProvince:', error);
      });
  };

  const getOwnerCode = () => {
    setLoading(true);
    OwnerSaleService.getOwnerCodes(isParamsFilter)
      .then((resp) => {
        setLoading(false);
        const data = resp.data.data;
        if (data) {
          setOwnerCodes(data);
        } else {
          handleApiError(resp.data);
        }
      })
      .catch((error) => {
        handleApiError(error);
        console.error('Error fetching getOwnerCode:', error);
      });
  };

  const getColor = () => {
    setLoading(true);
    ModelProductService.getColors(isParamsFilter)
      .then((resp) => {
        setLoading(false);
        const data = resp.data.data;
        if (data) {
          setColors(data);
        } else {
          handleApiError(resp.data);
        }
      })
      .catch((error) => {
        handleApiError(error);
        console.error('Error fetching getColor:', error);
      });
  };

  const getProductCode = () => {
    setLoading(true);
    ModelProductService.getProductCodes(isParamsFilter)
      .then((resp) => {
        setLoading(false);
        const data = resp.data.data;
        if (data) {
          setProductCodes(data);
        } else {
          handleApiError(resp.data);
        }
      })
      .catch((error) => {
        handleApiError(error);
        console.error('Error fetching getProductCode:', error);
      });
  };

  const getModelCode = () => {
    setLoading(true);
    ModelProductService.getModelCodes(isParamsFilter)
      .then((resp) => {
        setLoading(false);
        const data = resp.data.data;
        if (data) {
          setModelCodes(data);
        } else {
          handleApiError(resp.data);
        }
      })
      .catch((error) => {
        handleApiError(error);
        console.error('Error fetching getModelCode:', error);
      });
  };

  const getDealer = () => {
    setLoading(true);
    OwnerSaleService.getDealers(isParamsFilter)
      .then((resp) => {
        setLoading(false);
        const data = resp.data.data;
        if (data) {
          setDealers(data);
        } else {
          handleApiError(resp.data);
        }
      })
      .catch((error) => {
        handleApiError(error);
        console.error('Error fetching getDealer:', error);
      });
  };

  const handleApiError = (err?: any) => {
    if (err.code === 'ERR_INVALID_JWT') {
      return navigate('/error', {
        state: {
          errorMessage: err.message || err.code || '',
          originalPath: '/delivery-status',
          errtokenExpire: true,
        },
        replace: true, // If you want to replace the current entry in the history stack
      });
    }
    return navigate('/error', {
      state: {
        errorMessage: err.message || err.code || '',
        originalPath: '/delivery-status',
        errtokenExpire: false,
      },
      replace: true, // If you want to replace the current entry in the history stack
    });
  };

  useEffect(() => {
    if (regionCodeByRegion || checkRoleManageMent(role)) {
      updateFilterValue('regionCode', Number(regionCodeByRegion ? regionCodeByRegion : 2));
    }
  }, [regionCodeByRegion]);

  useEffect(() => {
    callBackFilterParams(isParamsFilter);
  }, [isParamsFilter]);

  useEffect(() => {
    const fetchData = async () => {
      // console.warn('activeSubmenuactiveSubmenuactiveSubmenuactiveSubmenu', activeSubmenu);
      loadFilter(true);
      setLoading(true);
      if (profileData && !loading) {
        setLoading(true);
        try {
          const promises = [];
          if (
            activeSubmenu === DELIVERY_STATUS_SUBMENU.BY_AREA ||
            activeSubmenu === DELIVERY_STATUS_SUBMENU.BY_DEALER
          ) {
            promises.push(getRegion());
            promises.push(getSubRegion(profileData.regionCode));
            promises.push(getArea(profileData.regionCode, profileData.subregionCode));
            promises.push(getProvince(profileData.regionCode, profileData.subregionCode, profileData.areaCode));
            promises.push(getDealer());
            promises.push(getModelName());
            promises.push(getColor());
            promises.push(getModelCode());
            promises.push(getProductCode());
            promises.push(getOwnerCode());
          } else if (activeSubmenu === DELIVERY_STATUS_SUBMENU.BY_REGION) {
            promises.push(getRegion());
            promises.push(getSubRegion(profileData.regionCode));
            promises.push(getModelName());
            promises.push(getColor());
            promises.push(getModelCode());
            promises.push(getProductCode());
            promises.push(getOwnerCode());
          }
          await Promise.all(promises);
          setTimeout(() => {
            loadFilter(false);
            setLoading(false);
          }, 450);
          // console.warn(' await Promise.a await Promise.a');
        } catch (error: any) {
          loadFilter(false);
          setLoading(false);
          handleApiError(error);
        }
      }
    };
    fetchData();
  }, []);

  return (
    <>
      <Divider orientation="horizontal" flexItem style={{ marginBottom: '5px', marginTop: '0px' }} />
      {activeSubmenu === DELIVERY_STATUS_SUBMENU.BY_REGION && (
        <>
          <div id="group-container">
            <Stack
              style={{ justifyContent: 'center' }}
              direction="row"
              divider={<Divider orientation="vertical" flexItem />}
              spacing={2}
            >
              <>
                <GroupedLabelMultiple
                  titleLabel="Model Name"
                  dataList={formatArrToDropDown(filterModelName) || []}
                  isSelectFilter={(key: string, value: any) => {
                    updateFilterValue(key, value);
                  }}
                  disabled={false}
                />

                <GroupedLabelMultiple
                  titleLabel="Model Code"
                  dataList={formatArrToDropDown(filterModelCode) || []}
                  isSelectFilter={(key: string, value: any) => {
                    updateFilterValue(key, value);
                  }}
                  disabled={false}
                />

                <GroupedLabelMultiple
                  titleLabel="Product Code"
                  dataList={formatArrToDropDown(filterProductCodes)}
                  isSelectFilter={(key: string, value: any) => {
                    updateFilterValue(key, value);
                  }}
                  disabled={false}
                />
                <GroupedLabelMultiple
                  titleLabel="Color"
                  dataList={formatArrObjToDropdown(filterColor, 'colorNameTh', 'colorCode')}
                  isSelectFilter={(key: string, value: any) => {
                    updateFilterValue(key, value);
                  }}
                  disabled={false}
                />
              </>
              <GroupedLabel
                titleLabel="Month-Year"
                dataList={mockDate}
                typeOfData="date"
                isOnlyMonthYear
                isSelectFilter={(key: string, value: any) => {
                  // console.warn(key);
                  // console.warn(value);
                  const updatedParamsFilter = {
                    ...isParamsFilter, // Copy the existing state
                    month: value.month.toString().padStart(2, '0'), // Update the  field & value
                    year: value.year.toString(),
                  };
                  // console.warn('updatedParamsFilterupdatedParamsFilter', updatedParamsFilter);
                  setIsParamsFilter(updatedParamsFilter);
                }}
                disabled={false}
                isLockMonthCurrent={false}
              />
            </Stack>
          </div>
        </>
      )}

      {activeSubmenu !== DELIVERY_STATUS_SUBMENU.BY_REGION && (
        <>
          <div id="group-container">
            <Stack
              style={{ justifyContent: 'center' }}
              direction="row"
              divider={<Divider orientation="vertical" flexItem />}
              spacing={2}
            >
              <>
                {regionCodeByRegion || checkRoleManageMent(role) ? (
                  <GroupedLabelSingle
                    titleLabel="Region"
                    dataList={formatArrObjToDropdown(filterRegion, 'regionName', 'regionCode')}
                    selectedTemp={Number(regionCodeByRegion ? regionCodeByRegion : 2)}
                    disabled={false}
                    isSelectFilter={(key: string, value: any) => {
                      updateFilterValue(key, value);
                    }}
                    textLabelTemp={changeValuesToLabel(
                      Number(regionCodeByRegion ? regionCodeByRegion : 2),
                      formatArrObjToDropdown(filterRegion, 'regionName', 'regionCode'),
                    )}
                  />
                ) : (
                  <GroupedLabelSingle
                    titleLabel="Region"
                    dataList={formatArrObjToDropdown(filterRegion, 'regionName', 'regionCode')}
                    selectedTemp={regionCode}
                    disabled={checkRoleRegionFilter()}
                    textLabelTemp={changeValuesToLabel(
                      regionCode,
                      formatArrObjToDropdown(filterRegion, 'regionName', 'regionCode'),
                    )}
                    isSelectFilter={(key: string, value: any) => {
                      updateFilterValue(key, value);
                    }}
                  />
                )}

                {/* <GroupedLabelMultiple
              titleLabel="Region"
              dataList={formatArrObjToDropdown(filterRegion, 'regionName', 'regionCode')}
              selectedTemp={regionCode}
              disabled={checkRoleRegionFilter()}
              textLabelTemp={changeValuesToLabel(
                regionCode,
                formatArrObjToDropdown(filterRegion, 'regionName', 'regionCode'),
              )}
              isSelectFilter={(key: string, value: any) => {
                updateFilterValue(key, value);
              }}
            /> */}

                <GroupedLabelMultiple
                  titleLabel="Sub-Region"
                  dataList={formatArrObjToDropdown(filterSubRegion, 'subregionName', 'subregionCode')}
                  disabled={checkRoleAreaFilter()}
                  isSelectFilter={(key: string, value: any) => {
                    updateFilterValue(key, value);
                  }}
                  selectedTemp={subregionCode}
                  textLabelTemp={changeValuesToLabel(
                    subregionCode,
                    formatArrObjToDropdown(filterSubRegion, 'subregionName', 'subregionCode'),
                  )}
                />
              </>
              <GroupedLabelMultiple
                titleLabel="Owner Code"
                dataList={formatArrToDropDown(filterOwnerCodes)}
                isSelectFilter={(key: string, value: any) => {
                  updateFilterValue(key, value);
                }}
                disabled={false}
              />
              <>
                <GroupedLabelMultiple
                  titleLabel="Model Name"
                  dataList={formatArrToDropDown(filterModelName) || []}
                  isSelectFilter={(key: string, value: any) => {
                    updateFilterValue(key, value);
                  }}
                  disabled={false}
                />
                <GroupedLabelMultiple
                  titleLabel="Model Code"
                  dataList={formatArrToDropDown(filterModelCode) || []}
                  isSelectFilter={(key: string, value: any) => {
                    updateFilterValue(key, value);
                  }}
                  disabled={false}
                />
              </>
              <GroupedLabel
                titleLabel="Month-Year"
                dataList={mockDate}
                typeOfData="date"
                isOnlyMonthYear
                isSelectFilter={(key: string, value: any) => {
                  // console.warn(key);
                  console.warn('isSelectFilterisSelectFilterisSelectFilterisSelectFilter', value);
                  const updatedParamsFilter = {
                    ...isParamsFilter, // Copy the existing state
                    month: value.month.toString().padStart(2, '0'), // Update the  field & value
                    year: value.year.toString(),
                  };
                  // console.warn('updatedParamsFilterupdatedParamsFilter', updatedParamsFilter);
                  setIsParamsFilter(updatedParamsFilter);
                }}
                disabled={false}
                isLockMonthCurrent={false}
              />
            </Stack>
          </div>
          <div id="group-container">
            <Stack
              direction="row"
              divider={<Divider orientation="vertical" flexItem />}
              spacing={2}
              style={{ justifyContent: 'center' }}
            >
              <>
                {/* <GroupedLabelMultiple
                titleLabel="Sub-Region"
                dataList={formatArrObjToDropdown(filterSubRegion, 'subregionName', 'subregionCode')}
                disabled={checkRoleAreaFilter()}
                isSelectFilter={(key: string, value: any) => {
                  updateFilterValue(key, value);
                }}
                selectedTemp={subregionCode}
                textLabelTemp={changeValuesToLabel(
                  subregionCode,
                  formatArrObjToDropdown(filterSubRegion, 'subregionName', 'subregionCode'),
                )}
              />
              areaCode */}
                <GroupedLabelMultiple
                  titleLabel="Area"
                  dataList={formatArrObjToDropdown(filterArea, 'areaName', 'areaCode')}
                  disabled={checkRoleAreaFilter()}
                  isSelectFilter={(key: string, value: any) => {
                    updateFilterValue(key, value);
                  }}
                  selectedTemp={areaCode}
                  textLabelTemp={
                    areaCode &&
                    changeValuesToLabel(areaCode, formatArrObjToDropdown(filterArea, 'areaName', 'areaCode'))
                  }
                />
                <GroupedLabelMultiple
                  titleLabel="Province"
                  dataList={formatArrObjToDropdown(filterProvince, 'provinceNameTh', 'provinceCode')}
                  isSelectFilter={(key: string, value: any) => {
                    updateFilterValue(key, value);
                  }}
                  disabled={false}
                />
              </>
              <GroupedLabelMultiple
                titleLabel="Dealer"
                dataList={formatArrObjToDropdown(filterDealer, 'dealerNameTh', 'dealerCode' || [])}
                isSelectFilter={(key: string, value: any) => {
                  updateFilterValue(key, value);
                }}
                disabled={false}
              />
              <>
                <GroupedLabelMultiple
                  titleLabel="Product Code"
                  dataList={formatArrToDropDown(filterProductCodes)}
                  isSelectFilter={(key: string, value: any) => {
                    updateFilterValue(key, value);
                  }}
                  disabled={false}
                />
                <GroupedLabelMultiple
                  titleLabel="Color"
                  dataList={formatArrObjToDropdown(filterColor, 'colorNameTh', 'colorCode')}
                  isSelectFilter={(key: string, value: any) => {
                    updateFilterValue(key, value);
                  }}
                  disabled={false}
                />
              </>
              <div className="empty-box"></div>
            </Stack>
          </div>
        </>
      )}
      <BackdropWithLoading show={loading} />
      <Divider orientation="horizontal" flexItem style={{ marginTop: '5px', marginBottom: '5px' }} />
    </>
  );
};

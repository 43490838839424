import React, { useEffect, useState } from 'react';
import { Divider, Stack } from '@mui/material';
import './ExpectedDeliveryScreen.scss';
import GroupedLabel from '../../components/groupedLabel/groupedLabel';
import { ModelProductService } from '../../services/modelProductService';
import { OwnerSaleService } from '../../services/ownerSaleService';
import { useFilterSaleStore } from '../../state/filter-sale-store';
import {
  formatArrToDropDown,
  formatArrObjToDropdown,
  changeValuesToLabel,
  checkRoleManageMent,
} from '../../helper/utils-helper';
import { GroupedLabelMultiple } from '../../components/groupedLabel/groupedLabelMutiple';
import { RegionService } from '../../services/regionServices';
import { useNavigate } from 'react-router-dom';
import { PROFILE_ROLE, DELIVERY_STATUS_SUBMENU } from '../DeliveryStatusScreen/constant';
import GroupedLabelSingle from '../../components/groupedLabel/groupedLabelSingle';
import { ProfileDataType, useAuthStore } from '../../state/authen-store';
import { filterPayload } from '../../services/type';
import BackdropWithLoading from '../../components/loading';
import moment from 'moment';

export interface FilterExpectedDeliveryProps {
  activeSubmenu: string;
  callBackFilterParams: (value: any) => void;
  loadFilter: (loading: boolean) => void;
  pageIndex: number;
}

export const FilterExpectedDelivery = (props: FilterExpectedDeliveryProps) => {
  const mockDate = [{ month: Number(moment().format('MM')), year: Number(moment().year()) }];
  const { activeSubmenu, callBackFilterParams, loadFilter, pageIndex } = props;
  const [loading, setLoading] = useState<boolean>(false);
  const { filterModelName, setModelNames } = useFilterSaleStore();
  const { filterDealer, setDealers } = useFilterSaleStore();
  const { filterColor, setColors } = useFilterSaleStore();
  const { filterModelCode, setModelCodes } = useFilterSaleStore();
  const { filterProductCodes, setProductCodes } = useFilterSaleStore();
  const { filterOwnerCodes, setOwnerCodes } = useFilterSaleStore();
  const { filterProvince, setProvinces } = useFilterSaleStore();
  const { filterArea, setAreas } = useFilterSaleStore();
  const { filterSubRegion, setSubRegions } = useFilterSaleStore();
  const { filterRegion, setRegions } = useFilterSaleStore();
  const navigate = useNavigate();
  const { profileData } = useAuthStore();
  const { role, regionCode, subregionCode, areaCode } = profileData || ({} as ProfileDataType);
  const sizePage = 20;
  const intitialParams = {
    subregionCodes: [checkRoleManageMent(role) ? null : subregionCode],
    regionCode: checkRoleManageMent(role) ? null : regionCode,
    areaCodes: [checkRoleManageMent(role) ? null : areaCode],
    month: Number(moment().format('MM')).toString().padStart(2, '0'),
    year: Number(moment().year()).toString(),
    provinceCodes: null,
    ownerCodes: null,
    dealerCodes: null,
    modelNames: null,
    modelCodes: null,
    productCodes: null,
    colorCodes: null,
    limit: sizePage,
    offSet: sizePage * (pageIndex - 1),
  };

  const [isParamsFilter, setIsParamsFilter] = useState<filterPayload>(intitialParams);

  const updateFilterValue = (key: string, value: any) => {
    console.warn('updateFilterValue');
    // Create a new object
    const updatedParamsFilter = {
      ...isParamsFilter, // Copy the existing state
      limit: sizePage,
      offSet: sizePage * (pageIndex - 1),
      [key]: value,
    };
    setIsParamsFilter(updatedParamsFilter);
  };

  const getRegion = () => {
    RegionService.getRegions(isParamsFilter)
      .then((resp) => {
        const data = resp.data.data;
        setRegions(data);
      })
      .catch((error) => {
        handleApiError(error);
        console.error('Error fetching getRegions:', error);
      });
  };

  const checkRoleRegionFilter = () => {
    if (role === PROFILE_ROLE.DM || role === PROFILE_ROLE.SP) {
      return false;
    }
    return true;
  };

  const checkRoleAreaFilter = () => {
    if (role === PROFILE_ROLE.DM || role === PROFILE_ROLE.SP || role === PROFILE_ROLE.GM || role === PROFILE_ROLE.HD) {
      return false;
    }
    return true;
  };

  const getSubRegion = (regionCode: number | string | any) => {
    const isParams = {
      regionCode: regionCode || null,
      subregionCodes: [subregionCode] || null,
    };

    RegionService.getSubRegions(isParams)
      .then((resp) => {
        const data = resp.data.data;
        setSubRegions(data);
      })
      .catch((error) => {
        // Handle errors here
        handleApiError(error);
        console.error('Error fetching getSubRegions:', error);
      });
  };

  const getArea = (regionCode: number | string | any, subregionCode: any) => {
    const isParams = {
      regionCode: regionCode || null,
      subregionCodes: [subregionCode] || null,
    };
    RegionService.getAreas(isParams)
      .then((resp) => {
        const data = resp.data.data;
        setAreas(data);
      })
      .catch((error) => {
        handleApiError(error);
        console.error('Error fetching getAreas:', error);
      });
  };

  const getProvince = (regionCode: number | string | any, subregionCode: any, areaCodes: number | string | any) => {
    const isParams = {
      regionCode: regionCode || null,
      subregionCodes: [subregionCode] || null,
      areaCodes: [areaCodes] || null,
    };
    RegionService.getProvinces(isParams)
      .then((resp) => {
        const data = resp.data.data;
        setProvinces(data);
      })
      .catch((error) => {
        handleApiError(error);
        console.error('Error fetching getProvinces:', error);
      });
  };

  const getOwnerCode = () => {
    OwnerSaleService.getOwnerCodes(isParamsFilter)
      .then((resp) => {
        const data = resp.data.data;
        setOwnerCodes(data);
      })
      .catch((error) => {
        handleApiError(error);
        console.error('Error fetching getOwnerCodes:', error);
      });
  };

  const getColor = () => {
    ModelProductService.getColors(isParamsFilter)
      .then((resp) => {
        const data = resp.data.data;
        setColors(data);
      })
      .catch((error) => {
        handleApiError(error);
        console.error('Error fetching getColors:', error);
      });
  };

  const getProductCode = () => {
    ModelProductService.getProductCodes(isParamsFilter)
      .then((resp) => {
        const data = resp.data.data;
        setProductCodes(data);
      })
      .catch((error) => {
        handleApiError(error);
        console.error('Error fetching getProductCodes:', error);
      });
  };

  const getModelCode = () => {
    ModelProductService.getModelCodes(isParamsFilter)
      .then((resp) => {
        const data = resp.data.data;
        setModelCodes(data);
      })
      .catch((error) => {
        handleApiError(error);
        console.error('Error fetching getModelCodes:', error);
      });
  };

  const getDealer = () => {
    OwnerSaleService.getDealers(isParamsFilter)
      .then((resp) => {
        const data = resp.data.data;
        setDealers(data);
      })
      .catch((error) => {
        handleApiError(error);
        console.error('Error fetching getDealers:', error);
      });
  };

  const getModelName = () => {
    ModelProductService.getModelNames(isParamsFilter)
      .then((resp) => {
        const data = resp.data.data;
        setModelNames(data);
      })
      .catch((error) => {
        handleApiError(error);
        console.error('Error fetching getModelNames', error);
      });
  };

  const handleApiError = (err: any) => {
    if (err.code === 'ERR_INVALID_JWT') {
      return navigate('/error', {
        state: {
          errorMessage: err.message || err.code || '',
          originalPath: '/expected-delivery',
          errtokenExpire: true,
        },
        replace: true,
      });
    }
    return navigate('/error', {
      state: {
        errorMessage: err.message || err.code || '',
        originalPath: '/expected-delivery',
        errtokenExpire: false,
      },
      replace: true,
    });
  };
  useEffect(() => {
    // console.warn('callBackFilter In filter', isParamsFilter);
    callBackFilterParams(isParamsFilter);
  }, [isParamsFilter]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      loadFilter(true);
      if (profileData) {
        try {
          const promises = [];
          promises.push(getRegion());
          promises.push(getSubRegion(profileData.regionCode));
          promises.push(getArea(profileData.regionCode, profileData.subregionCode));
          promises.push(getProvince(profileData.regionCode, profileData.subregionCode, profileData.areaCode));
          promises.push(getDealer());
          promises.push(getModelName());
          promises.push(getColor());
          promises.push(getModelCode());
          promises.push(getProductCode());
          promises.push(getOwnerCode());
          await Promise.all(promises);
          setTimeout(() => {
            loadFilter(false);
            setLoading(false);
          }, 450);
        } catch (error: any) {
          setLoading(false);
          loadFilter(false);
          handleApiError(error);
        }
      }
    };
    fetchData();
  }, []);

  return (
    <>
      <Divider orientation="horizontal" flexItem style={{ marginBottom: '5px', marginTop: '0px' }} />
      <div id="group-container">
        <Stack
          style={{ justifyContent: 'center' }}
          direction="row"
          divider={<Divider orientation="vertical" flexItem />}
          spacing={2}
        >
          <>
            <GroupedLabelSingle
              titleLabel="Region"
              dataList={formatArrObjToDropdown(filterRegion, 'regionName', 'regionCode')}
              selectedTemp={regionCode}
              disabled={checkRoleRegionFilter()}
              textLabelTemp={changeValuesToLabel(
                regionCode,
                formatArrObjToDropdown(filterRegion, 'regionName', 'regionCode'),
              )}
              isSelectFilter={(key: string, value: any) => {
                updateFilterValue(key, value);
              }}
            />
            <GroupedLabelMultiple
              titleLabel="Sub-Region"
              dataList={formatArrObjToDropdown(filterSubRegion, 'subregionName', 'subregionCode')}
              disabled={checkRoleAreaFilter()}
              isSelectFilter={(key: string, value: any) => {
                updateFilterValue(key, value);
              }}
              selectedTemp={subregionCode}
              textLabelTemp={changeValuesToLabel(
                subregionCode,
                formatArrObjToDropdown(filterSubRegion, 'subregionName', 'subregionCode'),
              )}
            />
          </>
          <GroupedLabelMultiple
            titleLabel="Owner Code"
            dataList={formatArrToDropDown(filterOwnerCodes)}
            isSelectFilter={(key: string, value: any) => {
              updateFilterValue(key, value);
            }}
            disabled={false}
          />
          <>
            <GroupedLabelMultiple
              titleLabel="Model Name"
              dataList={formatArrToDropDown(filterModelName) || []}
              isSelectFilter={(key: string, value: any) => {
                updateFilterValue(key, value);
              }}
              disabled={false}
            />
            <GroupedLabelMultiple
              titleLabel="Model Code"
              dataList={formatArrToDropDown(filterModelCode) || []}
              isSelectFilter={(key: string, value: any) => {
                updateFilterValue(key, value);
              }}
              disabled={false}
            />
          </>
          <GroupedLabel
            titleLabel="Month-Year"
            dataList={mockDate}
            typeOfData="date"
            isOnlyMonthYear
            isSelectFilter={(key: string, value: any) => {
              // console.warn(key);
              // console.warn(value);
              const updatedParamsFilter = {
                ...isParamsFilter, // Copy the existing state
                month: value.month.toString().padStart(2, '0'), // Update the  field & value
                year: value.year.toString(),
              };
              // console.warn('updatedParamsFilterupdatedParamsFilter', updatedParamsFilter);
              setIsParamsFilter(updatedParamsFilter);
            }}
            disabled={false}
          />
        </Stack>
      </div>
      {activeSubmenu !== DELIVERY_STATUS_SUBMENU.BY_REGION && (
        <div id="group-container">
          <Stack
            direction="row"
            divider={<Divider orientation="vertical" flexItem />}
            spacing={2}
            style={{ justifyContent: 'center' }}
          >
            <>
              <GroupedLabelMultiple
                titleLabel="Area"
                dataList={formatArrObjToDropdown(filterArea, 'areaName', 'areaCode')}
                disabled={checkRoleAreaFilter()}
                isSelectFilter={(key: string, value: any) => {
                  updateFilterValue(key, value);
                }}
                selectedTemp={areaCode}
                textLabelTemp={changeValuesToLabel(
                  areaCode,
                  formatArrObjToDropdown(filterArea, 'areaName', 'areaCode'),
                )}
              />
              <GroupedLabelMultiple
                titleLabel="Province"
                dataList={formatArrObjToDropdown(filterProvince, 'provinceNameTh', 'provinceCode')}
                isSelectFilter={(key: string, value: any) => {
                  updateFilterValue(key, value);
                }}
                disabled={false}
              />
            </>
            <GroupedLabelMultiple
              titleLabel="Dealer"
              dataList={formatArrObjToDropdown(filterDealer, 'dealerNameTh', 'dealerCode' || [])}
              isSelectFilter={(key: string, value: any) => {
                updateFilterValue(key, value);
              }}
              disabled={false}
            />
            <>
              <GroupedLabelMultiple
                titleLabel="Product Code"
                dataList={formatArrToDropDown(filterProductCodes)}
                isSelectFilter={(key: string, value: any) => {
                  updateFilterValue(key, value);
                }}
                disabled={false}
              />
              <GroupedLabelMultiple
                titleLabel="Color"
                dataList={formatArrObjToDropdown(filterColor, 'colorNameTh', 'colorCode')}
                isSelectFilter={(key: string, value: any) => {
                  updateFilterValue(key, value);
                }}
                disabled={false}
              />
            </>
            <div className="empty-box"></div>
          </Stack>
        </div>
      )}
      <BackdropWithLoading show={loading} />
      <Divider orientation="horizontal" flexItem style={{ marginTop: '5px', marginBottom: '5px' }} />
    </>
  );
};

import React, { CSSProperties } from 'react';
import { Stack } from '@mui/material';
import './tableVertical.scss';
export interface VerticalColumnsProps {
  columns: string[] | React.ReactNode[];
  width?: string | number;
  height?: string | number;
  styleColumn?: CSSProperties;
}

const VerticalColumns: React.FC<VerticalColumnsProps> = ({ columns, width, height, styleColumn }) => (
  <Stack className="fixColumn">
    {columns.map((column, index) => (
      <Stack
        key={index}
        style={{
          border: '1px solid #ccc',
          // border: index === 0 ? 'none' : '1px solid #ccc',
          maxWidth: width,
          minWidth: width,
          minHeight: height,
          maxHeight: height,
          justifyContent: 'center',
          paddingLeft: '12px',

          ...styleColumn,
        }}
      >
        {column}
      </Stack>
    ))}
  </Stack>
);

export default VerticalColumns;

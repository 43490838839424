import React from 'react';
import { Typography } from '@mui/material';
import './TextStyle.scss';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Variant } from '@mui/material/styles/createTypography';

function createData(name: string, typeface: string, weight: string, size: number) {
  return { name, typeface, weight, size };
}

const rows = [
  createData('NAVIGATION BAR', 'Prompt', 'Semi Bold', 18),
  createData('HEADLINE', 'Prompt', 'Medium', 36),
  createData('SUB NAVIGATION', 'Prompt', 'Regular', 18),
  createData('Filter Head', 'Prompt', 'Regular', 14),
  createData('Filter Body', 'Prompt', 'Regular', 16),
  createData('SECTION', 'Prompt', 'Semi Bold', 16),
  createData('KEY NO.', 'Teko', 'Regular', 66),
  createData('VIEW DETAIL', 'Prompt', 'Medium', 16),
  createData('Body in Widget', 'Prompt', 'Regular', 14),
  createData('Graph Label', 'Prompt', 'Regular', 16),
];

const TextStyle = () => {
  const type = (name: string): Variant =>
    name.toLowerCase().replaceAll(' ', '_').replaceAll('.', '') as
      | 'h1'
      | 'h2'
      | 'h3'
      | 'h4'
      | 'h5'
      | 'h6'
      | 'subtitle1'
      | 'subtitle2'
      | 'body1'
      | 'body2'
      | 'caption'
      | 'button'
      | 'overline';

  return (
    <div className={'card-container'}>
      <Typography variant="h3">Text Style</Typography>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Example</TableCell>
              <TableCell>Typeface</TableCell>
              <TableCell>Weight</TableCell>
              <TableCell>Size</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow key={row.name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component="th" scope="row">
                  <Typography variant={type(row.name)}>{row.name}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body1">{row.typeface}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body1">{row.weight}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body1">{row.size}</Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default TextStyle;

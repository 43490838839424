import API from '../api';
import { token } from '../type';

class LastUpdatedServiceClass {
  getDeliveryStatusLastUpdated = (params?: token) =>
    // API.post({uri:'/sales/delivery-status/last-updated'});
    API.post({
      uri:
        process.env.REACT_APP_ENV === 'SIT' ? '15e578df-212d-4b96-8a8f-641dac982436' : 'delivery-status/last-updated',
      params,
    });

  // Success         https://run.mocky.io/v3/15e578df-212d-4b96-8a8f-641dac982436

  getSalesExpectedDeliveryLastUpdated = (params?: token) =>
    // API.post({uri:'/sales/expected-delivery/last-updated'});
    API.post({
      uri:
        process.env.REACT_APP_ENV === 'SIT' ? '15e578df-212d-4b96-8a8f-641dac982436' : 'expected-delivery/last-updated',
      params,
    });

  // Success         https://run.mocky.io/v3/15e578df-212d-4b96-8a8f-641dac982436

  getDealerExpectedDeliveryLastUpdated = (params?: token) =>
    // API.post({uri:'/dealer/expected-delivery/last-updated'});
    API.post({
      uri:
        process.env.REACT_APP_ENV === 'SIT' ? '15e578df-212d-4b96-8a8f-641dac982436' : 'expected-delivery/last-updated',
      params,
    });

  // Success         https://run.mocky.io/v3/15e578df-212d-4b96-8a8f-641dac982436

  getLateDeliveryLastUpdated = (params?: token) =>
    // API.post({uri:'/sales/late-delivery/last-updated'});
    API.post({
      uri: process.env.REACT_APP_ENV === 'SIT' ? '15e578df-212d-4b96-8a8f-641dac982436' : 'late-delivery/last-updated',
      params,
    });

  // Success         https://run.mocky.io/v3/15e578df-212d-4b96-8a8f-641dac982436

  getLateProductionLastUpdated = (params?: token) =>
    // API.post({uri:'/sales/late-production/last-updated'});
    API.post({
      uri:
        process.env.REACT_APP_ENV === 'SIT' ? '15e578df-212d-4b96-8a8f-641dac982436' : 'late-production/last-updated',
      params,
    });

  // Success         https://run.mocky.io/v3/15e578df-212d-4b96-8a8f-641dac982436
}

export const LastUpdatedService = new LastUpdatedServiceClass();

import API from '../api';
import { filterPayload } from '../type';

class OwnerSaleServiceClass {
  getOwnerCodes = (params: filterPayload) =>
    // API.post({uri:'/sales/owner-codes', params});
    API.post({
      uri: process.env.REACT_APP_ENV === 'SIT' ? 'd4f7c649-b8f4-448e-b234-28171ac12391' : 'owner-codes',
      params,
    });
  // Success         https://run.mocky.io/v3/d4f7c649-b8f4-448e-b234-28171ac12391

  getDealers = (params: filterPayload) =>
    // API.post({uri:'/sales/dealers', params});
    API.post({
      uri: process.env.REACT_APP_ENV === 'SIT' ? 'acce4b0d-ea0e-42fe-99a8-28c243b53ade' : 'dealers',
      params,
    });
  // Success         https://run.mocky.io/v3/acce4b0d-ea0e-42fe-99a8-28c243b53ade
}

export const OwnerSaleService = new OwnerSaleServiceClass();

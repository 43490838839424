import React, { useRef, useState } from 'react';
import { Pagination, Stack } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import './TableCustom.scss';

export interface ITableCustomProps {
  snapColumn?: boolean;
  columns: Column[];
  data: any[];
  classNames?: string[];
  widthTable?: number;
  pagination?: boolean;
  heightTable?: number;
  lastUpdateText?: string;
  cellNopadding?: boolean;
  page?: number;
  count?: number;
  totalPage?: number;
  onChangePage?: (event: any, value: any) => void;
}

export interface Column {
  id: string;
  label: string;
  align?: 'inherit' | 'left' | 'center' | 'right' | 'justify';
  width?: string | number;
  styleColumn?: React.CSSProperties;
  customRow?: (data: any) => React.ReactNode;
}

export interface RowData {
  [key: string]: any;
}

export const TableCustom = (props: ITableCustomProps) => {
  const {
    snapColumn,
    columns,
    data,
    classNames,
    lastUpdateText,
    widthTable,
    heightTable,
    cellNopadding,
    pagination,
    page,
    totalPage,
    onChangePage,
  } = props;
  return (
    <div
      className={'card-container ' + classNames?.join(' ')}
      style={{ minWidth: widthTable ? widthTable : '100%', overflow: 'hidden' }}
    >
      {/* <Paper sx={{ width: '100%', overflow: 'hidden' }}> */}
      <Stack sx={{ justifyContent: 'space-between', flexDirection: 'row', paddingBottom: '12px' }}>
        <div>{lastUpdateText ? lastUpdateText : ''}</div>
        <Stack>
          {pagination ? (
            <Pagination
              defaultPage={1}
              page={page}
              count={totalPage}
              onChange={onChangePage}
              size="small"
              boundaryCount={2}
            />
          ) : null}
        </Stack>
      </Stack>
      <TableContainer
        component={Paper}
        sx={{ maxHeight: heightTable ? heightTable : 400, maxWidth: widthTable ? widthTable : 400 }}
        className="body"
      >
        <Table
          sx={{ maxHeight: heightTable ? heightTable : 400 }}
          stickyHeader
          className={snapColumn ? 'snapColumn' : ''}
        >
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  style={{
                    minWidth: column.width,
                    width: column.width,
                    maxWidth: column.width,
                    ...column.styleColumn,
                  }}
                  width={column.width}
                  align={column.align}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, rowIndex) => (
              <TableRow key={rowIndex}>
                {columns.map((column, indexChild) => (
                  <TableCell
                    key={column.id}
                    style={{
                      padding: cellNopadding ? '0' : '16',
                      minWidth: column.width,
                      width: column.width,
                      maxWidth: column.width,
                      background: row['header'] === true ? '#ececec' : 'white',
                      fontWeight: indexChild === 0 && row['header'] === true ? '800' : '400',
                    }}
                  >
                    {column.customRow ? column.customRow(row[column.id]) : row[column.id]}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {/* </Paper> */}
    </div>
  );
};

export default TableCustom;

// authStore.ts
import moment from 'moment';
import create from 'zustand';

const currMonth = Number(moment().format('MM'));
const currYear = Number(moment().year());

interface DatePeriodeStore {
  startMonth: any;
  startYear: any;
  endMonth: any;
  endYear: any;
  setStartMonthPeriode: (startMonth: any, startYear: any) => void;
  setEndYearPeriode: (endMonth: any, endYear: any) => void;
}

export const useDatePeriodeStore = create<DatePeriodeStore>((set, get) => ({
  startMonth: currMonth,
  startYear: currYear,
  endMonth: currMonth,
  endYear: currYear,

  setStartMonthPeriode: (startMonth, endMonth) => {
    // your logic
    set({ startMonth, endMonth });
  },

  setEndYearPeriode: (startYear, endYear) => {
    // your logic
    set({ startYear, endYear });
  },
}));

import React, { useState } from 'react';
import { Stack } from '@mui/material';
import ExampleTableCustom from '../../components/Table/ExampleTableCustom';
import { ExampleStackedBarChart } from '../../components/Chart/StackedBarChart/ExampleStackedBarChart';
import { ExampleLineChart } from '../../components/Chart/LineChart/ExampleLineChart';
import TextStyle from '../../components/textStyle/TextStyle';
import Component from '../../components/component/Component';
import { GroupedLabelPeriod } from '../../components/groupedLabelPeriod/groupedLabelPeriod';
import DrawerSidebarDatePeriod from '../../components/drawerSidebarDate/drawerSidebarDatePeriode';
import DrawerSidebarDateNew from '../../components/drawerSidebarDate/drawerSidebarDateNew';
import DrawerSidebarDate from '../../components/drawerSidebarDate/drawerSidebarDate';
import moment from 'moment';

export const DemoScreen = () => {
  const currMonth = Number(moment().format('MM'));
  const currYear = Number(moment().year());

  const defaultOnlyMonth = currMonth;
  const defaultOnlyYear = currYear;
  const [openStartPeriod, seOpenStartPeriod] = useState<boolean>(false);
  const [openEndPeriod, setOpenEndPeriod] = useState<boolean>(false);
  const mockList3 = [{ startMonth: 2, startYear: 2022, endMonth: 8, endYear: 2023 }];
  const [selectedStartMonth, setSelectedStartMonth] = React.useState(defaultOnlyMonth);
  const [selectedStartYear, setSelectedStartYear] = React.useState(defaultOnlyYear);
  const [selectedEndMonth, setSelectedEndMonth] = React.useState(defaultOnlyMonth);
  const [selectedEndYear, setSelectedEndYear] = React.useState(defaultOnlyYear);
  const handleSelectionPeriodMonthClick = (start: any, end: any) => {
    setSelectedStartMonth(start);
    setSelectedEndMonth(end);
  };
  const handleSelectionPeriodYearClick = (start: any, end: any) => {
    setSelectedStartYear(start);
    setSelectedEndYear(end);
  };

  return (
    <Stack sx={{ padding: '70px', flexDirection: 'column' }}>
      {/* <ExampleStackedBarChart />
      <ExampleTableCustom /> */}
      <GroupedLabelPeriod
        titleLabel="Start Date"
        isEndMonthYear={false}
        selectedStartMonth={selectedStartMonth}
        selectedStartYear={selectedStartYear}
        selectedEndMonth={selectedEndMonth}
        selectedEndYear={selectedEndYear}
        onClick={() => seOpenStartPeriod(!openStartPeriod)}
      />
      <GroupedLabelPeriod
        titleLabel="End Date"
        isEndMonthYear={true}
        selectedStartMonth={selectedStartMonth}
        selectedStartYear={selectedStartYear}
        selectedEndMonth={selectedEndMonth}
        selectedEndYear={selectedEndYear}
        onClick={() => setOpenEndPeriod(!openEndPeriod)}
      />

      {/* <ExampleStackedBarChart />
      <ExampleTableCustom /> */}
      <div>DemoScreen</div>
      <Stack>
        <ExampleLineChart />
        <TextStyle />
        <Component />
      </Stack>

      <DrawerSidebarDateNew
        titleLabel={'Start'}
        typeOfData={'datePeriod'}
        isDrawerOpen={openStartPeriod}
        handleCloseDrawer={() => setOpenEndPeriod(false)}
        isBackdropOpen={openStartPeriod}
        selectedStartMonth={selectedStartMonth}
        selectedStartYear={selectedStartYear}
        selectedEndMonth={selectedEndMonth}
        selectedEndYear={selectedEndYear}
        handleSelectionMonthClick={handleSelectionPeriodMonthClick}
        handleSelectionYearClick={handleSelectionPeriodYearClick}
        isEndMonthYear={false}
        isStartMonthYear={true}
        handleApply={() => {
          seOpenStartPeriod(false);
        }}
      />

      <DrawerSidebarDateNew
        titleLabel={'End'}
        typeOfData={'datePeriod'}
        isDrawerOpen={openEndPeriod}
        handleCloseDrawer={() => setOpenEndPeriod(false)}
        isBackdropOpen={openEndPeriod}
        selectedStartMonth={selectedStartMonth}
        selectedStartYear={selectedStartYear}
        selectedEndMonth={selectedEndMonth}
        selectedEndYear={selectedEndYear}
        handleSelectionMonthClick={handleSelectionPeriodMonthClick}
        handleSelectionYearClick={handleSelectionPeriodYearClick}
        isEndMonthYear={true}
        isStartMonthYear={false}
        handleApply={() => {
          setOpenEndPeriod(false);
        }}
      />
      {/* <DrawerSidebarDatePeriod
        titleLabel={'Start'}
        isDrawerOpen={openStartPeriod}
        handleCloseDrawer={() => seOpenStartPeriod(false)}
        handleApply={() => {
          seOpenStartPeriod(openStartPeriod);
        }}
      />
      <DrawerSidebarDatePeriod
        titleLabel={'End'}
        isDrawerOpen={openEndPeriod}
        handleCloseDrawer={() => setOpenEndPeriod(false)}
        handleApply={() => {
          setOpenEndPeriod(openEndPeriod);
        }}
      /> */}
    </Stack>
  );
};

import React, { useEffect } from 'react';
import './groupedLabel.scss';
import { changeMonthDisplay } from './mock';
import DrawerSidebar from '../drawerSidebar/drawerSidebar';
import DrawerSidebarMultiple from '../drawerSidebarMultiple/drawerSidebarMultiple';
import DrawerSidebarDate from '../drawerSidebarDate/drawerSidebarDate';
import { KeyboardArrowDown } from '@mui/icons-material';
import { changeValuesToLabel } from '../../helper/utils-helper';
import moment from 'moment';
type NewData = {
  label: string;
  value: string;
};

type DataTypeMap = {
  single: any;
  multiple: { product_code: string }[];
  datePeriod: {
    startMonth: number;
    startYear: number;
    endMonth: number;
    endYear: number;
  }[];
  singleNew: { label: string; value: string }[];
  date: {
    month: number;
    year: number;
  }[];
};
// Define a generic data structure
interface DataItem {
  label: string;
  value: string;
}
const colorData: DataItem[] = [
  {
    label: 'ดำ',
    value: '0300',
  },
  {
    label: 'เขียว',
    value: '0600',
  },
  {
    label: 'เทา',
    value: '0700',
  },
];

interface GroupedLabelProps<T extends keyof DataTypeMap> {
  titleLabel: string;
  dataList: DataTypeMap[T];
  typeOfData?: 'single' | 'multiple' | 'datePeriod' | 'date';
  disabled?: boolean;
  isStartMonthYear?: boolean;
  isEndMonthYear?: boolean;
  isOnlyMonthYear?: boolean;
  singleNewData?: NewData[];
  onChangeDate?: (value: any) => void;
  isSelectFilter?: (key: string, value: any) => void;
  isLockMonthCurrent?: boolean;
  // label:any;
}

export default function GroupedLabel<T extends keyof DataTypeMap>({
  titleLabel,
  dataList,
  typeOfData,
  disabled,
  isStartMonthYear,
  isEndMonthYear,
  isOnlyMonthYear,
  isSelectFilter,
  singleNewData,
  isLockMonthCurrent,
}: GroupedLabelProps<T>) {
  const [openDrawer, setOpenDrawer] = React.useState(false);

  const multipleList = dataList as DataTypeMap['multiple'];
  const singleList = dataList as DataTypeMap['single'];
  const defaultWord = typeOfData === 'multiple' ? colorData[0]?.label : singleList ? singleList[0].label : null;
  const [selectedValue, setSelectedValue] = React.useState(defaultWord);

  const currMonth = Number(moment().format('MM'));
  const currYear = Number(moment().year());

  // const monthYears = dataList as DataTypeMap['date'];
  // const startEndYears = dataList as DataTypeMap['datePeriod'];
  const defaultOnlyMonth = typeOfData === 'date' ? currMonth - 1 : currMonth;
  const defaultOnlyYear = currYear;
  const [selectedOnlyMonth, setSelectedOnlyMonth] = React.useState(defaultOnlyMonth);
  const [selectedOnlyYear, setSelectedOnlyYear] = React.useState(defaultOnlyYear);
  // const defaultMonth = isEndMonthYear ? startEndYears[0].endMonth : startEndYears[0].startMonth;
  // const defaultYear = isEndMonthYear ? startEndYears[0].endYear : startEndYears[0].startYear;
  const [selectedStartMonth, setSelectedStartMonth] = React.useState(defaultOnlyMonth);
  const [selectedStartYear, setSelectedStartYear] = React.useState(defaultOnlyYear);
  const [selectedEndMonth, setSelectedEndMonth] = React.useState(defaultOnlyMonth);
  const [selectedEndYear, setSelectedEndYear] = React.useState(defaultOnlyYear);
  const [textLabel, setTextLabel] = React.useState(defaultWord);
  const [anotherMonth, setAnotherMonth] = React.useState(defaultOnlyMonth);
  const [anotherYear, setAnotherYear] = React.useState(defaultOnlyYear);

  // useEffect(() => {}, [defaultOnlyMonth]);

  useEffect(() => {
    const monthYear = { month: selectedOnlyMonth, year: selectedOnlyYear };
    !!isSelectFilter && isSelectFilter('monthYear', monthYear);
  }, [selectedOnlyMonth, selectedOnlyYear]);

  const handleOpenDrawer = () => {
    setOpenDrawer(true);
  };
  const handleCloseDrawer = () => {
    setOpenDrawer(false);
    handleCloseBackdrop();
  };

  const [openBackdrop, setOpenBackdrop] = React.useState(false);
  const handleCloseBackdrop = () => {
    setOpenBackdrop(false);
  };
  const handleOpenBackdrop = () => {
    // console.warn(isLockMonthCurrent);
    setOpenBackdrop(true);
  };

  const handleSelectionClick = (value: any) => {
    const multivalue = changeValuesToLabel([value], dataList);

    if (typeOfData === 'multiple') {
      setSelectedValue(value.length > 1 ? multivalue + '...' : multivalue);
    } else if (typeOfData === 'single') {
      setTextLabel(multivalue); // Update the textLabel state for single selection
      // console.warn('singlevalue');
    } else {
      setSelectedValue(multivalue);
    }
  };

  const handleSelectionMonthClick = (value: any) => {
    setSelectedOnlyMonth(value);
  };
  const handleSelectionYearClick = (value: any) => {
    setSelectedOnlyYear(value);
  };

  const handleSelectionPeriodMonthClick = (start: any, end: any) => {
    setSelectedStartMonth(start);
    setSelectedEndMonth(end);
    isStartMonthYear ? displayValueMonth(end) : displayValueMonth(start);
  };
  const handleSelectionPeriodYearClick = (start: any, end: any) => {
    setSelectedStartYear(start);
    setSelectedEndYear(end);
    const period = { start: start, end: end };
    //  isStartMonthYear ? onChangeDate(period) : onChangeDate(period);
    isStartMonthYear ? displayValueYear(end) : displayValueYear(start);
  };
  const handleSingleClick = (value: any) => {
    const multivalue = changeValuesToLabel(value, colorData);
    typeOfData === 'multiple'
      ? value.length > 1
        ? setSelectedValue(multivalue + '...')
        : setSelectedValue(multivalue)
      : setSelectedValue(value);
  };

  // const handleSelectionPeriodYearClick = (start: any, end: any) => {
  //   setSelectedStartYear(start);
  //   setSelectedEndYear(end);
  //   const period = { start: start, end: end };

  //   if (onChangeDate) {
  //     isStartMonthYear ? (!!period ? onChangeDate(period) : onChangeDate(null)) : onChangeDate(period);
  //   }

  //   isStartMonthYear ? displayValueYear(end) : displayValueYear(start);
  // };

  const handleApply = () => {
    handleCloseDrawer();
    handleCloseBackdrop();
  };

  const displayValueMonth = (value: any) => {
    if (isStartMonthYear) {
      setSelectedEndMonth(value);
    } else {
      setSelectedStartMonth(value);
    }
  };

  const displayValueYear = (value: any) => {
    if (isStartMonthYear) {
      setSelectedEndYear(value);
    } else {
      setSelectedStartYear(value);
    }
  };
  return (
    <>
      {disabled ? (
        <fieldset id="dropdown-disabled">
          <legend id="dropdown-label-disabled">{titleLabel}</legend>
          {selectedValue} <KeyboardArrowDown id="dropdown-icon-disabled" />
        </fieldset>
      ) : (
        <fieldset
          id="dropdown"
          onClick={() => {
            handleOpenDrawer();
            handleOpenBackdrop();
          }}
        >
          <legend id="dropdown-label">{titleLabel}</legend>
          {typeOfData === 'date' || typeOfData === 'datePeriod' ? (
            <>
              {isOnlyMonthYear && (
                <>
                  {changeMonthDisplay(selectedOnlyMonth)} {selectedOnlyYear}
                </>
              )}
              {isStartMonthYear && (
                <>
                  {changeMonthDisplay(selectedStartMonth)} {selectedStartYear}
                </>
              )}
              {isEndMonthYear && (
                <>
                  {changeMonthDisplay(selectedEndMonth)} {selectedEndYear}
                </>
              )}
            </>
          ) : (
            <>{selectedValue}</>
          )}{' '}
          <KeyboardArrowDown id="dropdown-icon" />
        </fieldset>
      )}
      {typeOfData === 'multiple' ? (
        <DrawerSidebarMultiple
          titleLabel={titleLabel}
          dataList={colorData}
          isDrawerOpen={openDrawer}
          handleCloseDrawer={handleCloseDrawer}
          isBackdropOpen={openBackdrop}
          handleSelectionClick={handleSelectionClick}
        />
      ) : typeOfData === 'datePeriod' ? (
        <DrawerSidebarDate
          titleLabel={titleLabel}
          dataList={dataList as DataTypeMap['datePeriod']}
          typeOfData={typeOfData}
          isDrawerOpen={openDrawer}
          handleCloseDrawer={handleCloseDrawer}
          isBackdropOpen={openBackdrop}
          selectedStartMonth={selectedStartMonth}
          selectedStartYear={selectedStartYear}
          selectedEndMonth={selectedEndMonth}
          selectedEndYear={selectedEndYear}
          handleSelectionMonthClick={handleSelectionPeriodMonthClick}
          handleSelectionYearClick={handleSelectionPeriodYearClick}
          handleApply={handleApply}
          isStartMonthYear={isStartMonthYear}
          isEndMonthYear={isEndMonthYear}
          isOnlyMonthYear={isOnlyMonthYear}
          isLockMonthCurrent={false}
        />
      ) : typeOfData === 'date' ? (
        <DrawerSidebarDate
          titleLabel={titleLabel}
          dataList={dataList as DataTypeMap['date']}
          typeOfData={typeOfData}
          isDrawerOpen={openDrawer}
          handleCloseDrawer={handleCloseDrawer}
          isBackdropOpen={openBackdrop}
          selectedOnlyMonth={selectedOnlyMonth}
          selectedOnlyYear={selectedOnlyYear}
          handleSelectionMonthClick={handleSelectionMonthClick}
          handleSelectionYearClick={handleSelectionYearClick}
          handleApply={handleApply}
          isStartMonthYear={isStartMonthYear}
          isEndMonthYear={isEndMonthYear}
          isOnlyMonthYear={isOnlyMonthYear}
          isSelectFilter={isSelectFilter}
          isLockMonthCurrent={isLockMonthCurrent || false}
        />
      ) : (
        // <DrawerSidebar
        //   titleLabel={titleLabel}
        //   dataList={dataList as DataTypeMap['single']}
        //   isDrawerOpen={openDrawer}
        //   handleCloseDrawer={handleCloseDrawer}
        //   isBackdropOpen={openBackdrop}
        //   selectedValue={selectedValue}
        //   handleSelectionClick={handleSelectionClick}
        //   handleApply={handleApply}
        // />
        <DrawerSidebar
          titleLabel="Region" // Pass the correct titleLabel here
          dataList={singleNewData as DataTypeMap['singleNew']}
          isDrawerOpen={openDrawer}
          handleCloseDrawer={handleCloseDrawer}
          isBackdropOpen={openBackdrop}
          selectedValue={selectedValue}
          handleSelectionClick={(value) => handleSelectionClick(value)} // Pass "Region" here
          handleApply={handleApply}
        />
      )}
    </>
  );
}

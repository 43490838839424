import { Stack } from '@mui/material';
import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import './CustomLineChart.scss'; // Import your CSS file for styling
import { warn } from 'console';
import CustomXAxisTick from '../StackedBarChart/CustomXAxisTick';

export interface DataPoint {
  name: string;
  value: number;
}

interface LineChartProps {
  data: DataPoint[];
  colorLine?: string;
  unit?: string;
  lateScreen?: boolean;
}

export const CustomLineChart: React.FC<LineChartProps> = ({ data, unit, lateScreen }) => {
  const numberOfDataPoints = data.length;
  const minWidth = 100; // Set a minimum width for small datasets
  const maxWidth = 10000; // Set a maximum width for large datasets
  const dynamicChartWidth = Math.min(
    Math.max(minWidth, numberOfDataPoints * 40), // Adjust the multiplier as needed
    maxWidth,
  );

  // console.warn('value', data);

  return (
    <Stack className={'containerLineChart'}>
      <ResponsiveContainer width="100%" height={390}>
        <LineChart
          width={dynamicChartWidth * 4}
          height={300}
          data={data}
          margin={{
            top: 40,
            right: 40,
            left: 30,
            bottom: 30,
          }}
        >
          <CartesianGrid horizontal={true} vertical={false} />
          <XAxis
            dataKey="name"
            className="botName"
            tickMargin={12}
            tick={(props: any) => <CustomXAxisTick {...props} lateScreen={lateScreen} />}
          />
          <YAxis unit={unit ? unit : ''} />
          <Tooltip />

          <Line
            type="linear"
            dataKey="value"
            stroke="red"
            width={20}
            style={{ strokeWidth: 3 }}
            animationDuration={0}
            isAnimationActive={false}
          />
        </LineChart>
      </ResponsiveContainer>
    </Stack>
  );
};

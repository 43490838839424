export enum UserRole {
  DELIVERY_REGION = 'delivery_status.by_region',
  DELIVERY_AREA = 'delivery_status.by_area',
  DELIVERY_DEALER = 'delivery_status.by_dealer',
  EXPECTED_NATION = 'expected_delivery.nation_wide',
  EXPECTED_REGION = 'expected_delivery.region',
  EXPECTED_AREA = 'expected_delivery.area',
  LATE_DELIVERY = 'late_delivery',
  LATE_PRODUCTION = 'late_production',
}

export interface filterPayload extends token {
  regionCode?: number | any;
  subregionCodes?: string | any;
  areaCodes?: string | any;
  provinceCodes?: number | number[] | any;
  ownerCodes?: string | any;
  dealerCodes?: string | any;
  modelNames?: string | any;
  modelCodes?: string | any;
  productCodes?: string | any;
  colorCodes?: string | any;
  month?: string | any;
  year?: string | any;
  startMonth?: string | any;
  startYear?: string | any;
  endMonth?: string | any;
  endYear?: string | any;
  limit?: number | any;
  offSet?: number | any;
}

export interface filterDealer extends token {
  regionCode: string;
  subregionCodes: string;
  areaCodes: string;
  provinceCodes: string;
  ownerCodes: string;
}

export interface UserData {
  permissions: UserRole[];
}

export type token = {
  salesToken?: any;
  yesToken?: any;
};

import React from 'react';
import { Button, Stack, Typography } from '@mui/material';
import imgLogo from '../../assets/img/yamaha-logo.png';
import './ErrorScreen.scss';
import { useLocation, useNavigate } from 'react-router-dom';

export const ErrorScreen = () => {
  const location = useLocation();
  const { errorMessage, errtokenExpire, originalPath } = location?.state || '';
  const navigate = useNavigate();
  // console.warn(errorMessage);
  const handleErrScreen = () => {
    if (errtokenExpire) {
      return window.location.replace('https://www.google.com/');
    }
    if (originalPath) {
      // console.warn('originalPath', originalPath);
      return navigate(originalPath);
    }
    return navigate(-1);
  };

  return (
    <Stack sx={{ padding: '70px', flexDirection: 'column' }} className="mainContainer">
      <img alt="" width={200} height={80} src={imgLogo} className="" />
      <Typography variant="h3" className="textHeader">
        {errtokenExpire ? 'Session expired' : 'Error'}
      </Typography>
      <Typography variant="h6" className="textSubHeader">
        {errtokenExpire ? 'Please Login again' : 'Not Found'}
      </Typography>
      <span style={{ letterSpacing: 1 }}>
        <b>Remark :</b> {errorMessage || ''}
      </span>
      <Button variant="contained" className="btnOK" onClick={handleErrScreen}>
        OK
      </Button>
    </Stack>
  );
};

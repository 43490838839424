export const monthList = [
  { id: 1, monthName: 'JAN' },
  { id: 2, monthName: 'FEB' },
  { id: 3, monthName: 'MAR' },
  { id: 4, monthName: 'APR' },
  { id: 5, monthName: 'MAY' },
  { id: 6, monthName: 'JUN' },
  { id: 7, monthName: 'JUL' },
  { id: 8, monthName: 'AUG' },
  { id: 9, monthName: 'SEP' },
  { id: 10, monthName: 'OCT' },
  { id: 11, monthName: 'NOV' },
  { id: 12, monthName: 'DEC' },
];

export const changeMonthDisplay = (value: any) => {
  const monthObj = monthList.find((el) => el.id === value);
  return monthObj ? monthObj.monthName : '';
};

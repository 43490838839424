import React, { FC } from 'react';
import './CustomXAxisTick.scss';

interface CustomXAxisTickProps {
  x: number;
  y: number;
  payload: { value: string };
  lateScreen?: boolean;
}

const CustomXAxisTick: FC<CustomXAxisTickProps> = ({ x, y, payload, lateScreen }) => {
  const maxLabelWidth = 120;
  const maxLines = 4;
  const dy = 26; // Vertical distance between lines

  const labelStyle: React.CSSProperties = {
    fontSize: 12,
    lineHeight: '1.1em', // Adjust line height for better readability
  };

  const lines = payload?.value || '';
  const formattedValue = payload?.value.split(' ').join('<br/>');

  return (
    <g transform={`translate(${x},${y + 5})`} fill="yellow">
      <foreignObject x={-maxLabelWidth / 2} y={-10} width={maxLabelWidth * 1.2} height={maxLines * dy}>
        <div style={labelStyle}>
          {!lateScreen ? (
            <div key={x + 1} className="nameBusiness" style={{ marginLeft: lines.length - 36 }}>
              {lines}
            </div>
          ) : (
            <div key={x + 1} className="nameLateSceen" style={{ marginLeft: lines.length - 36 }}>
              {<span dangerouslySetInnerHTML={{ __html: formattedValue }} />}
            </div>
          )}
        </div>
      </foreignObject>
    </g>
  );
};

export default CustomXAxisTick;

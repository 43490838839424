import React from 'react';
import { Column } from '../../../components/Table/TableCustom';
import { numberWithCommas } from '../../../helper/utils-helper';

export type ExpectedDeatails = {
  productCode: any;
  productName: any;
  color: any;
  qty: any;
  expectedDeliveryDate: any;
  totalAmount: any;
};

export const mockColumnDetails: Column[] = [
  {
    id: 'ownerCode',
    label: 'Owner Code',
    styleColumn: {
      backgroundColor: '#fa230d',
      color: 'white',
      border: 'solid #fa230d',
    },
    align: 'center',
    width: '160px',
    customRow: (value: any) => <div style={{ textAlign: 'left', maxWidth: '160px' }}>{value}</div>,
  },
  {
    id: 'dealerName',
    label: 'Dealer Name',
    styleColumn: {
      backgroundColor: '#fa230d',
      color: 'white',
      border: 'solid #fa230d',
    },
    align: 'center',
    width: '190px',
    customRow: (value: any) => <div style={{ textAlign: 'left', maxWidth: '190px' }}>{value}</div>,
  },
  {
    id: 'productName',
    label: 'Product Name',
    styleColumn: {
      backgroundColor: '#fa230d',
      color: 'white',
      border: 'solid #fa230d',
    },
    align: 'center',
    width: '190px',
    customRow: (value: any) => <div style={{ textAlign: 'left', maxWidth: '190px' }}>{value}</div>,
  },
  {
    id: 'color',
    label: 'Color',
    styleColumn: { backgroundColor: '#fa230d', color: 'white', border: 'solid #fa230d' },
    width: '70px',
    align: 'center',
    customRow: (value: any) => <div style={{ textAlign: 'center' }}>{value}</div>,
  },
  {
    id: 'qty',
    label: 'Quantity',
    styleColumn: { backgroundColor: '#fa230d', color: 'white', border: 'solid #fa230d' },
    width: '70px',
    align: 'center',
    customRow: (value: any) => <div style={{ textAlign: 'center' }}>{value}</div>,
  },
  {
    id: 'totalAmount',
    label: 'Total Amount (THB)',
    styleColumn: {
      backgroundColor: '#fa230d',
      color: 'white',
      border: 'solid #fa230d',
    },
    align: 'center',
    width: '120px',
    customRow: (value: any) => <div style={{ textAlign: 'right' }}>{numberWithCommas(value)}</div>,
  },
];

export const mockColumn: Column[] = [
  {
    id: 'productName',
    label: 'Product Name',
    styleColumn: {
      backgroundColor: '#fa230d',
      color: 'white',
      border: 'solid #fa230d',
    },
    align: 'center',
    width: '160px',
    customRow: (value: any) => <div style={{ textAlign: 'left', maxWidth: '140px' }}>{value}</div>,
  },
  {
    id: 'color',
    label: 'Color',
    styleColumn: { backgroundColor: '#fa230d', color: 'white', border: 'solid #fa230d' },
    width: '70px',
    align: 'center',
    customRow: (value: any) => <div style={{ textAlign: 'center' }}>{value}</div>,
  },
  {
    id: 'qty',
    label: 'Quantity',
    styleColumn: { backgroundColor: '#fa230d', color: 'white', border: 'solid #fa230d' },
    width: '70px',
    align: 'center',
    customRow: (value: any) => <div style={{ textAlign: 'center' }}>{value}</div>,
  },
  {
    id: 'totalAmount',
    label: 'Total Amount (THB)',
    styleColumn: {
      backgroundColor: '#fa230d',
      color: 'white',
      border: 'solid #fa230d',
    },
    align: 'center',
    width: '120px',
    customRow: (value: any) => <div style={{ textAlign: 'right' }}>{numberWithCommas(value)}</div>,
  },
];
export const mockDetailsColumn: Column[] = [
  {
    id: 'ownerCode',
    label: 'Owner Code ',
    styleColumn: {
      backgroundColor: '#fa230d',
      color: 'white',
      border: 'solid #fa230d',
    },
    width: 70,
    align: 'right',
    customRow: (ownerCode: any) => <div style={{ textAlign: 'right' }}>{ownerCode}</div>,
  },
  {
    id: 'dealerName',
    label: 'Dealer Name',
    styleColumn: {
      backgroundColor: '#fa230d',
      color: 'white',
      border: 'solid #fa230d',
    },
    width: 90,
    align: 'right',
    customRow: (age: any) => <div style={{ textAlign: 'left' }}>{age}</div>,
  },

  {
    id: 'productName',
    label: 'Product Name',
    styleColumn: {
      backgroundColor: '#fa230d',
      color: 'white',
      border: 'solid #fa230d',
    },
    width: 140,
    align: 'right',
    customRow: (age: any) => <div style={{ textAlign: 'left' }}>{age}</div>,
  },
  {
    id: 'productCode',
    label: 'Product Code ',
    styleColumn: {
      backgroundColor: '#fa230d',
      color: 'white',
      border: 'solid #fa230d',
    },
    width: 120,
    align: 'right',
    customRow: (age: any) => <div style={{ textAlign: 'right' }}>{age}</div>,
  },
  {
    id: 'color',
    label: 'Color',
    styleColumn: {
      backgroundColor: '#fa230d',
      color: 'white',
      textAlign: 'right',
      border: 'solid #fa230d',
    },
    width: 70,
    align: 'center',
    customRow: (age: any) => <div style={{ textAlign: 'center' }}>{age}</div>,
  },
  {
    id: 'qty',
    label: 'Qty',
    styleColumn: {
      backgroundColor: '#fa230d',
      color: 'white',
      textAlign: 'center',
      border: 'solid #fa230d',
    },
    width: 70,
    align: 'center',
    customRow: (age: any) => <div style={{ textAlign: 'center' }}>{age}</div>,
  },
  {
    id: 'expectedDeliveryDate',
    label: 'Expected Deliver Date',
    styleColumn: {
      backgroundColor: '#fa230d',
      color: 'white',
      border: 'solid #fa230d',
    },
    width: 130,
    align: 'right',
    customRow: (age: any) => <div style={{ textAlign: 'right' }}>{age}</div>,
  },
  {
    id: 'totalAmount',
    label: 'Total Amount (THB)',
    styleColumn: {
      backgroundColor: '#fa230d',
      color: 'white',
      textAlign: 'center',
      border: 'solid #fa230d',
      padding: 0,
    },
    width: 100,
    align: 'center',
    customRow: (age: any) => <div style={{ textAlign: 'right' }}>{age}</div>,
  },
];

const testMockBar = {
  mcDelivered: 0,
  mcInTransit: 10,
  mcWaitForShipping: 10,
  mcRejected: 5,
  baseMcOrdered: 5,
  canceledMcOrdered: 5,
  mcInProduction: 2,
};
const testMockTable = [
  {
    expectedDeliveryDate: 'JAN 1- 5 , 2020',
    totalQty: 4,
    totalAmount: 400,
    products: [
      {
        productCode: 'JAN 1- 5 , 2020',
        productName: 'FIN1 : Elect',
        color: null,
        qty: 4,
        totalAmount: 400,
      },
      {
        productCode: '111',
        productName: 'FIN1 : Elect',
        color: 'แดง',
        qty: 5,
        totalAmount: 120,
      },
      {
        productCode: '222',
        productName: 'FIN2 : Elect',
        color: 'แดง',
        qty: 5,
        totalAmount: 120,
      },
    ],
  },
  {
    expectedDeliveryDate: 'feb 1- 5 , 2020',
    totalQty: 4,
    totalAmount: 400,
    products: [
      {
        productCode: 'feb 1- 5 , 2020',
        productName: 'FIN1 : Elect',
        color: null,
        qty: 4,
        totalAmount: 400,
      },
      {
        productCode: '101',
        productName: 'yamam : Elect',
        color: 'แดง',
        qty: 5,
        totalAmount: 120,
      },
      {
        productCode: '102',
        productName: 'yamasha : Elect',
        color: 'แดง',
        qty: 5,
        totalAmount: 120,
      },
      {
        productCode: '103',
        productName: 'yamasha : Elect',
        color: 'แดง',
        qty: 5,
        totalAmount: 120,
      },
    ],
  },
];

export const mockTableDataDetails = [
  {
    productCode: '111.prod_cd',
    productName: 'AlloOutput.prod_nm',
    color: 'p.color_nm',
    qty: ' AlloOutput.qty',
    expectedDeliveryDate: 'AlloOutput.tym_eta (format YYYYMMDD - YYYYMMDD)',
    totalAmount: 'AlloOutput.qty * PriceMaster.stand_sales_price',
  },
  {
    productCode: '222.prod_cd',
    productName: 'AlloOutput.prod_nm',
    color: 'p.color_nm',
    qty: ' AlloOutput.qty',
    expectedDeliveryDate: 'AlloOutput.tym_eta (format YYYYMMDD - YYYYMMDD)',
    totalAmount: 'AlloOutput.qty * PriceMaster.stand_sales_price',
  },
  {
    productCode: '333.prod_cd',
    productName: 'AlloOutput.prod_nm',
    color: 'p.color_nm',
    qty: ' AlloOutput.qty',
    expectedDeliveryDate: 'AlloOutput.tym_eta (format YYYYMMDD - YYYYMMDD)',
    totalAmount: 'AlloOutput.qty * PriceMaster.stand_sales_price',
  },
  {
    productCode: '444.prod_cd',
    productName: 'AlloOutput.prod_nm',
    color: 'p.color_nm',
    qty: ' AlloOutput.qty',
    expectedDeliveryDate: 'AlloOutput.tym_eta (format YYYYMMDD - YYYYMMDD)',
    totalAmount: 'AlloOutput.qty * PriceMaster.stand_sales_price',
  },
  {
    ownerCode: '12233',
    ownerName: 'owernanme',
    productCode: '555.prod_cd',
    productName: 'AlloOutput.prod_nm',
    color: 'p.color_nm',
    qty: ' AlloOutput.qty',
    expectedDeliveryDate: 'AlloOutput.tym_eta (format YYYYMMDD - YYYYMMDD)',
    totalAmount: 'AlloOutput.qty * PriceMaster.stand_sales_price',
  },
];

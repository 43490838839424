import React from 'react';
import { useDatePeriodeStore } from '../../state/Date-store';
import { changeMonthDisplay } from './mock';
import { KeyboardArrowDown } from '@mui/icons-material';

interface GroupedLabelPeriodProps {
  titleLabel: string;
  isEndMonthYear: boolean;
  selectedStartMonth: any;
  selectedStartYear: any;
  selectedEndMonth: any;
  selectedEndYear: any;

  onClick: () => void;
}

export const GroupedLabelPeriod: React.FC<GroupedLabelPeriodProps> = ({
  titleLabel,
  isEndMonthYear,
  onClick,
  selectedStartMonth,
  selectedStartYear,
  selectedEndMonth,
  selectedEndYear,
}: GroupedLabelPeriodProps) => {
  // Start Periode
  const valueStartMonth = useDatePeriodeStore.getState().startMonth; // for use store
  const valueStartYear = useDatePeriodeStore.getState().startYear; // for use store
  // End Periode
  const valueEndMonth = useDatePeriodeStore.getState().endMonth; // for use store
  const valueEndYear = useDatePeriodeStore.getState().endYear; // for use store

  return (
    <fieldset id="dropdown" onClick={onClick}>
      <legend id="dropdown-label">{titleLabel}</legend>
      {isEndMonthYear ? (
        <>
          {changeMonthDisplay(selectedEndMonth)} {selectedEndYear}
        </>
      ) : (
        <>
          {changeMonthDisplay(selectedStartMonth)} {selectedStartYear}
        </>
      )}

      <KeyboardArrowDown id="dropdown-icon" />
    </fieldset>
  );
};

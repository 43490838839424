export enum DELIVERY_STATUS_SUBMENU {
  BY_REGION = 'BY REGION',
  BY_AREA = 'BY AREA',
  BY_DEALER = 'BY DEALER',
}

export enum LATE_DELIVERY_STATUS_SUBMENU {
  DELIVERY = 'DELIVERY',
  PRODUCTION = 'PRODUCTION',
}

export enum PROFILE_ROLE {
  DM = 'DSM',
  SP = 'Sales Planning',
  GM = 'General Manager (GM)',
  HD = 'Group Manager (HD)',
  SC = 'Sales Consultant',
}
//
// Sales Planning
// General Manager (GM)
// Group Manager (HD)
// Sales Consultant

export interface DeliveryData {
  regionCode: string;
  regionName: string;
  mcDelivered: number;
  mcInTransit: number;
  mcWaitForShipping: number;
  mcRejected: number;
  baseMcOrdered: number;
  canceledMcOrdered: number;
  mcInProduction: number;
}

export const rootPageDelivery = ['delivery_status.by_region', 'delivery_status.by_area', 'delivery_status.by_dealer'];
export const rootPageExpected = ['expected_delivery.nation_wide', 'expected_delivery.region', 'expected_delivery.area'];
export const rootPageLateDelivery = ['late_delivery', 'late_production'];

import API from '../api';
import { filterPayload } from '../type';
class LateDeliveryServiceClass {
  getLateProductionTrend = (params: filterPayload) =>
    // API.post({uri:'/sales/late-production-trend', params});
    API.post({
      uri: process.env.REACT_APP_ENV === 'SIT' ? '3646fb0f-158b-45df-9d44-614eb6f12934' : 'late-production-trend',
      params,
    });
  // Success         https://run.mocky.io/v3/3646fb0f-158b-45df-9d44-614eb6f12934

  getLateProductionModel = (params: filterPayload) =>
    // API.post({uri:'/sales/late-production-by-model', params});
    API.post({
      uri: process.env.REACT_APP_ENV === 'SIT' ? '645b4ded-8860-4ca6-a2f5-a7c671dea556' : 'late-production-by-model',
      params,
    });
  // Success         https://run.mocky.io/v3/645b4ded-8860-4ca6-a2f5-a7c671dea556

  getLateProductionProduct = (params: filterPayload) =>
    // API.post({uri:'/sales/late-production-by-production-code', params});
    API.post({
      uri:
        process.env.REACT_APP_ENV === 'SIT'
          ? 'd348808f-6781-4a8a-8dad-e2f450df8cb2'
          : 'late-production-by-production-code',
      params,
    });
  // Success         https://run.mocky.io/v3/d348808f-6781-4a8a-8dad-e2f450df8cb2

  getAvgDelayedProduction = (params: filterPayload) =>
    // API.post({uri:'/sales/avg-delayed-production-time', params});
    API.post({
      uri: process.env.REACT_APP_ENV === 'SIT' ? 'aaff654e-0206-4ce6-a605-3088846a5a06' : 'avg-delayed-production-time',
      params,
    });

  // API.post({ uri: 'https://run.mocky.io/v3/dcdd1572-6e63-45fd-a6b9-d09ff62115d4', params }); year-month: number
  // Success         https://run.mocky.io/v3/dcdd1572-6e63-45fd-a6b9-d09ff62115d4
}

export const LateProductionService = new LateDeliveryServiceClass();

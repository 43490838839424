import { Grid } from '@mui/material';
import React from 'react';

export enum PROFILE_ROLE {
  DM = 'DSM',
  SP = 'Sales Planning',
  GM = 'General Manager (GM)',
  HD = 'Group Manager (HD)',
  SC = 'Sales Consultant',
}

export const dataGroup = [
  { region: 'BANGKOK' },
  { region: 'CENTRAL' },
  { region: 'NORTH' },
  { region: 'NORTH-EAST' },
  { region: 'SOUTH' },
];

export const mockList4 = [{ month: 4, year: 2022 }];
export const mockList3 = [{ startMonth: 2, startYear: 2022, endMonth: 8, endYear: 2023 }];

export const monthList = [
  { id: 1, monthName: 'JAN' },
  { id: 2, monthName: 'FEB' },
  { id: 3, monthName: 'MAR' },
  { id: 4, monthName: 'APR' },
  { id: 5, monthName: 'MAY' },
  { id: 6, monthName: 'JUN' },
  { id: 7, monthName: 'JUL' },
  { id: 8, monthName: 'AUG' },
  { id: 9, monthName: 'SEP' },
  { id: 10, monthName: 'OCT' },
  { id: 11, monthName: 'NOV' },
  { id: 12, monthName: 'DEC' },
];

export const dataItem = [
  {
    name: 'uuuu',
    data: {
      A: 40,
      B: 23,
    },
  },
  {
    name: 'iiiii',
    data: {
      A: 50,
      B: 23,
    },
  },
  {
    name: 'dggg',
    data: {
      A: 66,
      B: 11,
    },
  },
  {
    name: 'drrr',
    data: {
      A: 10,
      B: 61,
    },
  },
  {
    name: 'eeee',
    data: {
      A: 70,
      B: 6,
    },
  },
  {
    name: 'ppp',
    data: {
      A: 80,
      B: 20,
    },
  },
  {
    name: 'kkk',
    data: {
      A: 30,
      B: 33,
    },
  },
];

export const dataItemSolo = [
  {
    name: 'uuuu',
    data: {
      A: 40,
    },
  },
  {
    name: 'iiiii',
    data: {
      A: 50,
    },
  },
  {
    name: 'dggg',
    data: {
      A: 66,
    },
  },
  {
    name: 'drrr',
    data: {
      A: 10,
    },
  },
  {
    name: 'eeee',
    data: {
      A: 70,
    },
  },
  {
    name: 'ppp',
    data: {
      A: 80,
    },
  },
  {
    name: 'kkk',
    data: {
      A: 30,
    },
  },
];

export const dataLine: { [key: string]: number } = {
  'Page A': 2000,
  'Page B': 3000,
  'Page C': 2000,
  'Page D': 2780,
  'Page E': 1890,
  'Page F': 2390,
  'Page G': 3490,
  // Add more data points as needed
  'Page H': 1500,
  'Page I': 2700,
  'Page J': 3200,
};

export const columnsVertical = [
  '',
  <Grid container spacing={1} key={'deliveryLateQty'}>
    <Grid item xs="auto" style={{ margin: 4 }}>
      <div className="paletteSquare blank" />
    </Grid>
    <Grid item xs={9} style={{ margin: 4 }}>
      Delivery Late
    </Grid>
  </Grid>,
  <Grid container spacing={1} key={'delayedDepartureQty'}>
    <Grid item xs="auto" style={{ margin: 4 }}>
      <div className="paletteSquare transitTime" />
    </Grid>
    <Grid item xs={9} style={{ margin: 4 }}>
      Delayed Transit Time
    </Grid>
  </Grid>,
  <Grid container spacing={1} key={'delayTransitTimeQty'}>
    <Grid item xs="auto" style={{ margin: 4 }}>
      <div className="paletteSquare Departure" />
    </Grid>
    <Grid item xs={9} style={{ margin: 4 }}>
      Delayed Departure
    </Grid>
  </Grid>,

  <Grid container spacing={1} key={'totalOrder'}>
    <Grid item xs="auto" style={{ margin: 4 }}>
      <div className="paletteSquare blank" />
    </Grid>
    <Grid item xs={9} style={{ margin: 4, fontWeight: 'bold' }}>
      Total Order
    </Grid>
  </Grid>,
];

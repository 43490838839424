import React, { useEffect, useState } from 'react';
import './groupedLabel.scss';
import { KeyboardArrowDown } from '@mui/icons-material';
import { changeValuesToLabel } from '../../helper/utils-helper';
import DrawerSidebar from '../drawerSidebar/drawerSidebar';

type NewData = {
  label: string;
  value: string;
};

interface GroupedLabelSingleProps {
  titleLabel: string;
  dataList: NewData[];
  disabled?: boolean;
  isSelectFilter: (key: string, value: any) => void;
  selectedTemp?: any;
  textLabelTemp?: string | any;
}

export default function GroupedLabelSingle({
  titleLabel,
  dataList,
  disabled,
  selectedTemp,
  textLabelTemp,
  isSelectFilter,
}: GroupedLabelSingleProps) {
  const [openDrawer, setOpenDrawer] = useState(false);
  const defaultWord = 'ALL';
  const [selectedValue, setSelectedValue] = useState(selectedTemp ? selectedTemp : defaultWord);
  const [textLabel, setTextLabel] = React.useState('');

  const handleOpenDrawer = () => {
    setOpenDrawer(true);
  };

  useEffect(() => {
    // console.warn('textLabelTemp', textLabelTemp);
  }, [textLabelTemp]);
  useEffect(() => {
    // console.warn('selectedTemp', selectedTemp);
  }, [selectedTemp]);

  useEffect(() => {
    // Set the initial value of textLabel from textLabelTemp when the component is mounted
    if (textLabelTemp && selectedTemp) {
      setTextLabel(textLabelTemp);
      setSelectedValue(selectedTemp);
    }
  }, [selectedTemp, textLabelTemp]);

  const handleCloseDrawer = () => {
    setOpenDrawer(false);
  };

  const handleSelectionClick = (value: any) => {
    // console.warn(value);
    const multivalue = changeValuesToLabel(value, dataList);
    setTextLabel(multivalue);
    setSelectedValue(value);
    isSelectFilter('regionCode', value);
    console.warn(value);
  };

  return (
    <>
      <fieldset
        id={disabled ? 'dropdown-disabled' : 'dropdown'}
        onClick={() => {
          // console.warn('open', openDrawer);
          // handleOpenDrawer();
          if (!disabled) {
            handleOpenDrawer();
          }
        }}
      >
        <>
          <legend id={disabled ? 'dropdown-label-disabled' : 'dropdown-label'}>{titleLabel}</legend>
          <>{textLabel || 'All'}</>
        </>
        <KeyboardArrowDown id="dropdown-icon" style={{ color: disabled ? '#c7c7c7' : '#fa230d' }} />
      </fieldset>
      <DrawerSidebar
        titleLabel={titleLabel}
        dataList={dataList}
        isDrawerOpen={openDrawer}
        handleCloseDrawer={handleCloseDrawer}
        selectedValue={selectedValue}
        handleSelectionClick={handleSelectionClick}
        isBackdropOpen={openDrawer}
        handleApply={() => handleCloseDrawer}
      />
    </>
  );
}

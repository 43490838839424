import API from '../api';
import { filterPayload } from '../type';
class RegionServiceClass {
  getRegions = (params: filterPayload) =>
    // API.post({uri:'/sales/regions'});
    API.post({
      uri: process.env.REACT_APP_ENV === 'SIT' ? 'a75b0cae-c12d-4dbd-9f9a-a7dd27044abb' : 'regions',
      params,
    });

  // Success         https://run.mocky.io/v3/b2f4a8ad-35a1-43b2-8a05-c32c926ca013

  getSubRegions = (params: filterPayload) =>
    // API.post({uri:'/sales/subregions', params});
    API.post({
      uri: process.env.REACT_APP_ENV === 'SIT' ? '1481c2c2-52d9-4609-9f3c-768cf07262c4' : 'subregions',
      params,
    });
  // Success         https://run.mocky.io/v3/1481c2c2-52d9-4609-9f3c-768cf07262c4

  getAreas = (params: filterPayload) =>
    // API.post({uri:'/sales/areas', params});
    API.post({
      uri: process.env.REACT_APP_ENV === 'SIT' ? '05b3b48e-f060-4062-aa5c-4cbb0650677c' : 'areas',
      params,
    });
  // Success         https://run.mocky.io/v3/05b3b48e-f060-4062-aa5c-4cbb0650677c

  getProvinces = (params: filterPayload) =>
    // API.post({uri:'/sales/provinces', params});
    API.post({
      uri: process.env.REACT_APP_ENV === 'SIT' ? '389ab2df-4416-4b70-a2e8-89d99117e11a' : 'provinces',
      params,
    });
  // Success         https://run.mocky.io/v3/389ab2df-4416-4b70-a2e8-89d99117e11a
}

export const RegionService = new RegionServiceClass();

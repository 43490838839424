import React, { useEffect, useState } from 'react';
import { IList } from '../../components/Header/types';
import { UserRole } from '../../services/type';
import { Stack, ThemeProvider } from '@mui/material';
import { HeaderComponent } from '../../components/Header/HeaderComponent';
import theme from '../../theme';
import secureLocalStorage from 'react-secure-storage';
import { useNavigate } from 'react-router-dom';
import { AuthService } from '../../services/authService'; // Import AuthService
import { useAuthStore } from '../../state/authen-store';

interface ProtectedRouteProps {
  children: React.ReactNode;
  screenName?: string;
}

const listSaleScreen: IList[] = [
  {
    to: '/delivery-status',
    btnName: 'DELIVERY STATUS',
    page: 'DELIVERY STATUS',
    role: [UserRole.DELIVERY_REGION, UserRole.DELIVERY_AREA, UserRole.DELIVERY_DEALER],
    key: 'delivery_status',
  },
  {
    to: '/expected-delivery',
    btnName: 'EXPECTED DELIVERY',
    page: 'EXPECTED DELIVERY',
    role: [UserRole.EXPECTED_NATION, UserRole.EXPECTED_REGION, UserRole.EXPECTED_AREA],
    key: 'expected_delivery',
  },
  {
    to: '/late-delivery',
    btnName: 'LATE DELIVERY',
    page: 'LATE DELIVERY REPORT',
    role: [UserRole.LATE_DELIVERY, UserRole.LATE_PRODUCTION],
    key: 'late',
  },
];

const listYesScreen: IList[] = [
  {
    to: '/yes-summary',
    btnName: 'SUMMARY',
    page: 'EXPECTED DELIVERY',
    subTitle: 'DEALER',
    role: [''],
    key: 'yesSummary',
  },
  {
    to: '/yes-details',
    btnName: 'DETAILS',
    page: 'EXPECTED DELIVERY',
    subTitle: 'DEALER',
    role: [''],
    key: 'yesDetails',
  },
];

export const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children, screenName }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(true);
  const { profileData, setProfile } = useAuthStore();
  const queryParameters = new URLSearchParams(window.location.search);
  const salesToken = secureLocalStorage.getItem('salesToken');
  const yesToken = secureLocalStorage.getItem('yesToken');
  // const { setProfile } = useAuthStore();
  // const profileData = mockProfile.data;
  // console.warn(salesToken);
  // console.warn(yesToken);
  useEffect(() => {
    // console.warn(profileData);
    // console.warn('profileDataprofileDataprofileDataprofileDataprofileData');
  }, [profileData]);

  useEffect(() => {
    AuthService.getProfile({ salesToken: salesToken, yesToken: yesToken })
      .then((resp) => {
        setLoading(false);
        const data = resp?.data?.data;
        // console.warn('ddatadatadatadatadataata', data);
        setProfile(data);
      })
      .catch((err) => {
        setLoading(false);
        if (err.code === 'ERR_INVALID_JWT') {
          return navigate('/error', {
            state: {
              errorMessage: err.message || err.code || '',
              errtokenExpire: true,
            },
            replace: true,
          });
        }
        return navigate('/error', {
          state: {
            errorMessage: err.message || err.code || '',
            errtokenExpire: false,
          },
          replace: true,
        });
      });
  }, [navigate, salesToken, yesToken]);

  // You can render the protected route content
  return (
    <ThemeProvider theme={theme}>
      {!loading && profileData && (
        <Stack sx={{ display: 'inline-flex' }} className="container">
          <HeaderComponent
            profileData={profileData}
            listScreen={yesToken ? listYesScreen : salesToken ? listSaleScreen : []}
            permissions={profileData?.permissions}
            screenName={screenName}
            dealer={yesToken ? true : false}
          />
          {children}
        </Stack>
      )}
    </ThemeProvider>
  );
};

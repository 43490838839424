import React from 'react';
import { Grid } from '@mui/material';

export const statusAllRegionData = [
  {
    regionCode: '1',
    regionName: 'NATIONWIDE',
    mcDelivered: 5728,
    mcInTransit: 4413,
    mcWaitForShipping: 1419,
    mcRejected: 20,
    baseMcOrdered: 5,
    canceledMcOrdered: 5,
    mcInProduction: 3348,
  },
  {
    regionCode: '2',
    regionName: 'North',
    mcDelivered: 4728,
    mcInTransit: 4413,
    mcWaitForShipping: 1419,
    mcRejected: 20,
    baseMcOrdered: 5,
    canceledMcOrdered: 5,
    mcInProduction: 3347,
  },
  {
    regionCode: '3',
    regionName: 'South',
    mcDelivered: 3728,
    mcInTransit: 4413,
    mcWaitForShipping: 1419,
    mcRejected: 20,
    baseMcOrdered: 5,
    canceledMcOrdered: 5,
    mcInProduction: 3346,
  },
  {
    regionCode: '4',
    regionName: 'North-east',
    mcDelivered: 2728,
    mcInTransit: 4413,
    mcWaitForShipping: 1419,
    mcRejected: 20,
    baseMcOrdered: 5,
    canceledMcOrdered: 5,
    mcInProduction: 3345,
  },
  {
    regionCode: '5',
    regionName: 'Central',
    mcDelivered: 1728,
    mcInTransit: 4413,
    mcWaitForShipping: 1419,
    mcRejected: 20,
    baseMcOrdered: 5,
    canceledMcOrdered: 5,
    mcInProduction: 3344,
  },
];

export const statusByAreaData = [
  {
    areaCode: '1',
    areaName: 'C-E1',
    mcDelivered: 1,
    mcInTransit: 0,
    mcWaitForShipping: 0,
    mcRejected: 2,
    baseMcOrdered: 5,
    canceledMcOrdered: 5,
    mcInProduction: 4,
  },
  {
    areaCode: '2',
    areaName: 'C-E2',
    mcDelivered: 2,
    mcInTransit: 4,
    mcWaitForShipping: 5,
    mcRejected: 7,
    baseMcOrdered: 5,
    canceledMcOrdered: 5,
    mcInProduction: 19,
  },
  {
    areaCode: '3',
    areaName: 'C-E3',
    mcDelivered: 3,
    mcInTransit: 10,
    mcWaitForShipping: 10,
    mcRejected: 5,
    baseMcOrdered: 5,
    canceledMcOrdered: 5,
    mcInProduction: 36,
  },
  {
    areaCode: '4',
    areaName: 'C-E4',
    mcDelivered: 5,
    mcInTransit: 9,
    mcWaitForShipping: 17,
    mcRejected: 4,
    baseMcOrdered: 5,
    canceledMcOrdered: 5,
    mcInProduction: 55,
  },
  {
    areaCode: '5',
    areaName: 'C-W1',
    mcDelivered: 1,
    mcInTransit: 0,
    mcWaitForShipping: 0,
    mcRejected: 2,
    baseMcOrdered: 5,
    canceledMcOrdered: 5,
    mcInProduction: 4,
  },
  {
    areaCode: '6',
    areaName: 'C-W2',
    mcDelivered: 2,
    mcInTransit: 4,
    mcWaitForShipping: 5,
    mcRejected: 7,
    baseMcOrdered: 5,
    canceledMcOrdered: 5,
    mcInProduction: 19,
  },
  {
    areaCode: '7',
    areaName: 'C-W3',
    mcDelivered: 3,
    mcInTransit: 10,
    mcWaitForShipping: 10,
    mcRejected: 5,
    baseMcOrdered: 5,
    canceledMcOrdered: 5,
    mcInProduction: 36,
  },
  {
    areaCode: '8',
    areaName: 'C-W4',
    mcDelivered: 5,
    mcInTransit: 9,
    mcWaitForShipping: 17,
    mcRejected: 4,
    baseMcOrdered: 5,
    canceledMcOrdered: 5,
    mcInProduction: 55,
  },
];

export const statusByDealerData = [
  {
    dealerCode: '1',
    dealerNameTh: 'บ.โฮม โปรดักส์ เซ็นเตอร์ จก. (มหาชน)',
    dealerNameEn: 'EN บ.โฮม โปรดักส์ เซ็นเตอร์ จก. (มหาชน)',
    mcDelivered: 10,
    mcInTransit: 9,
    mcWaitForShipping: 13,
    mcRejected: 1,
    baseMcOrdered: 5,
    canceledMcOrdered: 5,
    mcInProduction: 32,
  },
  {
    dealerCode: '2',
    dealerNameTh: 'บ.โมโตมีเดีย กรุ๊ป จก.',
    dealerNameEn: 'EN บ.โมโตมีเดีย กรุ๊ป จก.',
    mcDelivered: 7,
    mcInTransit: 5,
    mcWaitForShipping: 8,
    mcRejected: 2,
    baseMcOrdered: 5,
    canceledMcOrdered: 5,
    mcInProduction: 20,
  },
  {
    dealerCode: '3',
    dealerNameTh: 'บ.กรีนแอปเปิ้ล กราฟฟิค พริ้นติ้ง จก.',
    dealerNameEn: 'EN บ.กรีนแอปเปิ้ล กราฟฟิค พริ้นติ้ง จก.',
    mcDelivered: 3,
    mcInTransit: 0,
    mcWaitForShipping: 2,
    mcRejected: 3,
    baseMcOrdered: 5,
    canceledMcOrdered: 5,
    mcInProduction: 5,
  },
  {
    dealerCode: '4',
    dealerNameTh: 'บ.33 มอเตอร์เซลล์ จก.',
    dealerNameEn: 'EN บ.33 มอเตอร์เซลล์ จก.',
    mcDelivered: 3,
    mcInTransit: 4,
    mcWaitForShipping: 1,
    mcRejected: 5,
    baseMcOrdered: 5,
    canceledMcOrdered: 5,
    mcInProduction: 8,
  },
  {
    dealerCode: '5',
    dealerNameTh: 'บ.จักรรัตน์ มอเตอร์ จก.',
    dealerNameEn: 'EN บ.จักรรัตน์ มอเตอร์ จก.',
    mcDelivered: 8,
    mcInTransit: 5,
    mcWaitForShipping: 8,
    mcRejected: 2,
    baseMcOrdered: 5,
    canceledMcOrdered: 5,
    mcInProduction: 21,
  },
  {
    dealerCode: '6',
    dealerNameTh: 'บ.ชัยเจริญดี จก.',
    dealerNameEn: 'EN บ.ชัยเจริญดี จก.',
    mcDelivered: 4,
    mcInTransit: 0,
    mcWaitForShipping: 3,
    mcRejected: 2,
    baseMcOrdered: 5,
    canceledMcOrdered: 5,
    mcInProduction: 7,
  },
  {
    dealerCode: '7',
    dealerNameTh: 'บ.ชัยณรงค์มอเตอร์เซลล์ จก.',
    dealerNameEn: 'EN บ.ชัยณรงค์มอเตอร์เซลล์ จก.',
    mcDelivered: 10,
    mcInTransit: 1,
    mcWaitForShipping: 2,
    mcRejected: 3,
    baseMcOrdered: 5,
    canceledMcOrdered: 5,
    mcInProduction: 13,
  },
  {
    dealerCode: '8',
    dealerNameTh: 'บ.ธนรุ่งโรจน์โทรม้า จก.',
    dealerNameEn: 'EN บ.ธนรุ่งโรจน์โทรม้า จก.',
    mcDelivered: 8,
    mcInTransit: 2,
    mcWaitForShipping: 6,
    mcRejected: 2,
    baseMcOrdered: 5,
    canceledMcOrdered: 5,
    mcInProduction: 16,
  },
  {
    dealerCode: '9',
    dealerNameTh: 'บ.นัดพบนนท์ มอเตอร์เซอร์วิส จก.',
    dealerNameEn: 'EN บ.นัดพบนนท์ มอเตอร์เซอร์วิส จก.',
    mcDelivered: 7,
    mcInTransit: 4,
    mcWaitForShipping: 14,
    mcRejected: 1,
    baseMcOrdered: 5,
    canceledMcOrdered: 5,
    mcInProduction: 25,
  },
];

export const columnDeliveryStatus = [
  '',
  <Grid container spacing={1} key={'total'}>
    <Grid item xs="auto" style={{ margin: 4 }}>
      <div className="colorSquare blank" />
    </Grid>
    <Grid item xs={8} style={{ margin: 4 }}>
      Total
    </Grid>
  </Grid>,
  <Grid container spacing={1} key={'delivered'}>
    <Grid item xs="auto" style={{ margin: 4 }}>
      <div className="colorSquare delivery" />
    </Grid>
    <Grid item xs={8} style={{ margin: 4 }}>
      Delivered
    </Grid>
  </Grid>,
  <Grid container spacing={1} key={'inTransit'}>
    <Grid item xs="auto" style={{ margin: 4 }}>
      <div className="colorSquare inTransit" />
    </Grid>
    <Grid item xs={8} style={{ margin: 4 }}>
      In Transit
    </Grid>
  </Grid>,
  <Grid container spacing={1} key={'waitForShipping'}>
    <Grid item xs="auto" style={{ margin: 4 }}>
      <div className="colorSquare waitForShipping" />
    </Grid>
    <Grid item xs={8} style={{ margin: 4 }}>
      Wait for Shipping
    </Grid>
  </Grid>,
  <Grid container spacing={1} key={'inProduction'}>
    <Grid item xs="auto" style={{ margin: 4 }}>
      <div className="colorSquare inProcess" />
    </Grid>
    <Grid item xs={8} style={{ margin: 4 }}>
      In Production
    </Grid>
  </Grid>,
  <Grid container spacing={1} key={'rejected'}>
    <Grid item xs="auto" style={{ margin: 4 }}>
      <div className="colorSquare reJected" />
    </Grid>
    <Grid item xs={8} style={{ margin: 4 }}>
      Rejected
    </Grid>
  </Grid>,
];

export const barColorsDeliveryStatus = ['#12F4E1', '#C7C7C7', '#FEBF00', '#FA230D', '#585858'];

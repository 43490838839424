import React, { CSSProperties } from 'react';
import './tableVertical.scss';
interface HorizontalRowProps {
  data: Record<string, string | number>[];
  width?: string | number;
  height?: string | number;
  styleRow?: CSSProperties;
  keyHeader: string;
  showTotal: boolean;
  minWidthHRow?: number;
}

export const HorizontalRow: React.FC<HorizontalRowProps> = ({
  data,
  width,
  height,
  keyHeader,
  showTotal,
  styleRow,
  minWidthHRow,
}) => (
  <div style={{ display: 'flex', width: minWidthHRow, fontSize: '12px' }}>
    {data.map((row, rowIndex) => (
      <div key={rowIndex}>
        <div
          style={{
            maxWidth: width,
            minWidth: width,
            minHeight: height,
            maxHeight: height,
            // borderLeft: rowIndex === 0 ? '1px solid #ccc' : '0.2px solid #ccc',
            // marginLeft: rowIndex === 0 ? ' 0.5px' : 0,
          }}
          className="rowStyle"
        >
          {row[keyHeader]}
        </div>
        {showTotal && (
          <div
            style={{
              maxWidth: width,
              minWidth: width,
              minHeight: height,
              maxHeight: height,
            }}
            className="rowStyle"
          >
            {row.total}
          </div>
        )}
        {/* Display other properties */}
        {Object.keys(row).map((key, index) => {
          if (key !== 'total' && key !== keyHeader) {
            return (
              <div
                key={index}
                style={{
                  maxWidth: width,
                  minWidth: width,
                  minHeight: height,
                  maxHeight: height,
                  ...styleRow,
                }}
                className="rowStyle"
              >
                {row[key]}
              </div>
            );
          }
          return null;
        })}
      </div>
    ))}
  </div>
);

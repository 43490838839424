import API from '../api';
import { filterPayload } from '../type';

class DeliveryStatusServiceClass {
  getOverallStatus = (params: filterPayload) =>
    // API.post({uri:'', params});
    API.post({
      uri: process.env.REACT_APP_ENV === 'SIT' ? '525c0437-3994-412b-b42d-9c5af4de98f6' : 'overall-delivery-status',
      params,
    });
  // Success         https://run.mocky.io/v3/43319177-1113-4125-8289-0488fb4d608d

  getpinedata = (params: filterPayload) =>
    // API.post({uri:'/sales/overall-delivery-status', params});
    API.post({
      uri: process.env.REACT_APP_ENV === 'SIT' ? '43319177-1113-4125-8289-0488fb4d608d' : 'overall-delivery-status',
      params,
    });

  getAreaStatus = (params: filterPayload) =>
    // API.post({uri:'/sales/delivery-status-by-area', params});
    API.post({
      uri: process.env.REACT_APP_ENV === 'SIT' ? 'd40c11f9-cfea-4f2b-ac76-95072c442cf6' : 'delivery-status-by-area',
      params,
    });
  // Success         https://run.mocky.io/v3/d40c11f9-cfea-4f2b-ac76-95072c442cf6

  getDealerStatus = (params: filterPayload) =>
    // API.post({uri:'/sales/delivery-status-by-dealer', params});
    API.post({
      uri: process.env.REACT_APP_ENV === 'SIT' ? '47e5d6ae-4a64-482c-95c9-701267787b19' : 'delivery-status-by-dealer',
      params,
    });
  // Success         https://run.mocky.io/v3/47e5d6ae-4a64-482c-95c9-701267787b19

  getDsmByArea = (params: filterPayload) =>
    // API.post({uri:'/sales/delivery-status-by-area', params});
    API.post({
      uri: process.env.REACT_APP_ENV === 'SIT' ? '5006b612-ba61-43d2-9a16-8c0c707da8da' : 'delivery-status-by-area',
      params,
    });
  // Success         https://run.mocky.io/v3/47e5d6ae-4a64-482c-95c9-701267787b19

  getDsmByDealer = (params: filterPayload) =>
    // API.post({uri:'/sales/delivery-status-by-dealer', params});
    API.post({
      uri: process.env.REACT_APP_ENV === 'SIT' ? '822314ce-fe05-4c93-9e38-824149127aef' : 'delivery-status-by-dealer',
      params,
    });
  // Success         https://run.mocky.io/v3/47e5d6ae-4a64-482c-95c9-701267787b19

  getDeliveryStatus = (params: filterPayload) =>
    // API.post({uri:'', params});
    API.post({
      uri: process.env.REACT_APP_ENV === 'SIT' ? '525c0437-3994-412b-b42d-9c5af4de98f6' : 'delivery-status',
      params,
    });
}

export const DeliveryStatusService = new DeliveryStatusServiceClass();

import React from 'react';
import { Column } from '../../components/Table/TableCustom';
import { numberWithCommas } from '../../helper/utils-helper';

export const mockLRegions = [
  { region: 'BANGKOK' },
  { region: 'CENTRAL' },
  { region: 'NORTH' },
  { region: 'NORTH-EAST' },
  { region: 'SOUTH' },
];

export const mockDate = [{ month: 4, year: 2022 }];

export const mockColors = [
  {
    colorCode: '0300',
    colorNameTh: 'ดำ',
    colorNameEn: 'Black',
  },
  {
    colorCode: '0600',
    colorNameTh: 'เขียว',
    colorNameEn: 'Green',
  },
  {
    colorCode: '0700',
    colorNameTh: 'เทา',
    colorNameEn: 'Gray',
  },
];

export const mockColumn: Column[] = [
  {
    id: 'ownerCode',
    label: 'Owner Code ',
    styleColumn: {
      backgroundColor: '#fa230d',
      color: 'white',
      width: '120px',
      textAlign: 'center',
      border: 'solid #fa230d',
    },
    width: 100,
    align: 'center',
    customRow: (ownerCode: any) => <div style={{ textAlign: 'left' }}>{ownerCode}</div>,
  },
  {
    id: 'dealerName',
    label: 'Dealer Name',
    styleColumn: {
      backgroundColor: '#fa230d',
      color: 'white',
      border: 'solid #fa230d',
    },
    width: 120,
    align: 'center',
    customRow: (dealerName: any) => <div style={{ textAlign: 'left' }}>{dealerName}</div>,
  },
  {
    id: 'productCode',
    label: 'Product Code',
    styleColumn: {
      backgroundColor: '#fa230d',
      color: 'white',
      border: 'solid #fa230d',
    },
    width: 140,
    align: 'right',
    customRow: (productCode: any) => <div style={{ textAlign: 'left' }}>{productCode}</div>,
  },
  {
    id: 'productName',
    label: 'Product Name',
    styleColumn: {
      backgroundColor: '#fa230d',
      color: 'white',
      border: 'solid #fa230d',
    },
    width: 140,
    align: 'right',
    customRow: (productName: any) => <div style={{ textAlign: 'left' }}>{productName}</div>,
  },
  {
    id: 'color',
    label: 'Color',
    styleColumn: {
      backgroundColor: '#fa230d',
      color: 'white',
      border: 'solid #fa230d',
    },
    width: 60,
    align: 'right',
    customRow: (color: any) => <div style={{ textAlign: 'left' }}>{color}</div>,
  },
  {
    id: 'qty',
    label: 'Qty',
    styleColumn: {
      backgroundColor: '#fa230d',
      color: 'white',
      border: 'solid #fa230d',
    },
    width: 60,
    align: 'center',
    customRow: (qty: any) => <div style={{ textAlign: 'center' }}>{qty}</div>,
  },
  {
    id: 'expectedDeliveryDate',
    label: 'Expected Deliver Date',
    styleColumn: {
      backgroundColor: '#fa230d',
      color: 'white',
      border: 'solid #fa230d',
    },
    width: 100,
    align: 'center',
    customRow: (expectedDeliveryDate: any) => <div style={{ textAlign: 'left' }}>{expectedDeliveryDate}</div>,
  },
  {
    id: 'totalAmount',
    label: 'Total Amount (THB)',
    styleColumn: {
      backgroundColor: '#fa230d',
      color: 'white',
      border: 'solid #fa230d',
    },
    width: 80,
    align: 'center',
    customRow: (totalAmount: any) => <div style={{ textAlign: 'right' }}>{numberWithCommas(totalAmount)}</div>,
  },
];

import React from 'react';
import './DeliveryStatusPieChart.scss';
import { Typography } from '@mui/material';
import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts';

interface DeliverStatusPieChartProps {
  haveViewDetail?: boolean;
  titleLabel: string;
  dataList: { name: string; value: number }[];
  onClick?: () => void;
}

export default function DeliveryStatusPieChart({ titleLabel, dataList, onClick }: DeliverStatusPieChartProps) {
  const totalValue = dataList.reduce((total, obj) => obj.value + total, 0);

  const getPercent = (value: number) => {
    return ((value / totalValue) * 100).toFixed(0);
  };

  const COLORS = ['#12F4E1', '#C7C7C7', '#FEBF00', '#FA230D', '#585858'];

  return (
    <div id="chart-container" onClick={onClick}>
      <Typography variant="h5" style={{ fontWeight: 'bold' }}>
        {titleLabel}
      </Typography>
      <ResponsiveContainer width={200} height={200}>
        <PieChart>
          <text textAnchor="middle" x="50%" y="42%" fontSize={24} style={{ fontWeight: 'bold' }}>
            TOTAL
          </text>
          <text style={{ fontWeight: 'bold' }} fontSize={36} textAnchor="middle" x="50%" y="63%">
            {totalValue}
          </text>
          <Pie
            data={dataList}
            innerRadius={76}
            outerRadius={90}
            dataKey="value"
            startAngle={90}
            endAngle={450}
            blendStroke
            animationDuration={0}
            isAnimationActive={false}
          >
            {dataList.map((entry, index) => (
              <Cell fill={COLORS[index]} key={index} />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
      <div id="chart-label-container">
        {dataList.map((el, index) => {
          return (
            <div id="chart-label-color" key={index}>
              <span
                id="color-label"
                style={{
                  backgroundColor: COLORS[index],
                }}
              />
              <div id="chart-label-text">
                <span id="chart-label-text-name">{el.name}</span>
                <span id="chart-label-text-value">{el.value} </span>
                <span>
                  <strong>
                    ({getPercent(el.value)}
                    {'%'})
                  </strong>
                </span>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

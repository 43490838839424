import axios from 'axios';
// import './interceptor';
import secureLocalStorage from 'react-secure-storage';

const checkSaleOrDealer = () => {
  const queryParameters = new URLSearchParams(window.location.search);

  if (queryParameters.get('salesToken')) {
    secureLocalStorage.removeItem('yesToken');
    // console.warn('clearrr yesToken');
  } else if (queryParameters.get('yesToken')) {
    secureLocalStorage.removeItem('salesToken');
    // console.warn('clearrr salesToken');
  }
  const salesToken = queryParameters.get('salesToken') || secureLocalStorage.getItem('salesToken');
  const yesToken = queryParameters.get('yesToken') || secureLocalStorage.getItem('yesToken');

  if (salesToken) {
    secureLocalStorage.setItem('salesToken', salesToken);
  } else if (yesToken) {
    secureLocalStorage.setItem('yesToken', yesToken);
  }
};
checkSaleOrDealer();

const subPath = secureLocalStorage.getItem('salesToken')
  ? 'sales/'
  : secureLocalStorage.getItem('yesToken') && 'dealer/';
// console.warn(subPath);
const checkPath = process.env.REACT_APP_ENV === 'SIT' ? '' : subPath;
export const envConfig = {
  API_URL: `${process.env.REACT_APP_API_CONTEXT_PATH}${checkPath}`,
};

function replaceEmptyArrayWithNull(arr: any) {
  if (Array.isArray(arr) && (arr.length === 0 || (arr.length === 1 && arr[0] === null))) {
    return null;
  } else if (!Array.isArray(arr) && arr !== null && arr !== undefined) {
    // console.warn('arrarrarrarr', arr);
    return [arr];
  }
  return arr;
}
class APIClient {
  $fetch = (url: string, method: string, data: any, header?: any, cancelToken?: any) => {
    const headers: any = {
      'Content-type': header || 'application/json;charset=UTF-8',
    };
    const params: any = {
      method,
      url: url.includes('http') ? url : `${envConfig.API_URL}${url}`,
      headers,
      // responseType: 'json',
      // // @ts-ignore
      // transformResponse: axios.defaults.transformResponse.concat((data) => new CustomHttpResponse(data)),
    };
    if (data) {
      if (secureLocalStorage.getItem('salesToken')) {
        data.salesToken = secureLocalStorage.getItem('salesToken');
      } else {
        data.yesToken = secureLocalStorage.getItem('yesToken');
      }

      data.colorCodes = replaceEmptyArrayWithNull(data.colorCodes);
      data.productCodes = replaceEmptyArrayWithNull(data.productCodes);
      data.modelCodes = replaceEmptyArrayWithNull(data.modelCodes);
      data.modelNames = replaceEmptyArrayWithNull(data.modelNames);
      data.dealerCodes = replaceEmptyArrayWithNull(data.dealerCodes);
      data.ownerCodes = replaceEmptyArrayWithNull(data.ownerCodes);
      data.provinceCodes = replaceEmptyArrayWithNull(data.provinceCodes);
      data.subregionCodes = replaceEmptyArrayWithNull(data.subregionCodes);
      data.areaCodes = replaceEmptyArrayWithNull(data.areaCodes);
      // console.warn('data.regionCodedata.regionCode', data.regionCode);
      // data.regionCode = replaceEmptyArrayWithNull(data.regionCode);
      // console.warn('data.regionCode.lengthdata.regionCode.lengthdata.regionCode.length', data.regionCode.length);
      if (Array?.isArray(data.regionCode)) {
        data.regionCode = null;
      }

      if (method === 'GET') {
        params.params = data;
      } else {
        params.data = data;
      }
    }
    if (cancelToken) {
      params.cancelToken = cancelToken;
    }

    return axios(params);
  };

  get(options: any) {
    return this.$fetch(options.uri, 'GET', options.params, null, options.cancelToken);
  }

  post(options: any) {
    return this.$fetch(options.uri, 'POST', options.params, options.header);
  }

  delete(options: any) {
    return this.$fetch(options.uri, 'DELETE', options.params);
  }

  put(options: any) {
    return this.$fetch(options.uri, 'PUT', options.params);
  }

  patch(options: any) {
    return this.$fetch(options.uri, 'PATCH', options.params);
  }
}

const API = new APIClient();
export default API;

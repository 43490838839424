import { createTheme, ThemeOptions } from '@mui/material/styles';
import { Typography } from './Typography';

const rootStyles = getComputedStyle(document.documentElement);
const getComputedStyleByPropValue = (propValue: string) => rootStyles.getPropertyValue(propValue);

const theme = createTheme({
  palette: {
    primary: {
      main: getComputedStyleByPropValue('--mui-palette-primary'),
    },
    secondary: {
      main: getComputedStyleByPropValue('--mui-palette-secondary'),
    },
  },
  typography: Typography,
} as ThemeOptions);

export default theme;

import React, { useEffect } from 'react';
import './drawerSidebarDate.scss';
import { monthList } from './mock';
import { Drawer, Typography, Divider, Button, IconButton, Backdrop } from '@mui/material';
import { Close } from '@mui/icons-material';
import moment from 'moment';

type DataTypeMap = {
  datePeriod: {
    startMonth: number;
    startYear: number;
    endMonth: number;
    endYear: number;
  }[];
  date: {
    month: number;
    year: number;
  }[];
};

interface DateProps {
  handleSelectionMonthClick: (value: any) => void;
  handleSelectionYearClick: (value: any) => void;
}

interface DatePeriodProps {
  handleSelectionMonthClick: (start: any, end: any) => void;
  handleSelectionYearClick: (start: any, end: any) => void;
}

type DrawerSidebarProps<T extends keyof DataTypeMap> = T extends 'date'
  ? DateProps
  : T extends 'datePeriod'
  ? DatePeriodProps
  : {};

interface drawerSidebarDateProps<T extends keyof DataTypeMap> {
  // dataList: DataTypeMap[T];
  typeOfData?: 'datePeriod' | 'date';
  isDrawerOpen: boolean;
  isBackdropOpen: boolean;
  selectedOnlyMonth?: number;
  selectedOnlyYear?: number;
  selectedStartMonth: number;
  selectedStartYear: number;
  selectedEndMonth: number;
  selectedEndYear: number;
  handleCloseDrawer: () => void;
  handleSelectionMonthClick: DrawerSidebarProps<T>['handleSelectionMonthClick'];
  handleSelectionYearClick: DrawerSidebarProps<T>['handleSelectionYearClick'];
  handleApply: () => void;
  titleLabel?: string;
  isStartMonthYear?: boolean;
  isEndMonthYear?: boolean;
  isOnlyMonthYear?: boolean;
}

export default function DrawerSidebarDateNew<T extends keyof DataTypeMap>({
  // dataList,
  typeOfData,
  isDrawerOpen,
  isBackdropOpen,
  selectedOnlyMonth,
  selectedOnlyYear,
  selectedStartMonth,
  selectedStartYear,
  selectedEndMonth,
  selectedEndYear,
  handleCloseDrawer,
  handleSelectionMonthClick,
  handleSelectionYearClick,
  handleApply,
  titleLabel,
  isStartMonthYear,
  isEndMonthYear,
  isOnlyMonthYear,
}: drawerSidebarDateProps<T>) {
  const [activeValueMonth, setActiveValueMonth] = React.useState(selectedOnlyMonth);
  const handleGetActiveValueMonth = (value: any) => {
    setActiveValueMonth(value);
  };
  const [activeValueYear, setActiveValueYear] = React.useState(selectedOnlyYear);
  const handleGetActiveValueYear = (value: any) => {
    setActiveValueYear(value);
  };

  const [activeValueStartMonth, setActiveValueStartMonth] = React.useState(selectedStartMonth);
  const handleGetActiveValueStartMonth = (value: any) => {
    setActiveValueStartMonth(value);
  };
  const [activeValueStartYear, setActiveValueStartYear] = React.useState(selectedStartYear);
  const handleGetActiveValueStartYear = (value: any) => {
    setActiveValueStartYear(value);
  };

  useEffect(() => {
    if (isDrawerOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [isDrawerOpen]);

  useEffect(() => {
    setActiveValueStartMonth(selectedStartMonth);
    setActiveValueStartYear(selectedStartYear);
    setActiveValueEndMonth(selectedEndMonth);
    setActiveValueEndYear(selectedEndYear);
  }, [selectedStartYear, selectedStartMonth, selectedEndMonth, selectedEndYear]);

  const [activeValueEndMonth, setActiveValueEndMonth] = React.useState(selectedEndMonth);
  const handleGetActiveValueEndMonth = (value: any) => {
    setActiveValueEndMonth(value);
  };
  const [activeValueEndYear, setActiveValueEndYear] = React.useState(selectedEndYear);
  const handleGetActiveValueEndYear = (value: any) => {
    setActiveValueEndYear(value);
  };
  const monthYear = Number(moment().year());
  const monthYearList = [];
  for (let i = 0; i < 5; i++) {
    monthYearList.push(monthYear - i);
  }

  return (
    <>
      <Backdrop id="backdrop" open={isDrawerOpen} sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <Drawer anchor="right" open={isDrawerOpen} variant="persistent" onClose={handleCloseDrawer}>
          <div id="close-icon">
            <IconButton
              onClick={() => {
                handleCloseDrawer();
              }}
            >
              <Close />
            </IconButton>
          </div>
          <div id="sidebar-header">
            {isOnlyMonthYear ? (
              <>
                <Typography variant="h6" id="sidebar-title">
                  Select {titleLabel}
                </Typography>
                <li>Select only one option</li>
                <Divider orientation="horizontal" flexItem />
                <Typography variant="subtitle2" id="sidebar-section-text">
                  Select Month
                </Typography>
                <div id="sidebar-choice-container">
                  {monthList.map((el, index) => {
                    return (
                      <div
                        key={index}
                        id={el.id === activeValueMonth ? 'selected-select-choice' : 'select-choice'}
                        onClick={() => handleGetActiveValueMonth(el.id)}
                      >
                        {el.monthName}
                      </div>
                    );
                  })}
                </div>
                <Typography variant="subtitle2" id="sidebar-section-text">
                  Select Year
                </Typography>
                <div id="sidebar-choice-container">
                  {monthYearList.map((el, index) => {
                    return (
                      <div
                        key={index}
                        id={el === activeValueYear ? 'selected-select-choice' : 'select-choice'}
                        onClick={() => handleGetActiveValueYear(el)}
                      >
                        {el}
                      </div>
                    );
                  })}
                </div>{' '}
              </>
            ) : (
              <>
                <Typography variant="h6" id="sidebar-title">
                  Select Period
                </Typography>
                <li>Select only one option</li>
                <Divider orientation="horizontal" flexItem />
                <Typography variant="subtitle2" id="sidebar-section-text">
                  Start Month
                </Typography>
                <div id="sidebar-choice-container">
                  {monthList.map((el, index) => {
                    return (
                      <div
                        key={index}
                        id={el.id === activeValueStartMonth ? 'selected-select-choice' : 'select-choice'}
                        onClick={() => handleGetActiveValueStartMonth(el.id)}
                      >
                        {el.monthName}
                      </div>
                    );
                  })}
                </div>
                <Typography variant="subtitle2" id="sidebar-section-text">
                  Start Year
                </Typography>
                <div id="sidebar-choice-container">
                  {monthYearList.map((el, index) => {
                    return (
                      <div
                        key={index}
                        id={el === activeValueStartYear ? 'selected-select-choice' : 'select-choice'}
                        onClick={() => handleGetActiveValueStartYear(el)}
                      >
                        {el}
                      </div>
                    );
                  })}
                </div>
                <Divider id="divider" orientation="horizontal" flexItem />
                <Typography variant="subtitle2" id="sidebar-section-text">
                  End Month
                </Typography>
                <div id="sidebar-choice-container">
                  {monthList.map((el, index) => {
                    return (
                      <div
                        key={index}
                        id={el.id === activeValueEndMonth ? 'selected-select-choice' : 'select-choice'}
                        onClick={() => handleGetActiveValueEndMonth(el.id)}
                      >
                        {el.monthName}
                      </div>
                    );
                  })}
                </div>
                <Typography variant="subtitle2" id="sidebar-section-text">
                  End Year
                </Typography>
                <div id="sidebar-choice-container">
                  {monthYearList.map((el, index) => {
                    return (
                      <div
                        key={index}
                        id={el === activeValueEndYear ? 'selected-select-choice' : 'select-choice'}
                        onClick={() => handleGetActiveValueEndYear(el)}
                      >
                        {el}
                      </div>
                    );
                  })}
                </div>
              </>
            )}
          </div>
          <div id="sidebar-footer">
            <Button
              id="apply-button"
              variant="contained"
              onClick={() => {
                handleApply();
                if (typeOfData === 'datePeriod') {
                  handleSelectionMonthClick(activeValueStartMonth, activeValueEndMonth);
                  handleSelectionYearClick(activeValueStartYear, activeValueEndYear);
                } else {
                  (handleSelectionMonthClick as (value: any) => void)(activeValueMonth);
                  (handleSelectionYearClick as (value: any) => void)(activeValueYear);
                }
              }}
            >
              APPLY
            </Button>
          </div>
        </Drawer>
      </Backdrop>
    </>
  );
}

import React from 'react';
import { TypographyOptions } from '@mui/material/styles/createTypography';

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    navigation_bar: true;
    headline: true;
    sub_navigation: true;
    filter_head: true;
    filter_body: true;
    section: true;
    key_no: true;
    view_detail: true;
    body_in_widget: true;
    graph_label: true;
  }
}

interface ExtendedTypographyOptions extends TypographyOptions {
  navigation_bar: React.CSSProperties;
  headline: React.CSSProperties;
  sub_navigation: React.CSSProperties;
  filter_head: React.CSSProperties;
  filter_body: React.CSSProperties;
  section: React.CSSProperties;
  key_no: React.CSSProperties;
  view_detail: React.CSSProperties;
  body_in_widget: React.CSSProperties;
  graph_label: React.CSSProperties;
}

export const Typography = {
  fontSize: 16,
  fontFamily: 'Prompt',
  navigation_bar: {
    fontSize: '1.125rem',
    fontWeight: 700,
  },
  headline: {
    fontSize: '2.25rem',
    fontWeight: 500,
  },
  sub_navigation: {
    fontSize: '1.125rem',
  },
  filter_head: {
    fontSize: '0.875rem',
  },
  filter_body: {
    fontSize: '1rem',
  },
  section: {
    fontSize: '1rem',
    fontWeight: 700,
  },
  key_no: {
    fontSize: '4.125rem',
    fontFamily: 'Teko !important',
  },
  view_detail: {
    fontSize: '1rem',
    fontWeight: 500,
  },
  body_in_widget: {
    fontSize: '0.875rem',
  },
  graph_label: {
    fontSize: '1rem',
  },
} as ExtendedTypographyOptions;

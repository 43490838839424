import React from 'react';
import './loading.scss'; // Add your styles here

interface BackdropWithLoadingProps {
  show?: boolean;
}

const BackdropWithLoading: React.FC<BackdropWithLoadingProps> = ({ show }) => {
  return show ? (
    <div className="backdrop-loader">
      <div className="loader-container">
        <div className="loader"></div>
      </div>
    </div>
  ) : null;
};

export default BackdropWithLoading;
